<!--------Edit-Installs------->

<div class="content-card">
  <!-- <div class="ticket-loader">
        <h2>Please Wait -- Your Ticket is Updating</h2>
        <div class="progress-bar">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div> -->
  <div class="ticketBody">
    <div class="row">
      <div class="col-sm-10">
        <h2>Edit User</h2>
      </div>
      <div class="headerBar">
        <p>User Information</p>
      </div>
      <div class="col-sm-3 createdBy">
        <!-- <p>Created By: <b></b></p> -->
      </div>
      <div class="offset-sm-1 col-sm-10">
        <div class="row" *ngIf="getUserData">
          <div class="col-sm-12 form-group">
            <div class="row">
              <div class="col-sm-12">
                <!-- {{getUserData | json}} -->
                <label for="urlInput">Id</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getUserData.id"
                  value="{{ getUserData.id }}"
                  id="id"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">FirstName</label>
                <input
                  class="form-control"
                  [(ngModel)]="getUserData.firstname"
                  id="subjectInput"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">LastName</label>
                <input
                  class="form-control"
                  [(ngModel)]="getUserData.lastname"
                  id="subjectInput"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Email</label>
                <input
                  class="form-control"
                  [(ngModel)]="getUserData.email"
                  id="subjectInput"
                  type="email"
                />
              </div>
            </div>
          </div>
          <div class="offset-sm-5 col-sm-2 text-center">
            <button class="submit-button btn btn-primary" (click)="EditUser()">
              Update User
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
