<div class="content-card">
  <div class="report-top">
    <h2>SSO Logs</h2>
    <div class="form-group row"></div>
  </div>
</div>

<div class="content-card">
  <tr style="width: 100%; display: flex; justify-content: space-between">
    <td>Download SSO Logs</td>
    <td class="d-flex flex-row">
      <a
        class="btn btn-outline-secondary"
        (click)="downloadCSV()"
        style="margin-left: auto; display: flex; align-items: center"
      >
        <mat-icon>arrow_downward</mat-icon>Download CSV
      </a>
    </td>
  </tr>
</div>
