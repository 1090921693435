import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import { UtilsService } from '../shared/services/utils.service';
import { Router } from '@angular/router';
import { $ } from 'protractor';
import { BindingScope } from '@angular/compiler/src/render3/view/template';
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { MenuItem } from 'primeng/api';
import { async } from '@angular/core/testing';
import Swal from 'sweetalert2';
import { AlertService } from '../shared/services/alert.service.service';
import { event } from 'jquery';
import { debug } from 'console';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  constructor(
    private router: Router,
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    private alertService: AlertService,
  ) {}

  selectedInstall: string;
  controlNuggets = JSON.parse(sessionStorage.getItem('installs'));
  nuggetHTML: string;
  seshKeysRaw = sessionStorage.getItem('sessionKeys');
  seshKeys = JSON.parse(this.seshKeysRaw);
  seshkeyraw = sessionStorage.getItem('sessionKey');
  seshKey = JSON.parse(this.seshkeyraw);
  listUname = sessionStorage.getItem('username');
  listUserJson;
  passwordToast = false;
  installToast = false;
  regToast = false;
  adminPage;
  adminPageSize;
  adminTableSize;
  adminTableList: any[] = [];
  adminSpinner = true;
  adminTable = undefined;
  firstName;
  lastName;
  email;
  installationArray;
  supportOnlyCheck;
  isAdminCheck;
  baseURLSend = window.location.origin;
  seleted_src_item;
  newUser = false;
  supportOnlyNew = false;
  isAdminNew = false;
  isSslLogs = false;
  instObj = {};
  editInstalls = false;
  noInstallToast;
  installationFullList;
  getInstallationData;
  deleteFlag = false;
  user_uuid: any;
  selectedValues: string;
  newopationsList: any[] = [];
  isLoading: boolean;
  delete_user: any;
  commentsArr = [];
  userdelete = false;
  selectedUserId: any = null;
  userid: any;
  ID: any;
  installationData: any[] = [];
  admin: any;
  installSpinner = true;
  errorBool = undefined;
  errorMsg;

  async ngOnInit(): Promise<any> {
    if (this.baseURLSend === environment.frontendBaseURL) {
      this.baseURLSend = environment.baseUrl;
    }

    this.selectedUserId = null; // or this.selectedUserId = '';

    this.getInstallations();
    this.listUserJson = await this.fetchAdminList();

    this.adminSpinner = false;
    this.renderAdminTable(this.listUserJson);
    var installationResponse = await this.getInstallations();
    this.installationFullList = installationResponse;
    this.installationFullList.forEach((element) => {
      if (element.name == 'Coso Cloud') {
        element['selected'] = true;
      } else element['selected'] = false;
    });

    // for (var i = 0; i < this.installationFullList.length; i++) {
    //   this.instObj[this.installationFullList[i]["installationKey"]] = this.installationFullList[i].accountName
    // }
  }

  sendPasswordResetEmail = (e) => {
    if (window.confirm("Reset this user's password?")) {
      var passwordResetArr =
        e.currentTarget.parentNode.parentNode.children[0].children;
      var currentInstallsElement =
        e.currentTarget.parentNode.parentNode.children[1].children;
      var adminCheck = e.currentTarget.parentNode.children;
      var passwordResetID;
      var currentInstalls = [];
      for (var k in passwordResetArr) {
        if (passwordResetArr[k].className == 'uuid') {
          passwordResetID = passwordResetArr[k].children[1].innerText;
        }
      }
      for (var i in currentInstallsElement) {
        if (currentInstallsElement[i].className == 'updateInstallsBox') {
          for (var o = 0; o < currentInstallsElement[i].children.length; o++) {
            if (currentInstallsElement[i].children[o].className == 'selectB') {
              for (
                var j = 0;
                j < currentInstallsElement[i].children[o].options.length;
                j++
              )
                currentInstalls.push(
                  currentInstallsElement[i].children[o][j].value,
                );
            }
          }
        }
      }
      for (var p in adminCheck) {
        if (adminCheck[p].className == 'isAdminCheck') {
          var currentAdmin;
          if (adminCheck[p].checked == true) {
            currentAdmin = true;
          } else {
            currentAdmin = false;
          }
        }
      }
      this.updateUserAPIFetch(
        passwordResetID,
        currentAdmin,
        currentInstalls,
        null,
        null,
        null,
        null,
      );
    } else {
      return;
    }
  };

  forgotPass = async (email) => {
    var data = {
      api: 'authentication.reset',
      username: email,
      // baseUrl: this.urlUtil(),
    };
    var response = await this.apiCallsService.sendApi(data);
    if (response.status == '200') {
      this.alertService.showAlert(response.message, 'success');
      setTimeout(() => {
        this.passwordToast = null;
      }, 12000);
    } else {
      if (response.status == '400') {
        this.alertService.showAlert(response.failed, 'error');
      }
    }
    return response;
  };

  renderNewUser = async () => {
    this.newUser = true;
    this.selectedUserId = 0;
    var btn = document.getElementById('userdetailsbutton');
    btn.classList.remove('pi-chevron-down');
    btn.classList.add('pi-chevron-right');
    // this.selectedInstallation = this.installationFullList.find(
    //   element => element.name === 'Coso Cloud'
    // );
    this.installationFullList.forEach((element) => {
      if (element.name == 'Coso Cloud') {
        element['selected'] = true;
      } else element['selected'] = false;
    });
  };

  srcValueChange = (event) => {
    // this.installationArray=[];
    // for(var i in event.target.selectedOptions){
    //     if(event.target.selectedOptions[i].value){
    //         this.installationArray.push(event.target.selectedOptions[i].value);
    //     }
    // }
    event.selected = !event.selected;
  };

  editinstallationsChange = (event) => {
    // console.log("event-->",event.target.value)
    event.selected = !event.selected;
  };

  loadData() {
    this.getInstallationData.forEach((ele) => {
      ele.selected = false;
    });
    this.getInstallationData.forEach((outer_ele) => {
      if (this.installationData[0] && this.installationData[0].installations) {
        this.installationData[0].installations.forEach((inner_ele) => {
          if (inner_ele.id === outer_ele.id) {
            outer_ele.selected = true;
          }
        });
      }
    });
  }

  listbox_moveacross = (e, sourceID, destID) => {
    var ev = e;
    var src;
    var dest;
    if (sourceID == 'selectA') {
      src = ev.parentElement.previousSibling;
      dest = ev.parentElement.nextSibling;
    } else {
      src = ev.parentElement.nextSibling;
      dest = ev.parentElement.previousSibling;
    }

    for (var count = 0; count < src.options.length; count++) {
      if (src.options[count].selected == true) {
        var option = src.options[count];

        var newOption = document.createElement('option');
        newOption.value = option.value;
        newOption.text = option.text;
        newOption.selected = true;
        try {
          dest.add(newOption, null); //Standard
          src.remove(count, null);
        } catch (error) {
          dest.add(newOption); // IE only
          src.remove(count);
        }
        console.log('newOption', newOption.value);
        this.newopationsList.push(newOption.value);
        //  this.newopationsList = newOption.text

        count--;
      }
    }
  };

  updateUserAPIFetch = async (
    passIDParam,
    currentAdmin,
    currentInstalls,
    installIDParam,
    installsParam,
    isAdminID,
    isAdminFlag,
  ) => {
    var updateID;
    var passFlag;
    var installationSend;
    var adminFlag;
    if (installIDParam !== null) {
      updateID = installIDParam;
      passFlag = false;
      installationSend = installsParam;
      adminFlag = currentAdmin;
    } else if (passIDParam !== null) {
      updateID = passIDParam;
      passFlag = true;
      installationSend = currentInstalls;
      adminFlag = currentAdmin;
    } else {
      updateID = isAdminID;
      passFlag = false;
      installationSend = currentInstalls;
      adminFlag = isAdminFlag;
    }
    // var data = {
    //   "api": "administration.administratorEditUser",
    //   "sessionKeys": this.seshKeys,
    //   "baseUrl": true,
    //   "userId": updateID,
    //   "resetPassword": passFlag,
    //   "installationKeys": installationSend,
    //   "isAdmin": adminFlag,
    //   "isShowSSLLog": this.isSslLogs
    // };
    var newUserObjRaw = {
      user_id: updateID,
      resetPassword: passFlag,
      installationKeys: installationSend,
      isAdmin: adminFlag,
      // "isShowSSLLog": this.isSslLogs,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      'no-connect': this.supportOnlyNew,
      installs: this.installationArray,
    };
    var data = {
      api: 'administration.administratorEditUser',
      'session-key': this.seshKeys,
      baseUrl: this.baseURLSend,
      newUser: newUserObjRaw,
    };
    var response = await this.apiCallsService.sendApi(data);
    this.ngOnInit();
    if (installIDParam !== null) {
      this.installToast = true;
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    } else if (passIDParam !== null) {
      this.passwordToast = true;
      setTimeout(function () {
        this.passwordToast = false;
      }, 2000);
    } else {
      this.regToast = true;
      setTimeout(function () {
        this.regToast = false;
      }, 2000);
    }
    return response;
  };

  updateUserSslLogAPIFetch = async (
    passIDParam,
    currentAdmin,
    currentInstalls,
    installIDParam,
    installsParam,
    isAdminID,
    isSslLogFlag,
  ) => {
    var updateID;
    var passFlag;
    var installationSend;
    // var adminFlag
    var sslLogFlag;
    if (installIDParam !== null) {
      updateID = installIDParam;
      passFlag = false;
      installationSend = installsParam;
      sslLogFlag = currentAdmin;
    } else if (passIDParam !== null) {
      updateID = passIDParam;
      passFlag = true;
      installationSend = currentInstalls;
      sslLogFlag = currentAdmin;
    } else {
      updateID = isAdminID;
      passFlag = false;
      installationSend = currentInstalls;
      sslLogFlag = isSslLogFlag;
    }

    var newUserObjRaw = {
      user_id: updateID,
      resetPassword: passFlag,
      installationKeys: installationSend,
      // "isAdmin": adminFlag,
      isShowSSLLog: sslLogFlag,
      email: this.email,
      firstName: this.firstName,
      lastName: this.lastName,
      'no-connect': this.supportOnlyNew,
      installs: this.installationArray,
    };
    var data = {
      api: 'administration.administratorEditUser',
      'session-key': this.seshKeys,
      baseUrl: this.baseURLSend,
      newUser: newUserObjRaw,
    };
    var response = await this.apiCallsService.sendApi(data);
    this.ngOnInit();
    if (installIDParam !== null) {
      this.installToast = true;
      setTimeout(function () {
        window.location.reload();
      }, 2000);
    } else if (passIDParam !== null) {
      this.passwordToast = true;
      setTimeout(function () {
        this.passwordToast = false;
      }, 2000);
    } else {
      this.regToast = true;
      setTimeout(function () {
        this.regToast = false;
      }, 2000);
    }
    return response;
  };

  adminToggle = (e) => {
    var adminInstallsArr =
      e.currentTarget.parentNode.parentNode.children[0].children;
    var adminToggleArr =
      e.currentTarget.parentNode.parentNode.children[1].children;
    var adminID;
    var adminToggle;
    var currentInstalls = [];
    var adminCheckTarget = e.currentTarget;
    for (var k in adminInstallsArr) {
      if (adminInstallsArr[k].className == 'uuid') {
        adminID = adminInstallsArr[k].children[1].innerText;
      }
    }
    for (var i in adminToggleArr) {
      if (adminToggleArr[i].className == 'updateInstallsBox') {
        for (var o = 0; o < adminToggleArr[i].children.length; o++) {
          if (adminToggleArr[i].children[o].className == 'selectB') {
            for (
              var j = 0;
              j < adminToggleArr[i].children[o].options.length;
              j++
            )
              currentInstalls.push(adminToggleArr[i].children[o][j].value);
          }
        }
      }
    }
    if (e.currentTarget.checked == true) {
      adminToggle = true;
      adminCheckTarget.checked = true;
    } else {
      adminToggle = false;
      adminCheckTarget.checked = false;
    }
    this.updateUserAPIFetch(
      null,
      null,
      currentInstalls,
      null,
      null,
      adminID,
      adminToggle,
    );
  };

  SSLToggle = (e) => {
    var adminInstallsArr =
      e.currentTarget.parentNode.parentNode.children[0].children;
    var SSLToggleArr =
      e.currentTarget.parentNode.parentNode.children[1].children;
    var adminID;
    var SSLToggle;
    var currentInstalls = [];
    var adminCheckTarget = e.currentTarget;

    for (var k in adminInstallsArr) {
      if (adminInstallsArr[k].className == 'uuid') {
        adminID = adminInstallsArr[k].children[1].innerText;
      }
    }
    for (var i in SSLToggleArr) {
      if (SSLToggleArr[i].className == 'updateInstallsBox') {
        for (var o = 0; o < SSLToggleArr[i].children.length; o++) {
          if (SSLToggleArr[i].children[o].className == 'selectB') {
            for (var j = 0; j < SSLToggleArr[i].children[o].options.length; j++)
              currentInstalls.push(SSLToggleArr[i].children[o][j].value);
          }
        }
      }
    }

    if (e.currentTarget.checked == true) {
      SSLToggle = true;
      adminCheckTarget.checked = true;
    } else {
      SSLToggle = false;
      adminCheckTarget.checked = false;
    }
    this.updateUserSslLogAPIFetch(
      null,
      null,
      currentInstalls,
      null,
      null,
      adminID,
      SSLToggle,
    );
  };

  entitlementToggle = (e) => {
    var userEntitlementToggle = this.utilsService.setEntitlements(
      e.currentTarget,
    );
    var userEntitlementArr =
      e.currentTarget.parentNode.parentNode.children[0].children;
    var currentEntitlementTarget = e.currentTarget;
    var entitlementID;
    var entitlementVal;
    for (var k in userEntitlementArr) {
      if (userEntitlementArr[k].className == 'uuid') {
        entitlementID = userEntitlementArr[k].children[1].innerText;
      }
    }
    if (e.currentTarget.checked == true) {
      entitlementVal = true;
      currentEntitlementTarget.checked = true;
    } else {
      entitlementVal = false;
      currentEntitlementTarget.checked = false;
    }
    this.entitlementAPICall(
      entitlementVal,
      entitlementID,
      userEntitlementToggle,
    );
  };

  entitlementAPICall = async (val, ID, toggle) => {
    var data = {
      api: 'administration.updateuserentitlement',
      entitlement: toggle,
      userId: ID,
      value: val,
    };
    var response = await this.apiCallsService.sendApi(data);
    return response;
  };

  updateUserInstalls = (e) => {
    if (window.confirm('Update the installs for this user?')) {
      var updateInstalls = e.currentTarget.parentElement.children;
      var updateAdminParam =
        e.currentTarget.parentElement.parentElement.parentElement.children[2]
          .children;
      var updateIDParam =
        e.currentTarget.parentElement.parentElement.parentElement.children[0]
          .children;
      var updateInstallArr = [];
      var installsID;
      for (var j in updateIDParam) {
        if (updateIDParam[j].className == 'uuid') {
          installsID = updateIDParam[j].children[1].innerText;
        }
      }
      for (var o in updateAdminParam) {
        if (updateAdminParam[o].className == 'isAdminCheck') {
          var currentAdmin;
          if (updateAdminParam[o].checked == true) {
            currentAdmin = true;
          } else {
            currentAdmin = false;
          }
        }
      }
      for (var i in updateInstalls) {
        if (updateInstalls[i].className == 'selectB') {
          for (var k = 0; k < updateInstalls[i].options.length; k++) {
            updateInstallArr.push(updateInstalls[i][k].value);
          }
        }
      }
      var installSet = new Set(updateInstallArr);
      var noDupeInstallArray = Array.from(installSet);
      if (noDupeInstallArray.length !== 0) {
        this.updateUserAPIFetch(
          null,
          currentAdmin,
          null,
          installsID,
          noDupeInstallArray,
          null,
          null,
        );
      } else {
        this.noInstallToast = true;
        setTimeout(function () {
          this.noInstallToast = false;
        }, 2000);
      }
    } else {
      return;
    }
  };

  disableUser = async (id, email, flag) => {
    var enabled_disable;
    if (flag === 'true') {
      (flag = true), (enabled_disable = 'Enable');
    } else {
      (flag = false), (enabled_disable = 'Disable');
    }
    var data = {
      // "api": "authentication.administratorDisableUser",
      api: 'administration.administratorEnableDisableUser',
      'session-key': this.seshKeys,
      email: email,
      enable: flag,
    };
    // var response = await this.apiCallsService.sendApi(data);
    // // window.location.reload();
    // if(response){
    //   this.ngOnInit()
    // }
    Swal.fire({
      title: 'Are you sure? You want to ' + enabled_disable + ' this user ?',
      // text: "You won't be able to revert this!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        var response = this.apiCallsService.sendApi(data);
        response
          .then(() => {
            this.commentsArr = this.commentsArr.filter((c: any) => c.id !== id);
            Swal.fire(
              enabled_disable,
              'Your User Has Been ' + enabled_disable + '.',
              'success',
            );
            this.userdelete = true;
            // this.fetchAdminList();
            this.ngOnInit();
            setTimeout(() => {
              this.userdelete = false;
            }, 2000);
          })
          .catch((error: any) => {
            console.error('Error deleting comment:', error);
            Swal.fire('Error!', 'Failed to delete the comment.', 'error');
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Cancel button is clicked
        this.ngOnInit();
        // this.fetchAdminList(); // Reload the installations on the same page
      }
    });
  };

  createUser = async () => {
    if (
      this.firstName == '' ||
      this.firstName == undefined ||
      this.firstName == null
    ) {
      this.alertService.showAlert('Please enter first name.', 'error');
      return;
    }
    if (
      this.lastName == '' ||
      this.lastName == undefined ||
      this.lastName == null
    ) {
      this.alertService.showAlert('Please enter last name.', 'error');
      return;
    }
    if (this.email == '' || this.email == undefined || this.email == null) {
      this.alertService.showAlert('Please enter email.', 'error');
      return;
    }

    this.installationArray = [];
    this.installationFullList.forEach((element) => {
      if (element.selected) this.installationArray.push(element.id);
    });

    var newUserObjRaw = {
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      installs: this.installationArray,
      'no-connect': this.supportOnlyNew,
      isAdmin: this.isAdminNew,
      isShowSSLLog: this.isSslLogs,
    };
    var creationData = {
      api: 'authentication.administratorCreateNewUser',
      username: this.listUname,
      sessionKeys: this.seshKeys,
      baseUrl: this.baseURLSend,
      newUser: newUserObjRaw,
    };
    var response = await this.apiCallsService.sendApi(creationData);
    // this.alertService.showAlert("User created successfully..", "success");

    if (response.status === 201) {
      this.alertService.showAlert('User created successfully..', 'success');
    } else {
      this.installSpinner = true;
      this.errorBool = true;
      this.alertService.showAlert((this.errorMsg = response.message), 'error');
    }

    if (response) {
      this.ngOnInit();
    }
    this.newUser = false;
    return response;
  };

  deleteUser(user: any) {
    this.deleteFlag = false;
    this.isLoading = true;
    this.delete_user = user;
    var sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
    var data = {
      api: 'administration.administratorDeleteUser',
      'session-key': this.seshKey,
      email: this.delete_user.email,
    };

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        var response = this.apiCallsService.sendApi(data);
        response
          .then(() => {
            this.commentsArr = this.commentsArr.filter(
              (c: any) => c.id !== this.delete_user.id,
            );
            Swal.fire('Deleted!', 'Your User Has Been Deleted.', 'success');
            this.userdelete = true;
            // this.fetchAdminList();
            this.ngOnInit();
            setTimeout(() => {
              this.userdelete = false;
            }, 2000);
          })
          .catch((error: any) => {
            console.error('Error deleting comment:', error);
            Swal.fire('Error!', 'Failed to delete the comment.', 'error');
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Cancel button is clicked
        this.ngOnInit();
        // this.fetchAdminList(); // Reload the installations on the same page
      }
    });
  }

  getInstallations = async () => {
    var data = {
      api: 'administration.administratorListInstallations',
      'session-key': this.seshKey,
    };
    var response = await this.apiCallsService.sendApi(data);
    if (response) {
      this.deleteFlag = true;
    }
    this.getInstallationData = response;
    return response;
  };

  editInstallations = async (user_id, installations) => {
    this.userid = user_id;
    const installationName = [];
    this.getInstallationData.forEach((element) => {
      if (element.selected) installationName.push(element.id);

      if (element.name == 'Coso Cloud') element.selected = true;
      else element.selected = false;
    });

    var data = {
      api: 'administration.administratorEditOrRemoveUserInstalls',
      'session-key': this.seshKey,
      installs: installationName,
      user_id: user_id,
    };

    var response = await this.apiCallsService.sendApi(data);

    this.editInstalls = false;
    this.ngOnInit();

    return response;
  };

  renderAdminTable = (userListParam) => {
    this.adminTableList = userListParam;
    // for (var i = 0; i < this.adminTableList.length; i++) {
    //   this.adminTableList[i].lastLogin = new Date(this.adminTableList[i].lastLogin)
    // }
  };

  getsingleuser = async (user_id) => {
    this.selectedUserId = this.selectedUserId === user_id ? null : user_id;
    var data = {
      api: 'authentication.administratorGetUsers',
      'session-key': this.seshKeys,
      user_id: user_id,
    };
    var response = await this.apiCallsService.sendApi(data);
    this.installationData = response; // Store response data in a variable
    return response;
  };

  fetchAdminList = async () => {
    var data = {
      api: 'authentication.administratorListUsers',
      username: this.listUname,
      sessionKeys: this.seshKeys,
    };
    var response = await this.apiCallsService.sendApi(data);
    return response;
  };

  expandChild = (e, idParam) => {
    var bgColor = e.currentTarget.parentElement.parentElement.style;
    bgColor.backgroundColor = '#00a6d9';
    document.getElementById('tableRow-' + idParam).style.maxHeight = '1000px';
    document.getElementById(idParam).style.display = 'block';
    document.getElementById('expand-' + idParam).style.display = 'none';
    document.getElementById('close-' + idParam).style.display = 'block';
  };

  closeChild = (e, idParam) => {
    var bgColor = e.currentTarget.parentElement.parentElement.style;
    bgColor.backgroundColor = 'white';
    document.getElementById('tableRow-' + idParam).style.height = 'auto';
    document.getElementById(idParam).style.display = 'none';
    document.getElementById('expand-' + idParam).style.display = 'block';
    document.getElementById('close-' + idParam).style.display = 'none';
  };
}
