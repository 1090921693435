import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-test-child',
  templateUrl: './test-child.component.html',
  styleUrls: ['./test-child.component.scss'],
})
export class TestChildComponent implements OnInit {
  @Output()
  buttonClicked: EventEmitter<string> = new EventEmitter<string>();

  constructor() {}

  ngOnInit(): void {}

  clickButton() {
    this.buttonClicked.emit('this sucks');
  }
}
