import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiCallsService } from 'src/app/shared/services/api-calls.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { ViewInstallComponent } from '../view-install/view-install.component';
import Swal from 'sweetalert2';
import { AlertService } from 'src/app/shared/services/alert.service.service';
import { environment } from 'src/environments/environment';
import { log } from 'console';
@Component({
  selector: 'app-install',
  templateUrl: './install.component.html',
  styleUrls: ['./install.component.scss'],
})
export class InstallComponent implements OnInit {
  installations: any;
  dbnamelist: any;
  username: any;
  password: any;
  instances: any;
  constructor(
    private router: Router,
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    private alertService: AlertService,
  ) {}
  selectedInstall: string;
  controlNuggets = JSON.parse(sessionStorage.getItem('installs'));
  nuggetHTML: string;
  seshKeysRaw = sessionStorage.getItem('sessionKeys');
  seshKeys = JSON.parse(this.seshKeysRaw);
  seshkeyraw = sessionStorage.getItem('sessionKey');
  seshKey = JSON.parse(this.seshkeyraw);
  listUname = sessionStorage.getItem('username');
  listUserJson;
  passwordToast = false;
  installToast = false;
  regToast = false;
  adminPage;
  adminPageSize;
  adminTableSize;
  adminTableList: any[] = [];
  adminSpinner = true;
  adminTable = undefined;
  firstName;
  lastName;
  email;
  installationArray;
  supportOnlyCheck;
  isAdminCheck;
  baseURLSend = window.location.origin;
  seleted_src_item;
  newUser = false;
  supportOnlyNew = false;
  isAdminNew = false;
  instObj = {};
  editInstalls = false;
  noInstallToast;
  installationFullList;
  installName: any;
  getInstallationData: any[] = [];
  installationTableSize;
  isLoading: boolean;
  delete_install: any;
  commentsArr = [];
  installdelete = false;
  errorBool = undefined;
  errorMsg;
  installSpinner = true;
  deleteFlag = false;

  selectedInstance: string = '';
  get_instances: string[] = [];
  dbNames: string[] = [];
  selectedDbName: string[] = [];

  async ngOnInit(): Promise<any> {
    if (this.baseURLSend === environment.frontendBaseURL) {
      this.baseURLSend = environment.baseUrl;
    }
    this.getInstallations();
    this.Get_instances();
    this.listUserJson = await this.fetchAdminList();
    this.adminSpinner = false;
    // this.renderAdminTable(this.listUserJson.response.userList);
    var installationResponse = await this.getInstallations();
    // this.installationFullList = installationResponse.response.installationList;
  }

  // renderNewUser = async() => {
  //   var installationResponse = await this.getInstallations()
  //   this.installationFullList = installationResponse.response.installationList
  // }

  srcValueChange = (event) => {
    this.installationArray = [];
    for (var i in event.target.selectedOptions) {
      if (event.target.selectedOptions[i].value) {
        this.installationArray.push(event.target.selectedOptions[i].value);
      }
    }
  };

  disableUser = async (param) => {
    var data = {
      api: 'authentication.administratorDisableUser',
      sessionKeys: this.seshKeys,
      userId: param,
      username: sessionStorage.getItem('username'),
    };
    var response = await this.apiCallsService.sendApi(data);
    window.location.reload();
  };

  createInstallation = async () => {
    this.deleteFlag = false;
    var sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
    var creationData = {
      api: 'administration.administratorSetInstallations',
      'session-key': this.seshKeys,
      name: this.installName,
      dbname: this.selectedDbName,
      instance_id: +this.selectedInstance,
      db_username: this.username,
      db_password: this.password,
    };
    var response = await this.apiCallsService.sendApi(creationData);
    if (response.status === 400) {
      this.installSpinner = true;
      this.errorBool = true;
      // this.alertService.showAlert("Please enter install name", "error");
      this.alertService.showAlert(response.message, 'error');
    } else if (response.status === 200) {
      this.alertService.showAlert('Install created successfully..', 'success');
    } else {
      this.installSpinner = true;
      this.errorBool = true;
      this.alertService.showAlert((this.errorMsg = response.message), 'error');
    }
    this.getInstallations();
    this.installName = '';
    return response;
  };

  Get_instances = async () => {
    var data = {
      api: 'get_instances',
    };
    var response = await this.apiCallsService.sendApi(data);
    if (response && response.instances) {
      this.get_instances = response.instances;
    } else {
      console.log('Response structure is unexpected.');
    }
    return response;
  };

  get_dbname = async () => {
    var data = {
      api: 'getdbname',
      id: +this.selectedInstance,
    };
    var response = await this.apiCallsService.sendApi(data);
    if (response && response.dbnames) {
      this.dbnamelist = response.dbnames;
    } else {
      console.log('Response structure is unexpected.', this.get_dbname);
    }
    return response;
  };

  updateToggle(data: any, event: any) {
    const body = {
      api: 'administration.administratorSetInstallations',
      'session-key': '88c9b416-0908-11ee-ad02-15fb19cd945c',
      // 'session-key': this.seshKeys,
      disabled: event.checked == true ? 'yes' : 'no',
      id: data.id,
    };
    var response = this.apiCallsService.sendApi(body);
    return response;
  }

  getInstallations = async () => {
    var data = {
      // "api": "authentication.administratorlistinstallations",
      api: 'administration.administratorListInstallations',
      'session-key': this.seshKey,
    };
    var response = await this.apiCallsService.sendApi(data);
    if (response) {
      this.deleteFlag = true;
    }
    this.getInstallationData = response;
    return response;
  };

  installDetails(data) {
    this.router.navigate(['/admin/view-install', data.id]);
  }

  deleteInstall(installations: any) {
    this.deleteFlag = false;
    this.isLoading = true;
    this.delete_install = installations;
    var sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
    var data = {
      api: 'administration.administratorRemoveUserInstalls',
      'session-key': this.seshKey,
      id: this.delete_install.id,
    };

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        var response = this.apiCallsService.sendApi(data);
        response
          .then(() => {
            this.commentsArr = this.commentsArr.filter(
              (c: any) => c.id !== this.delete_install.id,
            );
            Swal.fire(
              'Deleted!',
              'Your Installation Has Been Deleted.',
              'success',
            );
            this.installdelete = true;
            this.getInstallations();
            setTimeout(() => {
              this.installdelete = false;
            }, 2000);
          })
          .catch((error: any) => {
            console.error('Error deleting comment:', error);
            Swal.fire('Error!', 'Failed to delete the comment.', 'error');
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Cancel button is clicked
        this.getInstallations(); // Reload the installations on the same page
      }
    });
  }

  renderAdminTable = (userListParam) => {
    this.adminTableList = userListParam;
    for (var i = 0; i < this.adminTableList.length; i++) {
      this.adminTableList[i].lastLogin = new Date(
        this.adminTableList[i].lastLogin,
      );
    }
  };

  fetchAdminList = async () => {
    var data = {
      api: 'authentication.administratorListUsers',
      username: this.listUname,
      sessionKeys: this.seshKeys,
    };
    var response = await this.apiCallsService.sendApi(data);
    return response;
  };

  expandChild = (e, idParam) => {
    var bgColor = e.currentTarget.parentElement.parentElement.style;
    bgColor.backgroundColor = '#00a6d9';
    document.getElementById('tableRow-' + idParam).style.maxHeight = '1000px';
    document.getElementById(idParam).style.display = 'block';
    document.getElementById('expand-' + idParam).style.display = 'none';
    document.getElementById('close-' + idParam).style.display = 'block';
  };

  closeChild = (e, idParam) => {
    var bgColor = e.currentTarget.parentElement.parentElement.style;
    bgColor.backgroundColor = 'white';
    document.getElementById('tableRow-' + idParam).style.height = 'auto';
    document.getElementById(idParam).style.display = 'none';
    document.getElementById('expand-' + idParam).style.display = 'block';
    document.getElementById('close-' + idParam).style.display = 'none';
  };
}
