// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

//endpoints
const backendBaseURL = 'https://api.insights-dev.cosoinsights.cloud:443/';
const frontendBaseURL = 'https://insights-dev.cosoinsights.cloud';

export const environment = {
  production: false,
  baseUrl: backendBaseURL,
  frontendBaseURL: frontendBaseURL,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
