import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { ApiCallsService } from '../shared/services/api-calls.service';
import { Router } from '@angular/router';
import { UtilsService } from '../shared/services/utils.service';
import { Options } from 'ng5-slider';
import { async } from '@angular/core/testing';
import { log } from 'console';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {
  lineChartLabels: any[];
  constructor(
    private router: Router,
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
  ) {}

  chartName = 'dashboard';
  subchartName = 'Meetings';
  meetingsChartReady = false;
  usersChartReady = false;
  contentChartReady = false;
  meetingTot = 0;
  intervalSet;
  userTot = 0;
  contentTot = 0;
  globaltype;
  oneInstall;
  showBar;
  sliderVal = 15;
  sliderOptions: Options = {
    floor: 15,
    ceil: 60,
    step: 15,
    showTicks: true,
    showTicksValues: true,
  };
  selectedInstalls: any[] = [];
  installationList = JSON.parse(sessionStorage.getItem('installs'));
  installationChecks = this.installationList;
  summaryData = {};
  todayDate = new Date();
  sliderTime;
  public meetingChartData: ChartDataSets[] = [];
  public usersChartData: ChartDataSets[] = [];
  public contentChartData: ChartDataSets[] = [];
  public meetingLabels: Label[] = ['', '', '', '', '', '', '', '', ''];
  public usersLabels: Label[] = [];
  public contentLabels: Label[] = [];
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2.5,
    bezierCurve: true,
    bezierCurveTension: 0.4,
    elements: {
      point: {
        radius: 0,
        hitRadius: 5,
        hoverRadius: 1,
        hoverBorderWidth: 2,
      },
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          ticks: {
            display: true,
            autoSkip: true,
            beginAtZero: false,
            labelOffset: 0,
            maxTicksLimit: 16,
            maxRotation: 30,
            minRotation: 0,
          },
          type: 'time',
          distribution: 'series',
          time: {
            tooltipFormat: 'MMM D Y h:mm a',
            displayFormats: {
              minute: 'LT',
              hour: 'MMM D h:mm a',
              day: 'MMM D Y',
            },
          },
          gridLines: {
            borderDash: [8, 4],
            color: '#808080',
          },
        },
      ],
      yAxes: [
        {
          id: 1,
          position: 'left',
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#269AD0',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#282521',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#25408F',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#F7A209',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#6D6A67',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#8C4600',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#269AD0',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#282521',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#25408F',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#43658b',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ed6663',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ffa372',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ffa36c',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ebdc87',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#799351',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#290001',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#87431d',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#c87941',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#dbcbbd',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ffd5cd',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#efbbcf',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#c3aed6',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#8675a9',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#1b262c',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#0f4c75',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#00b7c2',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#fdcb9e',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#cffffe',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#f9f7d9',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#fce2ce',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ffc1f3',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#efbbcf',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ffd5cd',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#c3aed6',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#8675a9',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#00bcd4',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#b2ebf2',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ff5722',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ed6663',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#b52b65',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#3c2c3e',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#59405c',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#8d93ab',
    },
  ];
  installNames = [];

  // new
  public lineChartData: Array<any> = [{}];
  // end

  async ngOnInit(): Promise<any> {
    this.meetingTot = 0;
    this.userTot = 0;
    this.contentTot = 0;
    this.oneInstall = localStorage.getItem('OnlyOne');
    this.showBar = localStorage.getItem('showInstallSelect');
    if (this.showBar == 'true') {
      this.showBar = true;
    } else {
      this.showBar = false;
    }
    if (this.oneInstall == 'true') {
      this.oneInstall = true;
    }
    this.selectedInstalls = JSON.parse(
      localStorage.getItem('SelectedInstalls'),
    );
    if (this.selectedInstalls !== null) {
      for (var i = 0; i < this.installationChecks.length; i++) {
        for (var o = 0; o < this.selectedInstalls.length; o++) {
          if (
            this.selectedInstalls[o].name == this.installationChecks[i].name
          ) {
            this.installationChecks[i].checked = true;
          }
        }
      }
    }

    sessionStorage.setItem('subchartName', 'Meetings');
    var totalMeetings = await this.getChartData('Meetings');
    var totalUsers = await this.getChartData('Users');
    var contentData = await this.getChartData('Content');
    this.globaltype = 'Meetings';
    if (this.router.url.includes('dashboard')) {
      this.intervalSet = setInterval(() => {
        this.getChartData(this.globaltype);
      }, 60000);
    }
    // this.Update("Meetings")
  }

  ngOnDestroy() {
    if (this.intervalSet) {
      clearInterval(this.intervalSet);
    }
    this.selectedInstalls = JSON.parse(
      localStorage.getItem('SelectedInstalls'),
    );
    // this.selectedInstalls = [this.selectedInstalls[0]];
    // localStorage.removeItem('SelectedInstalls');
    localStorage.setItem(
      'SelectedInstalls',
      JSON.stringify(this.selectedInstalls),
    );
  }

  toggleInstallSelect = (param) => {
    if (param == 'true') {
      localStorage.setItem('showInstallSelect', 'true');
      this.showBar = true;
    } else {
      localStorage.setItem('showInstallSelect', 'false');
      this.showBar = false;
    }
  };

  installChecked = (install, checkvalue) => {
    if (this.selectedInstalls == null) {
      this.selectedInstalls = [];
    }
    if (checkvalue == true) {
      this.selectedInstalls.push(install);
      localStorage.setItem(
        'SelectedInstalls',
        JSON.stringify(this.selectedInstalls),
      );
    } else {
      for (var i = 0; i < this.selectedInstalls.length; i++) {
        if (this.selectedInstalls[i].name == install.name) {
          this.selectedInstalls.splice(i, 1);
        }
      }
      localStorage.setItem(
        'SelectedInstalls',
        JSON.stringify(this.selectedInstalls),
      );
    }
    this.getChartData(this.globaltype);
    // this.Update("Meetings")
  };

  sliderChange = async () => {
    setTimeout(() => {
      this.getChartData(this.globaltype);
    }, 100);
  };

  getChartData = async (type) => {
    this.globaltype = type;
    switch (this.sliderVal) {
      case 30:
        this.sliderTime = new Date();
        this.sliderTime.setMinutes(this.sliderTime.getHours() - this.sliderVal);
        break;
      case 45:
        this.sliderTime = new Date();
        this.sliderTime.setMinutes(this.sliderTime.getHours() - this.sliderVal);
        break;
      case 60:
        this.sliderTime = new Date();
        this.sliderTime.setMinutes(this.sliderTime.getHours() - this.sliderVal);
        break;
      default:
      // code block
    }
    var installData = [];
    var actives = [];
    var installationList = JSON.parse(sessionStorage.getItem('installs'));
    var selectedInstallations = JSON.parse(
      localStorage.getItem('SelectedInstalls'),
    );

    for (var key in actives) {
      // ... your existing code ...
      installData.push({
        response: response.data,
        activeName: actives[key].name,
      }); // Add this line to populate meetingLabels
    }
    for (var m = 0; m < selectedInstallations.length; m++) {
      // loop over all the installs and find the active ones
      this.installNames.push(selectedInstallations[m].name);
      actives.push(selectedInstallations[m]);
    }

    for (var key in actives) {
      if (this.sliderVal == 15) {
        var data = {
          domain: 'data-lake',
          api: 'getConnectPerMinuteStats',
          properties: {
            'connect-id': actives[key]['connect-id'],
          },
        };
        var response = await this.apiCallsService.sendApi(data);
      } else {
        this.sliderTime = new Date();
        this.sliderTime.setMinutes(this.sliderTime.getHours() - this.sliderVal);
        var timeData = {
          domain: 'data-lake',
          api: 'getConnectPerMinuteStats',
          properties: {
            'connect-id': actives[key]['connect-id'],
            begin: this.sliderTime.getTime(),
          },
        };
        var response = await this.apiCallsService.sendApi(timeData);
      }
      installData.push(response.data);
    }
    var responseData = await this.renderDashGraph(type, installData, actives);
    return responseData;
  };

  renderDashGraph = async (type, response, actives) => {
    this.meetingTot = 0;
    this.userTot = 0;
    this.contentTot = 0;
    var graphType = type;
    var chartArr = [];
    var datasets = [];
    for (var j = 0; j < response.length; j++) {
      var meetingLength = response[j].yAxes[0].dataSeries[0].chartData.length;
      this.meetingTot =
        this.meetingTot +
        response[j].yAxes[0].dataSeries[0].chartData[meetingLength - 1];
      var userLength = response[j].yAxes[1].dataSeries[0].chartData.length;
      this.userTot =
        this.userTot +
        response[j].yAxes[1].dataSeries[0].chartData[userLength - 1];
      var contentLength = response[j].yAxes[2].dataSeries[0].chartData.length;
      this.contentTot =
        this.contentTot +
        response[j].yAxes[2].dataSeries[0].chartData[contentLength - 1];
      for (var i = 0; i < response[j].yAxes.length; i++) {
        if (response[j].yAxes[i].label == graphType.toUpperCase()) {
          response[j].yAxes[0] = response[j].yAxes[i];
          response[j].yAxes.splice(1, 2);
        }
      }
      chartArr.push(this.utilsService.chartDataTransform(response[j]));
      chartArr[j][0].forEach((element) => {
        element.label = actives[j].name + ' ' + element.label;
      });
    }

    for (var k = 0; k < chartArr.length; k++) {
      for (var q = 0; q < chartArr[k][0].length; q++) {
        datasets.push(chartArr[k][0][q]);
      }
    }

    if (type == 'Meetings') {
      this.meetingLabels = chartArr[0][1];
      this.meetingChartData = datasets;
      this.meetingsChartReady = true;
    } else if (type == 'Users') {
      this.usersLabels = chartArr[0][1];
      this.usersChartData = datasets;
      this.usersChartReady = true;
    } else if (type == 'Content') {
      this.contentLabels = chartArr[0][1];
      this.contentChartData = datasets;
      this.contentChartReady = true;
    }
    return response;
  };

  Update = async (subchartParam) => {
    if (subchartParam === 'Meetings') {
      sessionStorage.setItem('subchartName', 'Meetings');
    } else if (subchartParam === 'Users') {
      sessionStorage.setItem('subchartName', 'Users');
    } else if (subchartParam === 'Content') {
      sessionStorage.setItem('subchartName', 'Content');
    }
    var r = '';
    var datasets = [];
    var vals1 = [];
    var vals2 = [];
    var labels = [];
    var lblcol = 0;
    var Names = [];
    var headers = [];
    var installs = JSON.parse(localStorage.getItem('installations'));
    const installNames = [];
    const ids = [];
    const chartDefs = this.utilsService.chartTypes();

    for (var k in chartDefs) {
      if (chartDefs[k].chartName === this.chartName) {
        var chartDef = chartDefs[k];
      }
      if (chartDefs[k].chartName === 'DailyMetrics') {
        var pointDef = chartDefs[k];
      }
    }

    var num;
    var d = new Date();
    d.setDate(d.getDate() - 2); // yesterday to get non null days in metrics
    d.setHours(0);
    d.setMinutes(0);
    sessionStorage.setItem('report-from-date', d.toISOString());
    let pointjqxr = await this.GetChartData(pointDef, this.installNames);

    var pointtots = Array(28).fill(0);
    var pointavg = Array(28).fill(0);
    var pointmax = Array(28).fill(0);
    var cnt = 0;
    for (k in pointjqxr) {
      // each installation - point data - one row only
      var o = await pointjqxr[k].json();
      if (o) {
        //		if (o.transactionStatus.toUpperCase() === "OK"){
        var rows = o.actionList[0].retvalMap['daily-metrics'].rows;
        headers = o.actionList[0].retvalMap['daily-metrics'].headers;
        // create an array to hold the totals
        r = rows.length;
        for (var c in rows[parseInt(r) - 1]) {
          num = parseInt(rows[parseInt(r) - 1][c]) || 0;
          pointtots[c] += num;
          cnt = parseInt(k, 10) + 1;
          pointavg[c] = pointtots[c] / cnt;
          pointmax[c] = num > pointmax[c] ? num : pointmax[c];
        }
      }
    }

    var pointtotals = [];
    if (headers.length === 0) {
      headers = [];
    }
    pointtotals.push(headers);
    pointtotals.push(pointtots);
    pointtotals.push(pointavg);
    pointtotals.push(pointmax);

    sessionStorage.setItem('pointtotals', JSON.stringify(pointtotals));
    let jqxr = await this.GetChartData(chartDef, installNames);

    var lastDat = [];
    var tots = Array(28).fill(0);
    num = 0;
    headers = [];
    for (k in jqxr) {
      // each installation
      vals1 = [];
      vals2 = [];
      labels = [];
      o = await jqxr[k].json();

      if (o) {
        r.toString();
        var subchartName = sessionStorage.getItem('subchartName');
        for (r in chartDef.subcharts) {
          if (chartDef.subcharts[r].name === subchartName) {
            break; // we use the value of r below
          }
        }
        lastDat.push(o);
        rows = o.actionList[0].retvalMap[chartDef.retval].rows;
        headers = o.actionList[0].retvalMap[chartDef.retval].headers;
        var datcol1 = this.findCol(headers, chartDef.subcharts[r].offsets[0]);
        var datcol2 = this.findCol(headers, chartDef.subcharts[r].offsets[1]);
        var lbl1 = headers[datcol1];
        var lbl2 = headers[datcol2];
        for (r in rows) {
          num = isNaN(rows[r][datcol1]) ? 0 : rows[r][datcol1];
          vals1.push(num);
          num = isNaN(rows[r][datcol2]) ? 0 : rows[r][datcol2];
          vals2.push(num);
          labels.push(this.utilsService.getFDate(rows[r][lblcol], 'time'));
        }
        // create an array to hold the totals
        for (c in rows[parseInt(r) - 1]) {
          num = parseInt(rows[parseInt(r) - 1][c]);
          num = isNaN(num) ? 0 : num;
          tots[c] += num;
        }
        this.lineChartLabels = labels;
        var data1 = {
          label: lbl1,
          data: vals1,
        };
        var data2 = {
          label: lbl2,
          data: vals2,
        };
        datasets.push(data1);
        datasets.push(data2);
        Names.push(installNames[k]);
      } // end if(o.trans

      for (r in installs) {
        // set error messages for servers that have gone away
        if (installs[r].name === installNames[k]) {
          installs[r].status = o.messageList[0]
            ? o.messageList[0].key
            : o.transactionStatus.toUpperCase();
        }
      } // for
    } //for

    var totals = [];
    totals.push(headers);
    totals.push(tots);
    sessionStorage.setItem('totals', JSON.stringify(totals));
    //  	if(document.getElementById("loadSpinner")){
    //		document.getElementById("loadSpinner").classList.add("uk-hidden");
    //	}
    var newChartData: Array<any> = [];
    for (var p = 0; p < datasets.length; p++) {
      newChartData.push({});
      newChartData[p].data = datasets[p].data;
      newChartData[p].label = datasets[p].label;
    }
    for (var s = 0; s < newChartData.length; s++) {
      newChartData[s].spanGaps = true;
    }
    this.lineChartData = newChartData;
    if (subchartName === 'Meetings') {
      this.usersChartReady = false;
      this.contentChartReady = false;
      this.meetingsChartReady = true;
    } else if (subchartName === 'Users') {
      this.usersChartReady = true;
      this.contentChartReady = false;
      this.meetingsChartReady = false;
    } else if (subchartName === 'Content') {
      this.usersChartReady = false;
      this.contentChartReady = true;
      this.meetingsChartReady = false;
    }
    return datasets;
  };

  GetColorArray = () => {
    var colorArr: Array<any> = [17];
    colorArr[0] = '#269AD0';
    colorArr[1] = '#282521';
    colorArr[2] = '#25408F';
    colorArr[3] = '#F7A209';
    colorArr[5] = '#6D6A67';
    colorArr[6] = '#8C4600';
    colorArr[7] = '#269AD0';
    colorArr[8] = '#282521';
    colorArr[9] = '#25408F';
    colorArr[10] = '#F7A209';
    colorArr[11] = '#6D6A67';
    colorArr[12] = '#8C4600';
    colorArr[13] = '#269AD0';
    colorArr[14] = '#282521';
    colorArr[15] = '#25408F';
    colorArr[16] = '#F7A209';
    colorArr[17] = '#6D6A67';
    return colorArr;
  };

  findCol = (headers, colname) => {
    var col = headers.findIndex(function (header) {
      return colname === header;
    });
    return col;
  };

  GetChartData = async (chartDef, installNames) => {
    // returns a promise array for each active install
    var sessionkey = '';
    var actives = []; //name,
    var qdata = [];
    var installationList = JSON.parse(sessionStorage.getItem('installs'));

    var selectedInstallations = JSON.parse(
      localStorage.getItem('SelectedInstalls'),
    );

    const sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
    for (var i = 0; i < installationList.length; i++) {
      for (var m = 0; m < selectedInstallations.length; m++) {
        if (installationList[i].key === selectedInstallations[m].key) {
          actives.push(installationList[i]);
        }
      }
    }
    for (var key in actives) {
      // loop over all the installs and find the active ones
      // var ccUrl = actives[key]
      var ccUrl = actives[key]['control-center-url']; // issue in "control-center-url" key
      // var ccUrl = actives[key]["name"]
      var sessionKey = sessionKeys[ccUrl];
      var data = {
        actions: [
          {
            definition: {
              'client-id': actives[key].id,
              verb: chartDef['verb'],
              object: chartDef['object'],
              type: chartDef['type'],
            },
            simpleParams: [],
          },
        ],
        session: { sessionKey: sessionKey },
      };
      var sp;
      for (var c in chartDef['simpleParams']) {
        sp = chartDef['simpleParams'][c];
        if (sp.name === 'connect-installation-id') {
          sp.value = actives[key].id;
        } else if (sp.name === 'minutes') {
          sp.value = sessionStorage.getItem('minutes')
            ? parseInt(sessionStorage.getItem('minutes'))
            : 15;
        } else if (sp.name === 'minutes-back') {
          sp.value = sessionStorage.getItem('minutes-back')
            ? parseInt(sessionStorage.getItem('minutes-back'))
            : 0;
        } else if (sp.name === 'day-count') {
          sp.value = sessionStorage.getItem('day-count')
            ? parseInt(sessionStorage.getItem('day-count'))
            : 15;
        } else if (sp.name === 'group-by-month') {
          sp.value = sessionStorage.getItem('group-by-month')
            ? sessionStorage.getItem('group-by-month')
            : false;
        } else if (sp.name === 'report-from-date') {
          sp.value = sessionStorage.getItem('report-from-date')
            ? sessionStorage.getItem('report-from-date')
            : sessionStorage.getItem('report-from-date');
        }
        data.actions[0].simpleParams.push(sp);
      }
      var rec = fetch(actives[key]['control-center-url'], {
        method: 'POST',
        body: 'json-data=' + encodeURI(JSON.stringify(data)), // data can be `string` or {object}!
        headers: { 'Content-Type': 'application/x-www-form-urlencoded ' },
      });
      qdata.push(rec);
    } //end for
    return Promise.all(qdata);
  };
}
