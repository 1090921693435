import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../shared/services/utils.service';
import { ApiCallsService } from '../shared/services/api-calls.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-disableuser',
  templateUrl: './disableuser.component.html',
  styleUrls: ['./disableuser.component.scss'],
})
export class DisableuserComponent implements OnInit {
  constructor(
    private router: Router,
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
  ) {}

  installList = JSON.parse(localStorage.getItem('SelectedInstalls'));
  disableSelectedInstall;
  daysValue = '90';
  reportKey;
  showInstructions;
  indexVal = 0;
  showInstructionsJSON = JSON.parse(localStorage.getItem('showInst'));
  showSuccess = null;
  multiInstall;
  inactiveResponseCode = null;
  userCountResponse;

  async ngOnInit(): Promise<any> {
    // this.showInstructionsJSON = JSON.parse(localStorage.getItem("showInst"));
    // if (this.showInstructionsJSON == null) {
    //   this.showInstructions = true
    // }
    // else if (this.showInstructionsJSON = "true") {
    //   this.showInstructions = true
    // } else if (this.showInstructionsJSON = "false") {
    //   this.showInstructions = false
    // }
    if (this.installList.length > 1) {
      this.multiInstall = true;
      this.disableSelectedInstall = 'Please Select an Install';
    } else {
      this.multiInstall = false;
      this.disableSelectedInstall = this.installList[0]['connect-id'];
    }
  }

  showInstructionsClick = () => {
    var getInstall = JSON.parse(localStorage.getItem('showInst'));
    if (getInstall == null) {
      this.showInstructions = false;
      localStorage.setItem('showInst', 'false');
    } else if (getInstall == true) {
      this.showInstructions = false;
      localStorage.setItem('showInst', 'false');
    } else if (getInstall == false) {
      this.showInstructions = true;
      localStorage.setItem('showInst', 'true');
    }
  };

  downloadDisableReport = async () => {
    this.inactiveResponseCode = null;
    var data = {
      domain: 'data-lake',
      api: 'inactiveLoginReport',
      properties: {
        'connect-id': this.disableSelectedInstall,
        'days-inactive': this.daysValue,
      },
    };
    var response = await this.apiCallsService.sendApi(data);
    var userCount = response.data.data.accountCount;
    var responseReportKey = response.data.data.reportKey;
    if (userCount == 0) {
      this.inactiveResponseCode = 0;
      return;
    }
    var keySet = {
      connectID: this.disableSelectedInstall,
      userCount: userCount,
    };
    var setapiData = {
      domain: 'data-lake',
      api: 'keyValue',
      data: {
        action: 'set',
        'is-global': 'true',
        key: responseReportKey,
        value: JSON.stringify(keySet),
      },
    };
    var setapiResponse = await this.apiCallsService.sendApi(setapiData);
    var baseURL = this.apiCallsService.urlUtil();
    var downloadURL =
      baseURL + '/download/' + response.data.data['download-key'];
    window.open(downloadURL);
  };

  disableCall = async () => {
    var getapiData = {
      domain: 'data-lake',
      api: 'keyValue',
      data: {
        action: 'get',
        'is-global': 'true',
        key: this.reportKey,
      },
    };
    var getApiResponse = await this.apiCallsService.sendApi(getapiData);
    var parsedResponse = JSON.parse(getApiResponse.data.value);
    this.userCountResponse = parsedResponse.userCount;
    var confirmation = window.confirm(
      'Are you sure you want to disable these ' +
        parsedResponse.userCount +
        ' users?',
    );
    var data = {
      domain: 'data-lake',
      api: 'disableInactiveLogins',
      properties: {
        'connect-id': parsedResponse.connectID,
        'report-name': this.reportKey,
      },
    };
    if (confirmation == true) {
      var response = await this.apiCallsService.sendApi(data);
      if (response.data.data['agent-status'] == 'INCORRECT_EXECUTION_TARGET') {
        this.showSuccess = 3;
      } else if (response.status == 'SUCCESS') {
        this.showSuccess = 0;
        var baseURL = this.apiCallsService.urlUtil();
        var downloadURL =
          baseURL + '/download/' + response.data.data['download-key'];
        window.open(downloadURL);
      } else {
        this.showSuccess = 1;
      }
      setTimeout(() => {
        this.showSuccess = null;
      }, 12000);
    } else {
      return;
    }
    console.log(response);
  };
}
