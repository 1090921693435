<div class="content-card">
  <div
    *ngIf="renderNewTicket == false && renderTicket == false"
    class="ticket-loader"
  >
    <h2>Please Wait -- Your Ticket is Loading</h2>
    <div class="progress-bar">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
  <div class="ticketBody" *ngIf="renderNewTicket == true">
    <div class="row">
      <div class="col-sm-10">
        <h2>Edit Ticket</h2>
      </div>
      <div class="headerBar">
        <p>Ticket Information</p>
      </div>
      <div class="col-sm-3 createdBy">
        <p>
          Created By: <b>{{ newTicketAuthor }}</b>
        </p>
      </div>
      <div class="offset-sm-1 col-sm-10">
        <div class="row">
          <div class="col-sm-12 form-group">
            <div class="row">
              <div class="col-sm-3">
                <label for="statusSelect">Status</label>
                <select
                  disabled
                  class="form-control"
                  [(ngModel)]="selectStatus"
                  id="statusSelect"
                >
                  <option *ngFor="let option of statusArr; let i = index">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="prioritySelect">Priority</label>
                <select
                  class="form-control"
                  [(ngModel)]="selectPriority"
                  id="prioritySelect"
                >
                  <option
                    *ngFor="let option of options.priorities; let i = index"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="reporterSelect">Reporter</label>
                <select
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="selectReporter"
                  id="reporterSelect"
                >
                  <option>{{ selectReporter }}</option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="typeSelect">Type</label>
                <select
                  class="form-control"
                  [(ngModel)]="selectType"
                  id="typeSelect"
                >
                  <option *ngFor="let option of options.type; let i = index">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12">
                <label for="urlInput">URL</label>
                <input
                  class="form-control"
                  [(ngModel)]="inputURL"
                  id="urlInput"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="detailsBar col-sm-12">
            <p>Description</p>
          </div>
          <div class="col-sm-12 form-group">
            <label for="productSelect">Product</label>
            <select
              class="form-control"
              [(ngModel)]="selectProduct"
              id="productSelect"
            >
              <option *ngFor="let option of options.product; let i = index">
                {{ option }}
              </option>
            </select>
            <label for="urlInput">Subject</label>
            <textarea
              class="form-control"
              [(ngModel)]="inputSubject"
              id="subjectInput"
              type="textarea"
            ></textarea>
            <label for="urlInput">Description</label>
            <textarea
              class="form-control"
              [(ngModel)]="inputDesc"
              id="descInput"
            ></textarea>
          </div>
          <div class="detailsBar col-sm-12">
            <p>Attachments</p>
          </div>
          <div id="drop-area" style="clear: both" class="offset-sm-2 col-sm-8">
            <form class="text-center col-sm-12 browse-input">
              <span uk-icon="icon: cloud-upload"></span>
              <span class="">Drag files here or</span>
              <input
                style="display: none"
                (change)="handleFiles($event)"
                type="file"
                id="fileElem"
                multiple
              />
              <label class="btn btn-primary browse-btn" for="fileElem"
                >Browse</label
              >
            </form>
            <div class="col-sm-12 attach-text">
              5MB MAX UPLOAD<br />
              .jpg, .png, .pdf, .pptx, .ppt, .xlsx, .csv, .docx, .doc <br />
              Duplicate files will not be added to the upload list, even the
              same file with a different name
            </div>
            <div id="gallery" class="col-sm-12"></div>
          </div>
          <!-- <div class="offset-sm-5 col-sm-2 text-center">
                        <button class="submit-button btn btn-primary" (click)="addSaveTicket1()">Create Ticket</button>
                    </div> -->
        </div>
      </div>
    </div>
  </div>

  <!---View-Edit-Tickets-->

  <div class="ticketBody" *ngIf="renderTicket == true">
    <div class="row">
      <div class="col-sm-10">
        <h2>{{ ticketSubject }}</h2>
      </div>
      <div class="col-sm-2 text-right">
        <p>TICKET #{{ ticketNumber }}</p>
      </div>
      <div class="headerBar">
        <p>Ticket Information</p>
      </div>
      <div class="col-sm-3 createdBy">
        <p>
          Created By: <b>{{ createdName }}</b>
        </p>
        <!-- <p>Last Modified By: <b>{{lastModifieddate}}</b></p> -->
        <!-- <td>{{ticket.lastModifiedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}}</td> -->
      </div>
      <div class="offset-sm-1 col-sm-10">
        <div class="row">
          <div class="col-sm-12 form-group">
            <div class="row">
              <div class="col-sm-3">
                <label for="statusSelect">Status</label>
                <select
                  class="form-control"
                  [(ngModel)]="selectStatus"
                  id="statusSelect"
                >
                  <option *ngFor="let option of statusArr; let i = index">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="prioritySelect">Priority</label>
                <select
                  class="form-control"
                  [(ngModel)]="selectPriority"
                  id="prioritySelect"
                >
                  <option
                    *ngFor="let option of options.priorities; let i = index"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="reporterSelect">Reporter</label>
                <select
                  class="form-control"
                  [(ngModel)]="selectReporter"
                  id="reporterSelect"
                >
                  <option>{{ selectReporter }}</option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="typeSelect">Type</label>
                <select
                  class="form-control"
                  [(ngModel)]="selectType"
                  id="typeSelect"
                >
                  <option *ngFor="let option of options.type; let i = index">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12">
                <label for="urlInput">URL</label>
                <input
                  class="form-control"
                  [(ngModel)]="inputURL"
                  id="urlInput"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="detailsBar col-sm-12">
            <p>Description</p>
          </div>
          <div class="col-sm-12 form-group">
            <label for="productSelect">Product</label>
            <select
              class="form-control"
              [(ngModel)]="selectProduct"
              id="productSelect"
            >
              <option *ngFor="let option of options.product; let i = index">
                {{ option }}
              </option>
            </select>
            <label for="urlInput">Subject</label>
            <textarea
              required
              minlength="4"
              class="form-control"
              [(ngModel)]="inputSubject"
              id="subjectInput"
              type="textarea"
            ></textarea>
            <label for="urlInput">Description</label>
            <textarea
              required
              minlength="4"
              style="height: 200px"
              class="form-control"
              [(ngModel)]="inputDesc"
              id="descInput"
            ></textarea>
          </div>
          <div class="detailsBar col-sm-12">
            <p>Attachments</p>
          </div>
          <!-- <div class="attachmentArea offset-sm-1 col-sm-10">
                        <a download="{{attachment.name}}" *ngFor="let attachment of attachmentArr; let i = index"
                            href="data:{{attachment.contentType}};base64,{{attachment.body}}"><img
                                src="/assets/images/doc-icon.png" style="height:50px; width:50px;" /></a>
                        <a *ngFor="let attachment of attachmentArr; let i = index"
                            href="{{attachment.contentType}};base64,{{attachment.body}}"><i class="fa fa-times" aria-hidden="true"></i></a>
                    </div> -->
          <div class="attachmentArea offset-sm-1 col-sm-10">
            <div
              *ngFor="let attachment of attachmentArr; let i = index"
              class="attachment"
            >
              <a
                download="{{ attachment.name }}"
                href="data:{{ attachment.contentType }};base64,{{
                  attachment.body
                }}"
              >
                <img
                  src="/assets/images/doc-icon.png"
                  style="height: 50px; width: 50px"
                />
                <span>{{ attachment.name }}</span>
              </a>
              <a
                class="btn btn-outline-dar kmx-1"
                [class.disabled-link]="!attachment.isCustomerAttachment"
                (click)="deleteAttachment(i)"
                title="Delete Tickets"
                data-toggle="tooltip"
                style="float: right"
              >
                <mat-icon>delete</mat-icon>
              </a>
              <a
                class="btn btn-outline-dar kmx-1"
                (click)="downloadAttachment(attachment)"
                title="Save Attachments"
                data-toggle="tooltip"
                style="float: right"
              >
                <mat-icon>save_alt</mat-icon>
              </a>
            </div>
          </div>
          <div id="drop-area" style="clear: both" class="offset-sm-2 col-sm-8">
            <form class="col-sm-12 text-center browse-input">
              <span uk-icon="icon: cloud-upload"></span>
              <span class="">Drag files here or</span>
              <input
                style="display: none"
                (change)="handleFiles($event)"
                type="file"
                id="fileElem"
                multiple
              />
              <label class="browse-btn btn btn-primary" for="fileElem"
                >BROWSE</label
              >
            </form>
            <div class="col-sm-12 attach-text">
              5MB MAX UPLOAD<br />
              .jpg, .png, .pdf, .pptx, .ppt, .xlsx, .csv, .docx, .doc <br />
              Duplicate files will not be added to the upload list, even the
              same file with a different name
            </div>
            <div id="gallery" class="col-sm-12"></div>
          </div>
          <div class="offset-sm-5 col-sm-2 text-center">
            <button
              class="submit-button btn btn-primary"
              [disabled]="!isTicketEditorDelete"
              (click)="addSaveTicket()"
            >
              UPDATE TICKET
            </button>
          </div>
        </div>
      </div>
    </div>

    <!----Edit-Comment---->
    <div class="comments-section">
      <h3>Comments</h3>
      <div>
        <div
          [hidden]="comment.published !== true"
          *ngFor="let comment of commentsArr; let i = index"
        >
          <div class="comment-body">
            <p class="comment-creator">{{ comment.createdByName }}</p>
            <p class="date">{{ comment.createdDate | date: "MM/dd/yyyy" }}</p>
            <p class="comment-text" type="text">{{ comment.commentBody }}</p>

            <p *ngIf="editFlag != i && comment.isCommentEditorDelete">
              <button
                style="float: right"
                [disabled]="!comment.isCommentEditorDelete"
                (click)="deleteComment(comment)"
                class="btn btn-primary"
              >
                DELETE
              </button>
              <button
                style="float: right"
                [disabled]="!comment.isCommentEditorDelete"
                (click)="editComment(comment); editFlag = i"
                class="btn btn-primary mx-1"
              >
                EDIT
              </button>
            </p>
          </div>
          <p></p>
          <div
            style="margin-bottom: 5rem"
            *ngIf="editFlag == i && editFlag != null"
          >
            <form>
              <textarea
                class="form-control"
                id="editCommentValue"
                value="{{ edit_comment.commentBody }}"
                type="text"
              ></textarea>
              <p>
                <button
                  style="float: right"
                  (click)="saveEditComment('cancel'); (editFlag != null)"
                  class="btn btn-primary mx-2"
                >
                  CANCEL
                </button>
                <button
                  style="float: right"
                  (click)="saveEditComment('save')"
                  class="btn btn-primary"
                >
                  SAVE
                </button>
                <label class="mx-2" style="float: right; margin-top: 4px"
                  >public</label
                >
                <input
                  style="float: right; margin-top: 12px"
                  (change)="onIsPublishedChange($event)"
                  [checked]="IsPublished"
                  name="IsPublished"
                  type="checkbox"
                />
              </p>
            </form>
          </div>
        </div>
      </div>
      <!-- <label for="comments">Comments</label> -->
      <!-- <input type="text" style="clear:both; height:150px" [(ngModel)]="inputComment" id="comments" name="comments" placeholder="Comment" class="form-control" >
              <button style="float:right; clear:both" (click)="addSaveComment()" class="btn btn-primary">COMMENT</button>  -->
    </div>
  </div>
  <div style="top: right" [hidden]="!commentToast">
    <ngb-toast style="top: right" class="alert alert-success" id="commentToast">
      <p>Edit Comment Added!</p>
    </ngb-toast>
  </div>
  <div style="top: right" [hidden]="!commentdelete">
    <ngb-toast
      style="top: right"
      class="alert alert-success"
      id="commentdelete"
    >
      <p>Your Comment Has Bin Deleted...</p>
    </ngb-toast>
  </div>
</div>
