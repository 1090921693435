<div class="ticketTop content-card">
  <h2>
    Ticket Activity
    <span id="ticketDate"
      >NEW OR CONTINUED SINCE {{ lastmonth | date: "fullDate" }}</span
    >
  </h2>
  <div class="row ticket-status">
    <div class="col-md-12 col-lg-6 col-xl-3">
      <div class="col-sm-12" id="Open">
        <div class="row">
          <div class="icon-container col-4">
            <mat-icon class="supportIcon">lock_open</mat-icon>
          </div>
          <div class="statusText col-8">
            <p class="number">{{ ticketOpenNum }}</p>
            <p>OPEN</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-3">
      <div class="col-sm-12" id="onHold">
        <div class="row">
          <div class="icon-container col-4">
            <mat-icon class="supportIcon">access_time</mat-icon>
          </div>
          <div class="statusText col-8">
            <p class="number">{{ ticketOnHoldNum }}</p>
            <p>ON HOLD</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-3">
      <div class="col-sm-12" id="awaiting">
        <div class="row">
          <div class="icon-container col-4">
            <mat-icon class="supportIcon">subdirectory_arrow_left</mat-icon>
          </div>
          <div class="statusText col-8">
            <p class="number">{{ ticketAwaitingNum }}</p>
            <p>AWAITING RESPONSE</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-3">
      <div class="col-sm-12" id="Closed">
        <div class="row">
          <div class="icon-container col-4">
            <mat-icon class="supportIcon">lock</mat-icon>
          </div>
          <div class="statusText col-8">
            <p class="number">{{ ticketClosedNum }}</p>
            <p>CLOSED</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="content-card ticket-filter">
  <h2 style="margin-bottom: 20px">Filter Tickets</h2>
  <div class="row">
    <div class="col-sm-12 col-md-4">
      <label for="status">Ticket Status</label>
      <select
        class="form-control"
        id="filter-status"
        (change)="filterTickets()"
        [(ngModel)]="filterTicketStatus"
        name="status"
      >
        <option selected value="">All Statuses</option>
        <option value="opentix">Open</option>
        <option value="holdingtix">On Hold</option>
        <option value="Escalatedtix">Awaiting Response</option>
        <option value="closedtix">Closed</option>
      </select>
    </div>
    <div class="col-sm-12 col-md-4">
      <label for="priority">Priority</label>
      <select
        class="form-control"
        id="filter-priority"
        (change)="filterTickets()"
        [(ngModel)]="filterPriority"
        name="priority"
      >
        <option selected value="">All Priorities</option>
        <option value="p4">P4 - Low</option>
        <option value="p3">P3 - Medium</option>
        <option value="p2">P2 - High</option>
        <option value="p1">P1 - Very High</option>
      </select>
    </div>
    <div class="col-sm-12 col-md-4">
      <label for="reporter">Reporter</label>
      <select
        (change)="filterTickets()"
        class="form-control"
        id="filter-reporter"
        [(ngModel)]="filterReporter"
        name="reporter"
      >
        <option value="">All Reporters</option>
        <option [value]="reporter" *ngFor="let reporter of reportersArr">
          {{ reporter }}
        </option>
      </select>
    </div>
  </div>
</div>
<!----------Recent Tickets---All Statuses------------>
<div id="ticket-list" class="content-card">
  <div
    style="margin-bottom: 30px"
    *ngIf="hiddenTable == true"
    class="ticket-loader"
  >
    <h2>Please Wait -- Loading Tickets</h2>
    <div class="progress-bar">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
  <div [hidden]="hiddenTable == true" class="row">
    <h2 style="width: 100%" id="supportHead">
      {{ ticketHeading }} -
      <span *ngIf="filterTicketStatus == ''">All Statuses</span>
      <span *ngIf="filterTicketStatus == 'opentix'">Open</span>
      <span *ngIf="filterTicketStatus == 'holdingtix'">On Hold</span>
      <span *ngIf="filterTicketStatus == 'Escalatedtix'"
        >Awaiting Response</span
      >
      <span *ngIf="filterTicketStatus == 'closedtix'">Closed</span>
      <span (click)="showTicketToggle(false)" *ngIf="showAll == true"
        ><a
          style="
            position: relative;
            left: 25%;
            top: -3px;
            padding: 0.25rem 0.75rem;
          "
          type="button"
          class="btn btn-primary"
          >Show Recent Tickets</a
        ></span
      >
      <span (click)="showTicketToggle(true)" *ngIf="showAll == false"
        ><a
          style="
            position: relative;
            left: 25%;
            top: -3px;
            padding: 0.25rem 0.75rem;
          "
          type="button"
          class="btn btn-primary"
          >Show All Tickets</a
        ></span
      >
      <span style="float: right"
        ><a
          [routerLink]="['/ticket']"
          id="newTicket"
          style="position: relative; top: -3px; padding: 0.25rem 0.75rem"
          type="button"
          class="btn btn-primary"
          >New Ticket</a
        ></span
      >
    </h2>
    <div class="col-sm-12" *ngIf="showTicketListTable == true">
      <div class="table-responsive">
        <table datatable id="tix-table" class="table row-border hover">
          <thead>
            <tr>
              <th scope="col">TICKET ID</th>
              <th scope="col">STATUS</th>
              <th scope="col">PRIORITY</th>
              <th scope="col">REPORTER</th>
              <th id="ticket-subject" scope="col">SUBJECT</th>
              <th class="dropdown" [hidden]="dateUpdated == false" scope="col">
                {{ dateToggle }}
                <div id="dropdown-content">
                  <a (click)="dateSwitch('hello')"
                    ><span
                      style="
                        margin-right: 5px;
                        position: relative;
                        top: 1px;
                        width: 15px;
                        height: 15px;
                        border-radius: 15px;
                        border: 1px solid #ccc;
                        display: inline-block;
                        background-color: white;
                      "
                    ></span>
                    Date Updated
                  </a>
                  <a
                    ><span
                      style="
                        margin-right: 5px;
                        position: relative;
                        top: 1px;
                        width: 15px;
                        height: 15px;
                        border-radius: 15px;
                        border: 1px solid transparent;
                        display: inline-block;
                        background-color: #1e87f0;
                      "
                    ></span
                    >Date Created</a
                  >
                </div>
              </th>
              <th class="dropdown" [hidden]="dateUpdated == true">
                {{ dateToggle }}
                <div id="dropdown-content">
                  <a
                    ><span
                      style="
                        margin-right: 5px;
                        position: relative;
                        top: 1px;
                        width: 15px;
                        height: 15px;
                        border-radius: 15px;
                        border: 1px solid transparent;
                        display: inline-block;
                        background-color: #1e87f0;
                      "
                    ></span
                    >Date Updated
                  </a>
                  <a (click)="dateSwitch(true)"
                    ><span
                      style="
                        margin-right: 5px;
                        position: relative;
                        top: 1px;
                        width: 15px;
                        height: 15px;
                        border-radius: 15px;
                        border: 1px solid #ccc;
                        display: inline-block;
                        background-color: white;
                      "
                    ></span
                    >Date Created
                  </a>
                </div>
              </th>
              <th id="view-ticket-col" scope="col"></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ticket of ticketListRecords">
              <td>
                <a [routerLink]="['/ticket', ticket.id]">{{
                  ticket.caseNumber
                }}</a>
              </td>
              <td>
                <span
                  style="visibility: hidden; position: absolute"
                  *ngIf="
                    ticket.status == 'On Hold' ||
                    ticket.status == 'Pending Customer Response' ||
                    ticket.status == 'Pending confirmation issue is resolved' ||
                    ticket.status == 'Scheduled for Maintenance'
                  "
                  >holdingtix -</span
                >
                <span
                  style="visibility: hidden; position: absolute"
                  *ngIf="
                    ticket.status == 'New' ||
                    ticket.status == 'Working' ||
                    ticket.status == 'Can be closed' ||
                    ticket.status == 'RCA'
                  "
                  >opentix -</span
                >
                <span
                  style="visibility: hidden; position: absolute"
                  *ngIf="
                    ticket.status == 'Escalated Internally' ||
                    ticket.status == 'Escalated to Adobe' ||
                    ticket.status == 'Escalated to Third Party'
                  "
                  >Escalatedtix -</span
                >
                <span
                  style="visibility: hidden; position: absolute"
                  *ngIf="
                    ticket.status == 'Closed' ||
                    ticket.status == 'Closed - Resolved' ||
                    ticket.status == 'Closed - No response from customer' ||
                    ticket.status == 'Closed - Duplicate Ticket' ||
                    ticket.status == 'Closed - Unresolvable' ||
                    ticket.status == 'Closed - Pending patch release' ||
                    ticket.status == 'Closed - Pending product upgrade' ||
                    ticket.status == 'Closed - Bug Filed'
                  "
                  >closedtix -</span
                >
                {{ ticket.status }}
              </td>
              <td>{{ ticket.priority }}</td>
              <td>{{ ticket.displayName }}</td>
              <td>{{ ticket.subject }}</td>
              <!-- <td [hidden]="dateUpdated == false">{{ticket.createdDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}}</td>
                    <td [hidden]="dateUpdated == true">{{ticket.lastModifiedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}}</td> -->
              <td [hidden]="dateUpdated == false">
                {{
                  ticket.createdDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })
                }}
              </td>
              <td [hidden]="dateUpdated == true">
                {{
                  ticket.lastModifiedDate.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })
                }}
              </td>

              <td class="d-flex flex-row">
                <!-- <a [routerLink]="['/ticket', ticket.id]"  type="button" class="btn btn-outline-dark">View</a>
                        <a [routerLink]="['/ticket', ticket.id]"  type="button" class="btn btn-outline-dark mx-1">Edit</a>
                        <a [routerLink]="['/ticket', ticket.id]"  type="button" class="btn btn-outline-dark mx-1">Delete</a> -->
                <a
                  class="btn btn-outline-dar kmx-1"
                  [routerLink]="['/ticket', ticket.id]"
                  title="View Tickets"
                  data-toggle="tooltip"
                  ><mat-icon>remove_red_eye</mat-icon></a
                >
                <!-- <a class="btn btn-outline-dar kmx-1" [routerLink]="['/edit-ticket', ticket.id]" title="Edit Tickets" data-toggle="tooltip"><mat-icon>edit</mat-icon></a> -->
                <!-- <a class="btn btn-outline-dar kmx-1" [routerLink]="['/ticket', ticket.id]" title="Delete Tickets" data-toggle="tooltip"><mat-icon>delete</mat-icon></a> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div *ngIf="ticketTableSize > 15"></div>
    </div>
  </div>
</div>
