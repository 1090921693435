<nav class="row" id="dash-header">
  <div class="logo-col col-1 col-xl-2">
    <img
      style="width: 100%; max-height: 100px"
      id="dash-coso-logo"
      src="../../../assets/images/logo_coso_cloud.svg"
    />
  </div>
  <div class="spacer-col col-7 col-xl-6"></div>
  <div class="date-col col-sm-2">
    <div class="row">
      <div class="date-flex-align">
        <p>{{ todayDate }}</p>
      </div>
    </div>
  </div>
  <div class="datacenter-col col-sm-2">
    <div class="d-flex datacenter-icon" *ngFor="let datacenter of datacenters">
      <img
        placement="bottom"
        [ngbTooltip]="datacenter"
        class="align-self-center dc-icons"
        [src]="getImg(datacenter)"
      />
    </div>

    <div class="date-col col-sm-5" style="margin-top: auto">
      <div>
        <div
          class="date-flex-align"
          style="margin-top: 29px; display: flex; align-items: center"
        >
          <p>{{ firstname }}</p>
        </div>
      </div>
    </div>
  </div>
</nav>
