<div class="content-card">
  <div
    *ngIf="renderNewTicket == false && renderTicket == false"
    class="ticket-loader"
  >
    <h2>Please Wait -- Your Ticket is Loading</h2>
    <div class="progress-bar">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
  <div class="ticketBody" *ngIf="renderNewTicket == true">
    <div class="row">
      <div class="col-sm-10">
        <h2>New Ticket</h2>
      </div>
      <div class="headerBar">
        <p>Ticket Information</p>
      </div>
      <div class="col-sm-3 createdBy">
        <p>
          Created By: <b>{{ newTicketAuthor }}</b>
        </p>
      </div>
      <div class="offset-sm-1 col-sm-10">
        <div class="row">
          <div class="col-sm-12 form-group">
            <div class="row">
              <div class="col-sm-3">
                <label for="statusSelect">Status</label>
                <select
                  disabled
                  class="form-control"
                  [(ngModel)]="selectStatus"
                  id="statusSelect"
                >
                  <option *ngFor="let option of statusArr; let i = index">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="prioritySelect">Priority</label>
                <select
                  class="form-control"
                  [(ngModel)]="selectPriority"
                  id="prioritySelect"
                >
                  <option
                    *ngFor="let option of options.priorities; let i = index"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="reporterSelect">Reporter</label>
                <select
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="selectReporter"
                  id="reporterSelect"
                >
                  <option>{{ selectReporter }}</option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="typeSelect">Type</label>
                <select
                  class="form-control"
                  [(ngModel)]="selectType"
                  id="typeSelect"
                >
                  <option *ngFor="let option of options.type; let i = index">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12">
                <label for="urlInput">URL</label>
                <input
                  class="form-control"
                  [(ngModel)]="inputURL"
                  id="urlInput"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="detailsBar col-sm-12">
            <p>Description</p>
          </div>
          <div class="col-sm-12 form-group">
            <label for="productSelect">Product</label>
            <select
              class="form-control"
              [(ngModel)]="selectProduct"
              id="productSelect"
            >
              <option *ngFor="let option of options.product; let i = index">
                {{ option }}
              </option>
            </select>
            <label for="urlInput">Subject</label>
            <textarea
              class="form-control"
              [(ngModel)]="inputSubject"
              id="subjectInput"
              type="textarea"
            ></textarea>
            <label for="urlInput">Description</label>
            <textarea
              class="form-control"
              [(ngModel)]="inputDesc"
              id="descInput"
            ></textarea>
          </div>
          <div class="detailsBar col-sm-12">
            <p>Attachments</p>
          </div>
          <div id="drop-area" style="clear: both" class="offset-sm-2 col-sm-8">
            <form class="text-center col-sm-12 browse-input">
              <span uk-icon="icon: cloud-upload"></span>
              <span class="">Drag files here or</span>
              <input
                style="display: none"
                (change)="handleFiles($event)"
                type="file"
                id="fileElem"
                multiple
              />
              <label class="btn btn-primary browse-btn" for="fileElem"
                >Browse</label
              >
            </form>
            <div class="col-sm-12 attach-text">
              5MB MAX UPLOAD<br />
              .jpg, .png, .pdf, .pptx, .ppt, .xlsx, .csv, .docx, .doc <br />
              Duplicate files will not be added to the upload list, even the
              same file with a different name
            </div>
            <div id="gallery" class="col-sm-12"></div>
          </div>
          <div class="offset-sm-5 col-sm-2 text-center">
            <button
              class="submit-button btn btn-primary"
              (click)="addSaveTicket()"
            >
              Create Ticket
            </button>
            <!-- <button class="submit-button btn btn-primary" (click)="addSaveTicket()">
            <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span *ngIf="!isLoading">Create Ticket</span>
          </button>           -->
          </div>
        </div>
      </div>
    </div>
  </div>

  <!---View-Tickets-->

  <div class="ticketBody" *ngIf="renderTicket == true">
    <div class="row">
      <div class="col-sm-10">
        <h2>{{ ticketSubject }}</h2>
      </div>
      <div class="col-sm-2 text-right">
        <p>TICKET #{{ ticketNumber }}</p>
      </div>
      <div class="headerBar">
        <p>Ticket Information</p>
      </div>
      <div class="col-sm-3 createdBy">
        <p>
          Created By: <b>{{ createdName }}</b>
        </p>
        <!-- <p>Last Modified By: <b>{{lastModifiedName}}</b></p> -->
      </div>
      <div class="offset-sm-1 col-sm-10">
        <div class="row">
          <div class="col-sm-12 form-group">
            <div class="row">
              <div class="col-sm-3">
                <label for="statusSelect">Status</label>
                <select
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="selectStatus"
                  id="statusSelect"
                >
                  <option *ngFor="let option of statusArr; let i = index">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="prioritySelect">Priority</label>
                <select
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="selectPriority"
                  id="prioritySelect"
                >
                  <option
                    *ngFor="let option of options.priorities; let i = index"
                  >
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="reporterSelect">Reporter</label>
                <select
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="selectReporter"
                  id="reporterSelect"
                >
                  <option>{{ selectReporter }}</option>
                </select>
              </div>
              <div class="col-sm-3">
                <label for="typeSelect">Type</label>
                <select
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="selectType"
                  id="typeSelect"
                >
                  <option *ngFor="let option of options.type; let i = index">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12">
                <label for="urlInput">URL</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="inputURL"
                  id="urlInput"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div class="detailsBar col-sm-12">
            <p>Description</p>
          </div>
          <div class="col-sm-12 form-group">
            <label for="productSelect">Product</label>
            <select
              disabled="disabled"
              class="form-control"
              [(ngModel)]="selectProduct"
              id="productSelect"
            >
              <option *ngFor="let option of options.product; let i = index">
                {{ option }}
              </option>
            </select>
            <label for="urlInput">Subject</label>
            <textarea
              required
              minlength="4"
              disabled="disabled"
              class="form-control"
              [(ngModel)]="inputSubject"
              id="subjectInput"
              type="textarea"
            ></textarea>
            <label for="urlInput">Description</label>
            <textarea
              required
              minlength="4"
              disabled="disabled"
              style="height: 200px"
              class="form-control"
              [(ngModel)]="inputDesc"
              id="descInput"
            ></textarea>
          </div>
          <div class="detailsBar col-sm-12">
            <p>Attachments</p>
          </div>
          <div
            *ngFor="let attachment of attachmentArr; let i = index"
            class="attachmentArea offset-sm-1 col-sm-10"
          >
            <a
              download="{{ attachment.name }}"
              href="data:{{ attachment.contentType }};base64,{{
                attachment.body
              }}"
              ><img
                src="/assets/images/doc-icon.png"
                style="height: 50px; width: 50px"
              /><span>{{ attachment.name }}</span></a
            >
          </div>
          <div id="drop-area" style="clear: both" class="offset-sm-2 col-sm-8">
            <!-- <form class="col-sm-12 text-center browse-input">
          <span uk-icon="icon: cloud-upload"></span> <span class="">Drag files here or</span>
          <input style="display:none" (change)="handleFiles($event)" type="file" id="fileElem" multiple >
          <label class="browse-btn btn btn-primary" for="fileElem">BROWSE</label>
        </form> -->
            <div class="col-sm-12 attach-text">
              5MB MAX UPLOAD<br />
              .jpg, .png, .pdf, .pptx, .ppt, .xlsx, .csv, .docx, .doc <br />
              Duplicate files will not be added to the upload list, even the
              same file with a different name
            </div>
            <div id="gallery" class="col-sm-12"></div>
          </div>
          <div class="offset-sm-5 col-sm-2 text-center">
            <!-- <button class="submit-button btn btn-primary" (click)="addSaveTicket()">SAVE TICKET</button> -->
          </div>
        </div>
      </div>
    </div>

    <!------Comments---->
    <!-- <div class="comments-section">
          <h3>Comments</h3>
          <div>
              <div [hidden]="comment.published !== true" *ngFor="let comment of commentsArr; let i = index">
                  <div class="comment-body">
                  <p class="comment-creator">{{comment.createdByName}}</p>
                  <p class="date">{{comment.createdDate | date:'MM/dd/yyyy'}}</p>
                  <p class="comment-text">{{comment.commentBody}}</p>
                  </div>
                  <hr/>
              </div>
          </div>
          <textarea style="clear:both; height:150px" [(ngModel)]="inputComment" name="comments" placeholder="Comment" class="form-control" ></textarea>
          <button style="float:right; clear:both" (click)="addSaveComment()" class="btn btn-primary">COMMENT</button>
          <div class="mx-3" style="float:right;">
            <input class="checkbox" (change)="onIsPublishedChange($event)" [checked]="IsPublished"  name="IsPublished" type="checkbox"/>
            <label class="mx-2">public</label>
          </div>
      </div> -->

    <div class="comments-section">
      <h3>Comments</h3>
      <div>
        <textarea
          style="clear: both; height: 150px"
          [(ngModel)]="inputComment"
          name="comments"
          placeholder="Comment"
          class="form-control"
        ></textarea>
        <button
          style="float: right; clear: both"
          (click)="addSaveComment()"
          class="btn btn-primary"
        >
          COMMENT
        </button>
        <div class="mx-3" style="float: right">
          <input
            class="checkbox"
            (change)="onIsPublishedChange($event)"
            [checked]="IsPublished"
            name="IsPublished"
            type="checkbox"
          />
          <label class="mx-2">public</label>
        </div>
      </div>
      <div style="margin-top: 4rem !important">
        <div
          [hidden]="comment.published !== true"
          *ngFor="let comment of commentsArr; let i = index"
        >
          <div class="comment-body">
            <p class="comment-creator">{{ comment.createdByName }}</p>
            <p class="date">{{ comment.createdDate | date: "MM/dd/yyyy" }}</p>
            <p class="comment-text">{{ comment.commentBody }}</p>
          </div>
          <hr />
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="!commentToast">
    <ngb-toast class="alert alert-success" id="commentToast">
      <p>Comment Added!</p>
    </ngb-toast>
  </div>
</div>
