<!--------Edit-Installs------->

<div class="content-card">
  <!-- <div  *ngIf="renderNewTicket == false && renderTicket == false" class="ticket-loader">
        <h2>Please Wait -- Your Edit Instans is Loading</h2>
        <div class="progress-bar">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div> -->
  <div class="ticketBody">
    <div class="row">
      <div class="col-sm-10">
        <h2>Edit Installation</h2>
      </div>
      <div class="headerBar">
        <p>Install Information</p>
      </div>
      <div class="col-sm-3 createdBy">
        <!-- <p>Created By: <b></b></p> -->
      </div>
      <div class="offset-sm-1 col-sm-10">
        <div class="row" *ngIf="getInstallationData">
          <div class="col-sm-12 form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="urlInput">Id</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.id"
                  id="id"
                  type="number"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Name</label>
                <input
                  class="form-control"
                  [(ngModel)]="getInstallationData.name"
                  id="subjectInput"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Dcops</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.dcops"
                  id="Dcops"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Application</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.application"
                  id="descInput"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Instance Name</label>
                <!-- <input  class="form-control" [(ngModel)]="getInstallationData.instance_name" id="instancename" type="text" /> -->
                <select
                  *ngIf="selectedInstance"
                  class="form-control"
                  [(ngModel)]="selectedInstance"
                  name="instance"
                  (change)="get_dbname()"
                >
                  <!-- <option selected value="getInstallationData.instance_id">{{getInstallationData.instance_name}}</option> -->
                  <option
                    [value]="instance.id"
                    *ngFor="let instance of get_instances; let i = index"
                  >
                    {{ instance.host }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Database Name</label>
                <!-- <input  class="form-control" [(ngModel)]="getInstallationData.dbname" id="dbname" type="text" /> -->
                <select
                  class="form-control"
                  name="dbname"
                  [(ngModel)]="selectedDbName"
                >
                  <option selected value="">
                    {{ getInstallationData.dbname }}
                  </option>
                  <option *ngFor="let dbnames of dbnamelist; let i = index">
                    {{ dbnames }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12">
                <label for="urlInput">User Name</label>
                <input
                  class="form-control"
                  [(ngModel)]="getInstallationData.username"
                  id="username"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Password</label>
                <input
                  class="form-control"
                  [(ngModel)]="getInstallationData.password"
                  id="password"
                  type="password"
                />
              </div>

              <div class="col-sm-12">
                <label for="urlInput">Closed</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.closed"
                  id="closed"
                  type="text"
                />
              </div>
              <!-- <label for="urlInput">Name</label>
                            <textarea required minlength="4" disabled="disabled" class="form-control" [(ngModel)]="getInstallationData.name" id="subjectInput" type="textarea"></textarea>
                            <label for="urlInput">Application</label>
                            <textarea required minlength="4" disabled="disabled" style="height:200px"  class="form-control" [(ngModel)]="getInstallationData.application" id="descInput" ></textarea> -->
            </div>
          </div>
          <div class="offset-sm-5 col-sm-2 text-center">
            <button
              class="submit-button btn btn-primary"
              (click)="EditInstallations()"
            >
              Update Install
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
