<div class="content-card">
  <div *ngIf="initialAccount == false" class="row">
    <div class="offset-8 col-4">
      <button
        *ngIf="accountTable == true"
        class="btn btn-primary float-right"
        (click)="showListAccounts = true; accountTable = false"
      >
        Back to Account List
      </button>
      <button
        *ngIf="accountTable == false"
        class="btn btn-primary float-right"
        (click)="showListAccounts = false; accountTable = true"
      >
        Back to Last Account
      </button>
    </div>
  </div>
  <div *ngIf="showListAccounts == true">
    <div class="row filter-row">
      <div class="col-sm-1">
        <button (click)="refreshList()" class="btn btn-primary">Refresh</button>
      </div>
      <div style="padding-top: 5px" class="col-sm-5">
        <span>Filters: </span>
        <input
          (click)="refreshList()"
          [(ngModel)]="meetingsCheck"
          style="margin-right: 5px"
          type="checkbox"
          name="meetingFilter"
        /><label for="meetingFilter">w/ meetings</label>
        <input
          [(ngModel)]="failuresCheck"
          (click)="refreshList()"
          style="margin-right: 5px; margin-left: 15px"
          type="checkbox"
          name="failfilter"
        /><label for="failfilter">all failures</label>
      </div>
      <div class="col-sm-5">
        <span>DC Filters:</span>
        <!-- <button (click)="clearDataFilters()" class="btn btn-primary">Include all</button> -->
        <span *ngIf="dataCenterControls == true">
          <span *ngFor="let datacenter of datacenterArr">
            <input
              type="checkbox"
              checked
              (click)="onCheckboxChange($event)"
              class="datacheck"
              name="datacheck"
              value="{{ datacenter }}"
            /><label for="{{ datacenter }}">{{ datacenter }}</label>
          </span>
        </span>
      </div>
      <div class="loading-table" *ngIf="initialLoad == true">
        <div class="progress-bar">
          <h2>Loading...</h2>
          <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="list-account-table col-12">
        <div class="loading-table" *ngIf="loadingTable == true">
          <div class="progress-bar">
            <h2>Loading...</h2>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
        <div
          style="background-color: white"
          class="loading-table"
          *ngIf="loadingAccount == true"
        >
          <div class="progress-bar">
            <h2>Please wait – slow now, much faster soon</h2>
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
        <p-table
          *ngIf="tableDisplay"
          #dt
          id="list-accounts-table"
          [resizableColumns]="true"
          [value]="listResponse"
          [rows]="10"
          [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 25, 50]"
          [paginator]="true"
          [rowHover]="true"
          styleClass="p-datatable-gridlines"
          [globalFilterFields]="[
            'account_name',
            'connect_id',
            'datacenter_key',
            'servers',
            'shared',
            'meetingCount',
            'meeting-concurrency',
            'user-logins',
            'content-sessions',
            'connect-concurrency',
            'succM',
            'failM'
          ]"
        >
          <ng-template pTemplate="caption">
            Account List
            <span class="p-input-icon-left float-right">
              <i
                style="position: relative; left: -15px"
                class="pi pi-search"
              ></i>
              <input
                pInputText
                type="text"
                (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Account Search"
              />
            </span>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th pResizableColumn pSortableColumn="account_name">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Account Name
                  <p-sortIcon field="account_name"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="connect_id">
                <div class="p-d-flex p-jc-between p-ai-center">
                  ID
                  <p-sortIcon field="connect_id"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="datacenter_key">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Data Center
                  <p-sortIcon field="datacenter_key"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="servers">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Servers
                  <p-sortIcon field="servers"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="shared">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Shared
                  <p-sortIcon field="shared"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="meetingCount">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Mtg Count
                  <p-sortIcon field="meetingCount"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="meeting-concurrency">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Mtg Con
                  <p-sortIcon field="meeting-concurrency"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="user-logins">
                <div class="p-d-flex p-jc-between p-ai-center">
                  User Logins
                  <p-sortIcon field="user-logins"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="content-sessions">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Content Sess
                  <p-sortIcon field="content-sessions"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="connect-concurrency">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Connect Con
                  <p-sortIcon field="connect-concurrency"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="succM">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Succ M
                  <p-sortIcon field="succM"></p-sortIcon>
                </div>
              </th>
              <th pResizableColumn pSortableColumn="failM">
                <div class="p-d-flex p-jc-between p-ai-center">
                  Fail M
                  <p-sortIcon field="failM"></p-sortIcon>
                </div>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-account>
            <tr
              (click)="
                getAccountDetails(
                  account.connect_id,
                  account.account_name,
                  account.servers
                )
              "
            >
              <td>{{ account.account_name }}</td>
              <td>{{ account.connect_id }}</td>
              <td>{{ account.datacenter_key }}</td>
              <td>{{ account.servers }}</td>
              <td>{{ account.shared }}</td>
              <td>{{ account.meetingCount }}</td>
              <td>{{ account["meeting-concurrency"] }}</td>
              <td>{{ account["user-logins"] }}</td>
              <td>{{ account["content-sessions"] }}</td>
              <td>{{ account["connect-concurrency"] }}</td>
              <td>
                <span *ngIf="account.succM == '' || account.succM == '-'">{{
                  account.succM
                }}</span
                ><span *ngIf="account.succM !== '' && account.succM !== '-'">{{
                  account.succM | date: "MM/dd/yy H:mm aa"
                }}</span>
              </td>
              <td>
                <span *ngIf="account.failM == '' || account.failM == '-'">{{
                  account.failM
                }}</span
                ><span *ngIf="account.failM !== '' && account.failM !== '-'">{{
                  account.failM | date: "MM/dd/yy H:mm aa"
                }}</span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <div *ngIf="accountTable == true" class="row">
    <div class="col-12">
      <h2 class="float-left">{{ accountName }}</h2>
      <button
        style="margin-top: 0"
        (click)="connectLogin()"
        class="btn btn-primary float-left"
      >
        Login to account
      </button>
    </div>
    <div *ngIf="newServers == false">
      <mat-tab-group
        id="disableTabs"
        [selectedIndex]="indexVal"
        class="col-sm-12"
      >
        <mat-tab [disabled]="disableAll == true">
          <ng-template mat-tab-label>
            <span (click)="tabIndexValue(0)">Live Status</span>
          </ng-template>
          <div class="row meeting-table">
            <div class="col-12">
              <p-table
                styleClass="p-datatable-gridlines"
                #dt
                id="meetingTable"
                [resizableColumns]="true"
                [value]="meetingData"
                [rows]="10"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10, 25, 50]"
                [paginator]="true"
                [rowHover]="true"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th pResizableColumn pSortableColumn="name">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Meeting
                        <p-sortIcon field="name"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="owner">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Meeting Owner
                        <p-sortIcon field="owner"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="part">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Part
                        <p-sortIcon field="part"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="start">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Start Time
                        <p-sortIcon field="start"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="minutes">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Length (min)
                        <p-sortIcon field="minutes"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="url">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Path
                        <p-sortIcon field="url"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="sco">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Sco Id
                        <p-sortIcon field="sco"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="primary">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Primary
                        <p-sortIcon field="primary"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="backup">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Backup
                        <p-sortIcon field="backup"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="reco">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Reco %
                        <p-sortIcon field="reco"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="rate">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Reco rate
                        <p-sortIcon field="rate"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="fail">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Fail %
                        <p-sortIcon field="fail"></p-sortIcon>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-meeting>
                  <tr
                    title="{{ meeting.color }}"
                    [ngStyle]="{ 'background-color': meeting.color }"
                    (click)="showMeeting(meeting.sco, meeting['asset-id'])"
                  >
                    <td title="{{ meeting.name }}">{{ meeting.name }}</td>
                    <td title="{{ meeting.owner }}">{{ meeting.owner }}</td>
                    <td title="{{ meeting.part }}">{{ meeting.part }}</td>
                    <td title="{{ meeting.start }}">
                      {{ meeting.start | date: "MM/dd/yy H:mm aa" }}
                    </td>
                    <td title="{{ meeting.minutes }}">{{ meeting.minutes }}</td>
                    <td title="{{ meeting.url }}">{{ meeting.url }}</td>
                    <td title="{{ meeting.sco }}">{{ meeting.sco }}</td>
                    <td title="{{ meeting.primary }}">{{ meeting.primary }}</td>
                    <td title="{{ meeting.backup }}">{{ meeting.backup }}</td>
                    <td title="{{ meeting.reco }}">
                      {{ meeting.reco.toFixed(2) }}%
                    </td>
                    <td title="{{ meeting.rate }}">
                      {{ meeting.rate.toFixed(2) }}
                    </td>
                    <td title="{{ meeting.fail }}">
                      {{ meeting.fail.toFixed(2) }}%
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="col-12">
              <h4>Current per-server load</h4>
              <p-table
                id="session-table"
                #dt
                [value]="statusData.servers"
                styleClass="p-datatable-gridlines"
                [resizableColumns]="true"
                [rows]="15"
                [showCurrentPageReport]="true"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th pResizableColumn pSortableColumn="machine_name">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Server
                        <p-sortIcon field="machine_name"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="cpuUse">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        CPU %
                        <p-sortIcon field="cpuUse"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="tcpAct">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        tcp Act
                        <p-sortIcon field="tcpAct"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="tcpNew">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        tcp new/m
                        <p-sortIcon field="tcpNew"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="tcpOut">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        tcp out/s
                        <p-sortIcon field="tcpOut"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="lastSucc">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Last success
                        <p-sortIcon field="lastSucc"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="lastFail">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Last failure
                        <p-sortIcon field="lastFail"></p-sortIcon>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-status>
                  <tr>
                    <td>{{ status.machine_name }}</td>
                    <td>
                      <span *ngIf="status.cpuUse !== undefined"
                        >{{ status.cpuUse.toFixed(2) }}%</span
                      >
                    </td>
                    <td>{{ status.tcpAct }}</td>
                    <td>
                      <span *ngIf="status.tcpNew !== undefined">{{
                        status.tcpNew.toFixed(2)
                      }}</span>
                    </td>
                    <td>
                      <span *ngIf="status.tcpOut !== undefined">{{
                        status.tcpOut.toFixed(2)
                      }}</span>
                    </td>
                    <td>{{ status.lastSucc | date: "MM/dd/yy H:mm aa" }}</td>
                    <td>{{ status.lastFail | date: "MM/dd/yy H:mm aa" }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
            <div class="col-12 form-group">
              <div class="row">
                <div class="col-12">
                  <h4>Sessions per hour</h4>
                  <canvas
                    baseChart
                    [datasets]="barChartData"
                    [labels]="barChartLabels"
                    [options]="barChartOptions"
                    [plugins]="barChartPlugins"
                    [legend]="barChartLegend"
                    [chartType]="barChartType"
                  >
                  </canvas>
                </div>
                <div class="col-12 table-min-height">
                  <h2>Logins</h2>
                  <p-table
                    #dt
                    id="loginTable"
                    [resizableColumns]="true"
                    [value]="loginData.rows"
                    styleClass="p-datatable-gridlines"
                    [rows]="10"
                    [showCurrentPageReport]="true"
                    [rowsPerPageOptions]="[10, 25, 50]"
                    [paginator]="true"
                  >
                    <ng-template pTemplate="header">
                      <tr>
                        <th pResizableColumn pSortableColumn="login[0]">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ loginData.headings[0] }}
                            <p-sortIcon field="login[0]"></p-sortIcon>
                          </div>
                        </th>
                        <th pResizableColumn pSortableColumn="login[1]">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ loginData.headings[1] }}
                            <p-sortIcon field="login[2]"></p-sortIcon>
                          </div>
                        </th>
                        <th pResizableColumn pSortableColumn="login[2]">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ loginData.headings[2] }}
                            <p-sortIcon field="login[2]"></p-sortIcon>
                          </div>
                        </th>
                        <th pResizableColumn pSortableColumn="login[3]">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            {{ loginData.headings[3] }}
                            <p-sortIcon field="login[3]"></p-sortIcon>
                          </div>
                        </th>
                      </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-login>
                      <tr>
                        <td>{{ login[0].toLocaleString() }}</td>
                        <td>{{ login[1] }}</td>
                        <td>{{ login[2] }}</td>
                        <td>{{ login[3] }}</td>
                      </tr>
                    </ng-template>
                  </p-table>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab [disabled]="disableAll == true" class="row">
          <ng-template mat-tab-label>
            <span (click)="tabIndexValue(1)">Account Details</span>
          </ng-template>
          <div class="col-12" *ngIf="accountDetails !== undefined">
            <div class="row">
              <h2 class="col-12">{{ accountDetails.account_name }}</h2>
              <div class="col-4">
                <p>
                  Version: <b>{{ accountDetails.version }}</b>
                </p>
                <p>
                  Data Center: <b>{{ accountDetails.datacenter_key }}</b>
                </p>
                <p>
                  Webapp Domain: <b>{{ accountDetails.webapp_domain }}</b>
                </p>
                <p>
                  Expiry Date:
                  <b>{{ accountDetails.date_expired.toLocaleString() }}</b>
                </p>
                <p>
                  Serial Key: <b>{{ accountDetails.serial_key }}</b>
                </p>
                <p>
                  Admin Email: <b>{{ accountDetails.admin_email }}</b>
                </p>
                <p>
                  Support Email: <b>{{ accountDetails.support_email }}</b>
                </p>
                <p>
                  Servers: <b>{{ accountDetails.numServers }}</b>
                </p>
              </div>
              <div class="col-4">
                <p>
                  DB Name: <b>{{ accountDetails.connect_db_name }}</b>
                </p>
                <p>
                  Primary DB server: <b>{{ accountDetails.connect_db_host }}</b>
                </p>
                <p>
                  SMTP Host: <b>{{ accountDetails.smtp_host }}</b>
                </p>
                <p>
                  SMTP User: <b>{{ accountDetails.smtp_user }}</b>
                </p>
                <p>
                  Alerting: <b>{{ accountDetails.alerting }}</b>
                </p>
                <p>
                  Master: <b>{{ accountDetails.smtp_host }}</b>
                </p>
                <p>
                  Serial Key: <b>{{ accountDetails.serial_key }}</b>
                </p>
                <p>
                  Disabled: <b>{{ accountDetails.disabled }}</b>
                </p>
              </div>
              <div class="col-4">
                <p>
                  DC Ops Code: <b>{{ accountDetails.dcops_code }}</b>
                </p>
                <p>
                  Backup Server ID:
                  <b>{{ accountDetails.backup_server_instance_id }}</b>
                </p>
                <p>
                  Connect ID: <b>{{ accountDetails.connect_id }}</b>
                </p>
                <p>
                  Scheduler: <b>{{ accountDetails.scheduler }}</b>
                </p>
                <p>
                  Is Secure: <b>{{ accountDetails.is_secure }}</b>
                </p>
                <p>
                  Is External: <b>{{ accountDetails.is_external }}</b>
                </p>
                <p>
                  Backups Enabled: <b>{{ accountDetails.enabled_backups }}</b>
                </p>
                <p>
                  Installation Key: <b>{{ accountDetails.installation_key }}</b>
                </p>
                <p>
                  Legacy Backup IDs:
                  <b>{{ accountDetails.legacy_backup_ids }}</b>
                </p>
              </div>
            </div>
          </div>
          <div class="row">
            <h3 class="col-12">Server List</h3>
            <div class="col-12">
              <p-table
                #dt
                id="account-servers"
                [resizableColumns]="true"
                styleClass="p-datatable-gridlines"
                [value]="accountServers"
                [rows]="15"
                [showCurrentPageReport]="true"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th pResizableColumn pSortableColumn="machine_name">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Machine Name
                        <p-sortIcon field="machine_name"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="ip">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        IP
                        <p-sortIcon field="ip"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="shared">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Shared
                        <p-sortIcon field="shared"></p-sortIcon>
                      </div>
                    </th>
                    <th pResizableColumn pSortableColumn="time">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Last local time
                        <p-sortIcon field="time"></p-sortIcon>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-servers>
                  <tr>
                    <td>{{ servers.machine_name }}</td>
                    <td>{{ servers.ip }}</td>
                    <td>{{ servers.shared }}</td>
                    <td>{{ servers.time | date: "MM/dd/yy H:mm aa" }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </mat-tab>
        <mat-tab [disabled]="disableAll == true">
          <ng-template mat-tab-label>
            <span (click)="tabIndexValue(2)">Logs / Manage</span>
          </ng-template>
          <p *ngIf="showLogControls == false">
            Click a server to view controls:
          </p>
          <p-table
            [rowHover]="true"
            [resizableColumns]="true"
            *ngIf="logsServerTable == true"
            #dt
            styleClass="p-datatable-gridlines"
            id="details-table"
            [value]="logsServerData"
            [rows]="15"
            [showCurrentPageReport]="true"
          >
            <ng-template pTemplate="header">
              <tr>
                <th pResizableColumn pSortableColumn="machine-name">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Machine Name
                    <p-sortIcon field="machine-name"></p-sortIcon>
                  </div>
                </th>
                <th pResizableColumn pSortableColumn="status">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Status
                    <p-sortIcon field="status"></p-sortIcon>
                  </div>
                </th>
                <th pResizableColumn pSortableColumn="is-shared-storage">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Shared?
                    <p-sortIcon field="is-shared-storage"></p-sortIcon>
                  </div>
                </th>
                <th pResizableColumn pSortableColumn="active-meetings">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Active Meetings
                    <p-sortIcon field="active-meetings"></p-sortIcon>
                  </div>
                </th>
                <th pResizableColumn pSortableColumn="flags">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    Flags
                    <p-sortIcon field="flags"></p-sortIcon>
                  </div>
                </th>
                <th pResizableColumn pSortableColumn="external-name">
                  <div class="p-d-flex p-jc-between p-ai-center">
                    External Name
                    <p-sortIcon field="external-name"></p-sortIcon>
                  </div>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-servers>
              <tr
                (click)="
                  getLogsForServer(
                    servers['machine-name'],
                    servers['server-key'],
                    servers.status
                  )
                "
                [ngClass]="{ activeRow: serverName == servers['machine-name'] }"
              >
                <td title="{{ servers['machine-name'] }}">
                  {{ servers["machine-name"] }}
                </td>
                <td title="{{ servers.status }}">{{ servers.status }}</td>
                <td title="{{ servers['is-shared-storage'] }}">
                  {{ servers["is-shared-storage"] }}
                </td>
                <td title="{{ servers['active-meetings'] }}">
                  {{ servers["active-meetings"] }}
                </td>
                <td title="{{ servers.flags }}">{{ servers.flags }}</td>
                <td title="{{ servers['external-name'] }}">
                  {{ servers["external-name"] }}
                </td>
              </tr>
            </ng-template>
          </p-table>
          <div id="logControls" *ngIf="showLogControls == true" class="row">
            <h1 class="col-12">{{ serverName }} Log Options</h1>
            <div class="col-6">
              <h2>Logs</h2>
              <h3>CS Agent:</h3>
              <input
                type="radio"
                name="logRadio"
                [(ngModel)]="logModel"
                id="csAgent"
                [value]="['agent', 'daysBack']"
                checked
              />
              <label class="radioBtn" for="csAgent">Log</label>
              <label class="selectBtn" for="daySelect">Days Ago</label>
              <select [(ngModel)]="agentDaysBack" name="daySelect">
                <option>0</option>
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
                <option>6</option>
                <option>7</option>
                <option>8</option>
                <option>9</option>
                <option>10</option>
                <option>11</option>
                <option>12</option>
                <option>13</option>
                <option>14</option>
                <option>15</option>
              </select>
              <hr />
              <h3>AC Debug:</h3>
              <input
                type="radio"
                name="logRadio"
                [(ngModel)]="logModel"
                id="acLog"
                [value]="['debug', 'daysBack']"
              />
              <label class="radioBtn" for="acLog">Log</label>
              <br />
              <div class="row">
                <div class="col-4">
                  <label class="selectBtn" for="teleDay">Start Date</label>
                  <form class="form-group">
                    <div class="input-group">
                      <input
                        class="form-control date-input"
                        placeholder="yyyy-mm-dd"
                        name="startDate"
                        [maxDate]="maxDate"
                        [(ngModel)]="selectedStartDate"
                        ngbDatepicker
                        #d="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d.toggle()"
                          type="button"
                        ></button>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-4">
                  <label for="timeSelect">Start Time</label>
                  <div>
                    <div class="float-left time-input">
                      <ngb-timepicker
                        name="timeSelect"
                        [(ngModel)]="startTime"
                      ></ngb-timepicker>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label for="timeback">Duration</label>
                  <ngb-timepicker
                    name="timeSelect"
                    name="timeback"
                    [(ngModel)]="selectedTimeRange"
                  ></ngb-timepicker>
                </div>
              </div>
              <br />
              <input
                type="radio"
                name="logRadio"
                [(ngModel)]="logModel"
                id="acTail"
                [value]="['debug', 'minutes']"
              />
              <label class="radioBtn" for="acTail">Tail</label>
              <label class="selectBtn" for="minutesAgo">Minutes Ago</label>
              <input
                value="10"
                type="text"
                for="minutesAgo"
                [(ngModel)]="acMinutes"
              />
              <br />
              <input
                type="radio"
                name="logRadio"
                [(ngModel)]="logModel"
                id="acError"
                [value]="['error']"
              />
              <label class="radioBtn" for="acError">Error</label>
              <hr />
              <h3>AMS:</h3>
              <input
                type="radio"
                name="logRadio"
                [(ngModel)]="logModel"
                id="amsDiag"
                [value]="['diagnostic']"
              />
              <label class="radioBtn" for="amsDiag">Diagnostic</label>
              <input
                type="radio"
                name="logRadio"
                [(ngModel)]="logModel"
                id="amsAccess"
                [value]="['access']"
              />
              <label class="radioBtn" for="amsAccess">Access</label>
              <hr />
              <h3>Java:</h3>
              <input
                type="radio"
                name="logRadio"
                [(ngModel)]="logModel"
                id="javaLast"
                [value]="['gc', false]"
              />
              <label class="radioBtn" for="javaLast">GC-Last</label>
              <input
                type="radio"
                name="logRadio"
                [(ngModel)]="logModel"
                id="javaArchive"
                [value]="['gc', true]"
              />
              <label class="radioBtn" for="javaArchive">GC-Archive</label>
              <hr />
              <h3>Telephony:</h3>
              <input
                type="radio"
                name="logRadio"
                [(ngModel)]="logModel"
                id="telephonyLog"
                [value]="['telephony', 'daysBack']"
              />
              <label class="radioBtn" for="telephonyLog">Log</label>
              <br />
              <div class="row">
                <div class="col-4">
                  <label class="selectBtn" for="teleDay">Start Date</label>
                  <form class="form-group">
                    <div class="input-group">
                      <input
                        class="form-control date-input"
                        placeholder="yyyy-mm-dd"
                        name="startDate"
                        [maxDate]="maxDate"
                        [(ngModel)]="selectedStartDate"
                        ngbDatepicker
                        #d="ngbDatepicker"
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-outline-secondary calendar"
                          (click)="d.toggle()"
                          type="button"
                        ></button>
                      </div>
                    </div>
                  </form>
                </div>
                <div class="col-4">
                  <label for="timeSelect">Start Time</label>
                  <div>
                    <div class="float-left time-input">
                      <ngb-timepicker
                        name="timeSelect"
                        [(ngModel)]="startTime"
                      ></ngb-timepicker>
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <label for="timeback">Duration</label>
                  <ngb-timepicker
                    name="timeSelect"
                    name="timeback"
                    [(ngModel)]="selectedTimeRange"
                  ></ngb-timepicker>
                </div>
              </div>
              <br />
              <input
                type="radio"
                name="logRadio"
                [(ngModel)]="logModel"
                id="telephonyTail"
                [value]="['telephony', 'minutes']"
              />
              <label class="radioBtn" for="telephonyTail">Tail</label>
              <label class="selectBtn" for="teleMinutesAgo">Minutes Ago</label>
              <input
                type="text"
                value="10"
                [(ngModel)]="teleMinutes"
                name="teleMinutesAgo"
              />
              <br />
              <button class="btn btn-primary" (click)="downloadLog('logs')">
                Download Logs
              </button>
              <br />
              <h2>Config</h2>
              <input
                type="radio"
                name="configRadio"
                [(ngModel)]="configModel"
                id="configAgent"
                [value]="'agent-config'"
                checked
              />
              <label class="radioBtn" for="configAgent">CS Agent</label>
              <input
                type="radio"
                name="configRadio"
                [(ngModel)]="configModel"
                id="configDB"
                [value]="'configDB'"
              />
              <label class="radioBtn" for="configDB">Database</label>
              <input
                type="radio"
                name="configRadio"
                [(ngModel)]="configModel"
                id="configArch"
                [value]="'configArch'"
              />
              <label class="radioBtn" for="configArch">Archive</label>
              <br />
              <button class="btn btn-primary" (click)="downloadLog('config')">
                Download Config Logs
              </button>
            </div>
            <div class="col-3">
              <h2>Services</h2>
              <input
                type="radio"
                name="servRadio"
                [(ngModel)]="serviceModel"
                id="serviceAll"
                [value]="['all', 'restart']"
                checked
              />
              <label class="radioBtn" for="serviceAll">Restart All</label>
              <h3>Connect:</h3>
              <input
                type="radio"
                name="servRadio"
                [(ngModel)]="serviceModel"
                id="connectRestart"
                [value]="['connect', 'restart']"
              />
              <label class="radioBtn" for="connectRestart">Restart</label>
              <input
                type="radio"
                name="servRadio"
                [(ngModel)]="serviceModel"
                id="connectStart"
                [value]="['connect', 'start']"
              />
              <label class="radioBtn" for="connectStart">Start</label>
              <input
                type="radio"
                name="servRadio"
                [(ngModel)]="serviceModel"
                id="connectStop"
                [value]="['connect', 'stop']"
              />
              <label class="radioBtn" for="connectStop">Stop</label>
              <h3>AMS:</h3>
              <input
                type="radio"
                name="servRadio"
                [(ngModel)]="serviceModel"
                id="amsRestart"
                [value]="['ams', 'restart']"
              />
              <label class="radioBtn" for="amsRestart">Restart</label>
              <input
                type="radio"
                name="servRadio"
                [(ngModel)]="serviceModel"
                id="amsStart"
                [value]="['ams', 'start']"
              />
              <label class="radioBtn" for="amsStart">Start</label>
              <input
                type="radio"
                name="servRadio"
                [(ngModel)]="serviceModel"
                id="amsStop"
                [value]="['ams', 'stop']"
              />
              <label class="radioBtn" for="amsStop">Stop</label>
              <h3>Telephony:</h3>
              <input
                type="radio"
                name="servRadio"
                [(ngModel)]="serviceModel"
                id="teleRestart"
                [value]="['telephony', 'restart']"
              />
              <label class="radioBtn" for="teleRestart">Restart</label>
              <input
                type="radio"
                name="servRadio"
                [(ngModel)]="serviceModel"
                id="teleStart"
                [value]="['telephony', 'start']"
              />
              <label class="radioBtn" for="teleStart">Start</label>
              <input
                type="radio"
                name="servRadio"
                [(ngModel)]="serviceModel"
                id="teleStop"
                [value]="['telephony', 'stop']"
              />
              <label class="radioBtn" for="teleStop">Stop</label>
              <br />
              <button class="btn btn-primary" (click)="downloadLog('services')">
                Execute
              </button>
              <br />
              <h2>State</h2>
              <select [(ngModel)]="stateModel">
                <option value="into-rotation">Active - In Rotation</option>
                <option value="out-of-rotation">
                  Inactive - Out of Rotation
                </option>
              </select>
              <button class="btn btn-primary" (click)="changeServerState()">
                Update State
              </button>
            </div>
            <div class="col-3">
              <h2>Report</h2>
              <input
                type="radio"
                name="reportRadio"
                [(ngModel)]="reportModel"
                id="reportScoVer"
                [value]="'scoVer'"
                checked
              />
              <label class="radioBtn" for="reportScoVer">Sco Ver</label>
              <button class="btn btn-primary" (click)="downloadLog('reports')">
                Download File
              </button>
              <hr />
              <label class="radioBtn" for="reportSco">Get Sco</label>
              <input name="reportSco" [(ngModel)]="reportSco" type="text" />
              <button
                class="btn btn-primary"
                (click)="downloadLog('scoFolder')"
              >
                Get File
              </button>
              <br />
              <button (click)="getServerProcess()" class="btn btn-primary">
                Get Server Process List
              </button>
            </div>
          </div>
        </mat-tab>
        <mat-tab [disabled]="meetingTab !== true">
          <ng-template mat-tab-label>
            <span *ngIf="meetingTab == true" (click)="tabIndexValue(3)"
              >Meeting Details</span
            >
            <span *ngIf="meetingTab !== true">Meeting Details</span>
          </ng-template>
          <div *ngIf="meetingLoaded !== true">
            <div class="progress-bar">
              <h3>Loading...</h3>
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
          </div>
          <div *ngIf="meetingLoaded == true">
            <div class="row">
              <div class="col-4">
                <p>
                  Meeting Name:
                  <span class="bold">{{ meetingDetailResp.data.name }}</span>
                </p>
                <p>
                  Start Time:
                  <span class="bold">{{
                    meetingDetailResp.data.start | date: "MM/dd/yy H:mm aa"
                  }}</span>
                </p>
                <p>
                  End Time:
                  <span class="bold">{{
                    meetingDetailResp.data.end | date: "MM/dd/yy H:mm aa"
                  }}</span>
                </p>
                <p>
                  Reconn/User:
                  <span class="bold">{{ meetingDetailResp.data.recon }}</span>
                </p>
                <p>
                  Owner:
                  <span class="bold">{{ meetingDetailResp.data.own }}</span>
                </p>
                <p>
                  URL Path:
                  <span class="bold">{{ meetingDetailResp.data.url }}</span>
                </p>
              </div>
              <div class="col-4">
                <p>
                  Failures:
                  <span class="bold">{{ meetingDetailResp.data.fail }}</span>
                </p>
                <p>
                  SCO ID:
                  <span class="bold">{{ meetingDetailResp.data.sco }}</span>
                </p>
                <p>
                  Asset ID:
                  <span class="bold">{{ meetingDetailResp.data.asset }}</span>
                </p>
                <p>
                  Primary Host:
                  <span
                    ><input type="text" [(ngModel)]="primaryServerMeeting" />
                    <button (click)="resetFMSCore()" class="btn btn-primary">
                      Find</button
                    ><span
                      style="height: 25px; width: 25px; display: inline-block"
                      ><mat-progress-spinner
                        diameter="25"
                        *ngIf="coreWait == true"
                        mode="indeterminate"
                      ></mat-progress-spinner></span
                  ></span>
                </p>
                <p>
                  Backup Host:
                  <span class="bold">{{ backupServerMeeting }}</span>
                </p>
              </div>
              <div class="col-4">
                <button
                  (click)="getLogs('application')"
                  class="btn btn-primary"
                >
                  Get all application Logs
                </button>
                <br />
                <button (click)="getLogs('analysis')" class="btn btn-primary">
                  Request Log Analysis
                </button>
                <br />
                <button (click)="getLogs('meeting')" class="btn btn-primary">
                  Request Meeting Logs
                </button>
              </div>
              <div *ngIf="showAttendeeTable == true" class="col-12">
                <p-table
                  #dt
                  [resizableColumns]="true"
                  id="meeting-details-table"
                  [value]="meetingDetailTable"
                  styleClass="p-datatable-gridlines"
                  [rows]="10"
                  [showCurrentPageReport]="true"
                  [rowsPerPageOptions]="[10, 25, 50]"
                  [paginator]="true"
                >
                  <ng-template pTemplate="header">
                    <tr>
                      <th pResizableColumn pSortableColumn="name">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Session Start
                          <p-sortIcon field="name"></p-sortIcon>
                        </div>
                      </th>
                      <th pResizableColumn pSortableColumn="owner">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Principle Name
                          <p-sortIcon field="owner"></p-sortIcon>
                        </div>
                      </th>
                      <th pResizableColumn pSortableColumn="part">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Principle Login
                          <p-sortIcon field="part"></p-sortIcon>
                        </div>
                      </th>
                      <th pResizableColumn pSortableColumn="start">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Guest Name
                          <p-sortIcon field="start"></p-sortIcon>
                        </div>
                      </th>
                      <th pResizableColumn pSortableColumn="part">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Session End
                          <p-sortIcon field="part"></p-sortIcon>
                        </div>
                      </th>

                      <th pResizableColumn pSortableColumn="minutes">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          User Session ID
                          <p-sortIcon field="minutes"></p-sortIcon>
                        </div>
                      </th>
                      <th pResizableColumn pSortableColumn="url">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Bytes Up
                          <p-sortIcon field="url"></p-sortIcon>
                        </div>
                      </th>
                      <th pResizableColumn pSortableColumn="sco">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Bytes Down
                          <p-sortIcon field="sco"></p-sortIcon>
                        </div>
                      </th>
                      <th pResizableColumn pSortableColumn="primary">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Ticket
                          <p-sortIcon field="primary"></p-sortIcon>
                        </div>
                      </th>
                      <th pResizableColumn pSortableColumn="backup">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Status
                          <p-sortIcon field="backup"></p-sortIcon>
                        </div>
                      </th>
                      <th pResizableColumn pSortableColumn="reco">
                        <div class="p-d-flex p-jc-between p-ai-center">
                          Perm
                          <p-sortIcon field="reco"></p-sortIcon>
                        </div>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-attendee>
                    <tr>
                      <td>{{ attendee[0] | date: "MM/dd H:mm" }}</td>
                      <td>{{ attendee[1] }}</td>
                      <td title="{{ attendee[2] }}">{{ attendee[2] }}</td>
                      <td>{{ attendee[3] }}</td>
                      <td>{{ attendee[4] | date: "MM/dd H:mm" }}</td>
                      <td>{{ attendee[5] }}</td>
                      <td>{{ attendee[6] }}</td>
                      <td>{{ attendee[7] }}</td>
                      <td title="{{ attendee[8] }}">{{ attendee[8] }}</td>
                      <td>{{ attendee[9] }}</td>
                      <td>{{ attendee[10] }}</td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
              <div *ngIf="showAttendeeTable == false" class="col-12">
                <canvas
                  baseChart
                  id="meetingChart"
                  [datasets]="meetingChartData"
                  [labels]="meetingChartLabels"
                  [options]="meetingChartOptions"
                  [legend]="meetingChartLegend"
                  [chartType]="meetingChartType"
                  [plugins]="meetingChartPlugins"
                  [colors]="meetingChartColors"
                >
                </canvas>
              </div>
              <div class="col-12">
                <button (click)="changeMeetingView()" class="btn btn-primary">
                  Show Attendee {{ meetBtnVar }}
                </button>
                <button (click)="killMeeting()" class="btn btn-danger">
                  Kill Meeting
                </button>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab>
          <ng-template mat-tab-label>
            <span (click)="tabIndexValue(4)">Settings</span>
          </ng-template>
          <div *ngIf="indexVal == 4" class="row">
            <div [hidden]="settingsEditing == true" class="col-4">
              <div *ngFor="let fields of settingsArr; let i = index">
                <div *ngIf="i < 8">
                  <p
                    *ngIf="fields.name !== 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span class="bold">{{ fields.value }}</span>
                  </p>
                  <p
                    *ngIf="fields.name == 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span
                      *ngIf="fields.value == null || fields.value == true"
                      class="bold"
                      >{{ fields.value }}</span
                    ><span
                      *ngIf="fields.value !== null && fields.value !== true"
                      class="bold"
                      >False</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div [hidden]="settingsEditing == true" class="col-4">
              <div *ngFor="let fields of settingsArr; let i = index">
                <div *ngIf="i > 7 && i < 16">
                  <p
                    *ngIf="fields.name !== 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span class="bold">{{ fields.value }}</span>
                  </p>
                  <p
                    *ngIf="fields.name == 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span
                      *ngIf="fields.value == null || fields.value == true"
                      class="bold"
                      >{{ fields.value }}</span
                    ><span
                      *ngIf="fields.value !== null && fields.value !== true"
                      class="bold"
                      >False</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div [hidden]="settingsEditing == true" class="col-4">
              <div *ngFor="let fields of settingsArr; let i = index">
                <div *ngIf="i > 15">
                  <p
                    *ngIf="fields.name !== 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span class="bold">{{ fields.value }}</span>
                  </p>
                  <p
                    *ngIf="fields.name == 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span
                      *ngIf="fields.value == null || fields.value == true"
                      class="bold"
                      >{{ fields.value }}</span
                    ><span
                      *ngIf="fields.value !== null && fields.value !== true"
                      class="bold"
                      >False</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div [hidden]="settingsEditing == false" class="col-12">
              <table id="settingsEditTable">
                <tr *ngFor="let fields of settingsArr; let i = index">
                  <td *ngIf="fields.ro == false">{{ fields.display }}</td>
                  <td>
                    <select
                      [hidden]="settingsEditing == false"
                      *ngIf="
                        fields.ro == false && fields.name == 'datacenter_key'
                      "
                      [(ngModel)]="dataCenterEditSelect"
                    >
                      <option
                        *ngFor="let datacenters of settingsData.datacenters"
                      >
                        {{ datacenters }}
                      </option>
                    </select>
                    <input
                      *ngIf="
                        fields.ro == false &&
                        fields.name !== 'datacenter_key' &&
                        fields.name !== 'backup_server_instance_id'
                      "
                      class="editingInputs"
                      name="{{ fields.display }}"
                      value="{{ fields.value }}"
                      placeholder="{{ fields.value }}"
                      type="text"
                    />
                    <select
                      [hidden]="settingsEditing == false"
                      *ngIf="
                        fields.ro == false &&
                        fields.name == 'backup_server_instance_id'
                      "
                      [(ngModel)]="backupEditSelect"
                    >
                      <option
                        *ngFor="let backups of settingsData.backup_servers"
                      >
                        {{ backups }}
                      </option>
                    </select>
                  </td>
                </tr>
              </table>
              <!-- <div *ngFor="let fields of settingsData.fields | keyvalue; let i = index">
          <p [hidden]="settingsEditing == false" *ngIf="fields.ro == false && fields.name !=='datacenter_key' && fields.name !=='backup_server_instance_id'">{{fields.value.display}}:</p>
          <p [hidden]="settingsEditing == false" *ngIf="fields.ro == false && fields.name =='datacenter_key'">{{fields.value.display}}:</p>
          <p [hidden]="settingsEditing == false" *ngIf="fields.ro == false && fields.name =='backup_server_instance_id'">{{fields.value.display}}: <select [(ngModel)]="backupEditSelect"><option *ngFor="let backups of settingsData.backup_servers">{{backups}}</option></select></p>
        </div> -->
            </div>
            <div class="col-12">
              <button
                *ngIf="settingsEditing == false"
                (click)="settingsEditing = !settingsEditing"
                class="btn btn-primary"
              >
                Edit Account
              </button>
              <button
                *ngIf="settingsEditing == true"
                (click)="settingsEditing = !settingsEditing"
                class="btn btn-primary"
              >
                Stop Editing
              </button>
              <button
                *ngIf="settingsEditing == false"
                class="btn btn-danger"
                (click)="disableAccount()"
              >
                Disable Account
              </button>
              <button
                *ngIf="settingsEditing == true"
                (click)="editAccount()"
                class="btn btn-success"
              >
                Save
              </button>
            </div>
          </div>
        </mat-tab>
        <mat-tab [disabled]="disableAll == true" class="row">
          <ng-template mat-tab-label>
            <span (click)="tabIndexValue(5)">Execute SQL</span>
          </ng-template>
          <textarea
            style="margin-top: 20px; height: 250px"
            class="col-12"
            [(ngModel)]="sqlExecute"
          ></textarea>
          <button (click)="submitSQL()" class="btn btn-primary">Submit</button>
        </mat-tab>
        <mat-tab [disabled]="disableAll == true" class="row">
          <ng-template mat-tab-label>
            <span (click)="tabIndexValue(6)">Search</span>
          </ng-template>
          <app-cc-search
            (buttonClicked)="searchEventHandlerFunction($event)"
          ></app-cc-search>
        </mat-tab>
        <!-- <mat-tab   class="row">
      <ng-template mat-tab-label>
        <span (click)="tabIndexValue(7)">Reports</span>
    </ng-template>
    <app-cc-recent-meetings (recentClicked)="recentEventHandlerFunction($event)"></app-cc-recent-meetings>
    </mat-tab> -->
      </mat-tab-group>
    </div>
    <div *ngIf="newServers == true">
      <mat-tab-group class="col-sm-12">
        <mat-tab>
          <ng-template mat-tab-label>
            <span>Settings</span>
          </ng-template>
          <div class="row">
            <div [hidden]="settingsEditing == true" class="col-4">
              <div *ngFor="let fields of settingsArr; let i = index">
                <div *ngIf="i < 8">
                  <p
                    *ngIf="fields.name !== 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span class="bold">{{ fields.value }}</span>
                  </p>
                  <p
                    *ngIf="fields.name == 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span
                      *ngIf="fields.value == null || fields.value == true"
                      class="bold"
                      >{{ fields.value }}</span
                    ><span
                      *ngIf="fields.value !== null && fields.value !== true"
                      class="bold"
                      >False</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div [hidden]="settingsEditing == true" class="col-4">
              <div *ngFor="let fields of settingsArr; let i = index">
                <div *ngIf="i > 7 && i < 16">
                  <p
                    *ngIf="fields.name !== 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span class="bold">{{ fields.value }}</span>
                  </p>
                  <p
                    *ngIf="fields.name == 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span
                      *ngIf="fields.value == null || fields.value == true"
                      class="bold"
                      >{{ fields.value }}</span
                    ><span
                      *ngIf="fields.value !== null && fields.value !== true"
                      class="bold"
                      >False</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div [hidden]="settingsEditing == true" class="col-4">
              <div *ngFor="let fields of settingsArr; let i = index">
                <div *ngIf="i > 15">
                  <p
                    *ngIf="fields.name !== 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span class="bold">{{ fields.value }}</span>
                  </p>
                  <p
                    *ngIf="fields.name == 'db_read_only_user_is_valid'"
                    [hidden]="settingsEditing == true"
                  >
                    {{ fields.display }}:
                    <span
                      *ngIf="fields.value == null || fields.value == true"
                      class="bold"
                      >{{ fields.value }}</span
                    ><span
                      *ngIf="fields.value !== null && fields.value !== true"
                      class="bold"
                      >False</span
                    >
                  </p>
                </div>
              </div>
            </div>
            <div [hidden]="settingsEditing == false" class="col-12">
              <table id="settingsEditTable">
                <tr *ngFor="let fields of settingsArr; let i = index">
                  <td *ngIf="fields.ro == false">{{ fields.display }}</td>
                  <td>
                    <select
                      [hidden]="settingsEditing == false"
                      *ngIf="
                        fields.ro == false && fields.name == 'datacenter_key'
                      "
                      [(ngModel)]="dataCenterEditSelect"
                    >
                      <option
                        *ngFor="let datacenters of settingsData.datacenters"
                      >
                        {{ datacenters }}
                      </option>
                    </select>
                    <input
                      *ngIf="
                        fields.ro == false &&
                        fields.name !== 'datacenter_key' &&
                        fields.name !== 'backup_server_instance_id'
                      "
                      class="editingInputs"
                      name="{{ fields.display }}"
                      value="{{ fields.value }}"
                      placeholder="{{ fields.value }}"
                      type="text"
                    />
                    <select
                      [hidden]="settingsEditing == false"
                      *ngIf="
                        fields.ro == false &&
                        fields.name == 'backup_server_instance_id'
                      "
                      [(ngModel)]="backupEditSelect"
                    >
                      <option
                        *ngFor="let backups of settingsData.backup_servers"
                      >
                        {{ backups }}
                      </option>
                    </select>
                  </td>
                </tr>
              </table>
              <!-- <div *ngFor="let fields of settingsData.fields | keyvalue; let i = index">
          <p [hidden]="settingsEditing == false" *ngIf="fields.ro == false && fields.name !=='datacenter_key' && fields.name !=='backup_server_instance_id'">{{fields.value.display}}:</p>
          <p [hidden]="settingsEditing == false" *ngIf="fields.ro == false && fields.name =='datacenter_key'">{{fields.value.display}}:</p>
          <p [hidden]="settingsEditing == false" *ngIf="fields.ro == false && fields.name =='backup_server_instance_id'">{{fields.value.display}}: <select [(ngModel)]="backupEditSelect"><option *ngFor="let backups of settingsData.backup_servers">{{backups}}</option></select></p>
        </div> -->
            </div>
            <div class="col-12">
              <button
                *ngIf="settingsEditing == false"
                (click)="settingsEditing = !settingsEditing"
                class="btn btn-primary"
              >
                Edit Account
              </button>
              <button
                *ngIf="settingsEditing == true"
                (click)="settingsEditing = !settingsEditing"
                class="btn btn-primary"
              >
                Stop Editing
              </button>
              <button
                *ngIf="settingsEditing == false"
                class="btn btn-danger"
                (click)="disableAccount()"
              >
                Disable Account
              </button>
              <button
                *ngIf="settingsEditing == true"
                (click)="editAccount()"
                class="btn btn-success"
              >
                Save
              </button>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  <ngb-toast class="alert alert-primary" id="serviceWait" *ngIf="serviceWait">
    <p>Service Operation In Progress</p>
  </ngb-toast>
  <ngb-toast
    class="alert alert-success"
    id="serviceSuccess"
    *ngIf="serviceSuccess"
  >
    <p>Service Operation Successful</p>
  </ngb-toast>
  <ngb-toast class="alert alert-danger" id="serviceError" *ngIf="serviceError">
    <p>
      Something went wrong with the service process. The server may not be
      configured for Service Ops or there was an issue.
    </p>
  </ngb-toast>

  <ngb-toast class="alert alert-primary" id="logsWait" *ngIf="logsWait">
    <p>Log Download In Progress</p>
  </ngb-toast>
  <ngb-toast class="alert alert-success" id="logsSuccess" *ngIf="logsSuccess">
    <p>Log Download Successful</p>
  </ngb-toast>
  <ngb-toast class="alert alert-primary" id="sqlWait" *ngIf="sqlWait">
    <p>Executing SQL</p>
  </ngb-toast>
  <ngb-toast class="alert alert-success" id="sqlSuccess" *ngIf="sqlSuccess">
    <p>SQL Execution Successful</p>
  </ngb-toast>
  <ngb-toast class="alert alert-danger" id="sqlError" *ngIf="sqlError">
    <p>SQL Execution Failed: {{ sqlErrorCode }}</p>
  </ngb-toast>
  <ngb-toast
    class="alert alert-success"
    id="accountDisabledSuccess"
    *ngIf="accountDisabledSuccess"
  >
    <p>Account Disabled</p>
  </ngb-toast>
  <ngb-toast class="alert alert-danger" id="logsDanger" *ngIf="logsDanger">
    <p>Log Download Failed</p>
  </ngb-toast>
  <ngb-toast class="alert alert-primary" id="stateWait" *ngIf="stateWait">
    <p>Changing Server State</p>
  </ngb-toast>
  <ngb-toast class="alert alert-success" id="stateSuccess" *ngIf="stateSuccess">
    <p>State Change Successful</p>
  </ngb-toast>
  <ngb-toast class="alert alert-danger" id="stateError" *ngIf="stateError">
    <p>State Change Failed</p>
  </ngb-toast>
</div>
