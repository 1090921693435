<div class="content-card">
  <div class="recentLoginContainer">
    <div *ngIf="controls.length === 0">
      <div [hidden]="controlsSpinner" id="controls-spinner">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading Controls...</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="controls.length > 0" class="control-container">
      <div
        [ngClass]="{ active: activeNugget === nugget.name }"
        class="controlNugget"
        *ngFor="let nugget of controls; let i = index"
      >
        <a
          (click)="selectInstallControl(nugget['connect-id'], nugget.name)"
          attr.data-value="{{ nugget['connect-id'] }}"
        >
          <h2 title="{{ nugget.name }}">{{ nugget.name }}</h2>
          <p>{{ nugget.response.meetingCount }} Meetings</p>
          <p>{{ nugget.response.meetingUsersCount }} Users</p>
        </a>
      </div>
    </div>
    <div *ngIf="recentLoginTable">
      <h2>Recent Logins - {{ recentLoginTableName }}</h2>
      <table datatable class="table table-striped row-border">
        <thead>
          <tr>
            <th scope="col">{{ recentLoginTableHeads[0] }}</th>
            <th scope="col">{{ recentLoginTableHeads[1] }}</th>
            <th scope="col">{{ recentLoginTableHeads[2] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let recentLogin of recentLoginTableRows">
            <td>{{ recentLogin[0].toLocaleString() }}</td>
            <td>{{ recentLogin[1] }}</td>
            <td>{{ recentLogin[2] }}</td>
          </tr>
        </tbody>
      </table>
      <div></div>
      <button
        style="width: 250px; margin: auto; display: block"
        class="btn btn-outline-secondary"
        (click)="downloadrptcsv()"
      >
        <span style="position: relative; top: 5px"
          ><mat-icon>arrow_downward</mat-icon></span
        >
        Download CSV
      </button>
    </div>
  </div>
</div>
