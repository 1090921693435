<div class="content-card">
  <!-- <div class="row backup-buttons">
        <button (click)="tableChange('smq')"  [ngClass]="{'active': tableDisplay == 'smq'}" class="btn btn-primary col-1">SMQ</button>
        <button (click)="tableChange('sjc')" [ngClass]="{'active': tableDisplay == 'sjc'}" class="btn btn-primary col-1">SJC</button>
        <button (click)="tableChange('lcy')" [ngClass]="{'active': tableDisplay == 'lcy'}" class="btn btn-primary col-1">LCY</button>
        <button (click)="tableChange('hkg')" [ngClass]="{'active': tableDisplay == 'hkg'}" class="btn btn-primary col-1">HKG</button>
    </div>
    <div style="margin-bottom:40px;" class="row">
        <button (click)="tableToggle = 'enabled'"  [ngClass]="{'active': tableToggle == 'enabled'}" class="btn btn-primary col-1">Enabled</button>
        <button (click)="tableToggle = 'disabled'" [ngClass]="{'active': tableToggle == 'disabled'}" class="btn btn-primary col-1">Disabled</button>
    </div> -->

  <div *ngIf="tablesLoaded == true" class="row">
    <mat-tab-group [selectedIndex]="indexVal" class="col-sm-12">
      <mat-tab *ngFor="let button of listResponse.data | keyvalue">
        <ng-template mat-tab-label>
          <span (click)="tableChange(button.key, button.value)">{{
            button.key.toUpperCase()
          }}</span>
        </ng-template>
        <mat-tab-group
          style="margin-top: 20px"
          [selectedIndex]="indexVal"
          class="col-sm-12"
        >
          <mat-tab>
            <ng-template mat-tab-label>
              <span
                (click)="tableChange(button.key, button.value)"
                *ngIf="button.key !== 'backups-disabled'"
                >Enabled</span
              >
            </ng-template>
            <div class="col-12">
              <p-table
                #dt
                id="enabledTable"
                [value]="button.value.enabled"
                [rows]="10"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10, 25, 50]"
                [paginator]="true"
                [rowHover]="true"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="account-name">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Account Name
                        <p-sortIcon field="account-name"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="connect-id">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Connect ID
                        <p-sortIcon field="connect-id"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="archive-storage-smb-path">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Path
                        <p-sortIcon
                          field="archive-storage-smb-path"
                        ></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="enable-archive">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Enabled
                        <p-sortIcon field="enable-archive"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="last-run-start-time">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Last Run Time
                        <p-sortIcon field="last-run-start-time"></p-sortIcon>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-enabledAccount>
                  <tr
                    (click)="
                      showBackupDetails(
                        enabledAccount['connect-id'],
                        button.key
                      )
                    "
                  >
                    <td>{{ enabledAccount["account-name"] }}</td>
                    <td>{{ enabledAccount["connect-id"] }}</td>
                    <td>{{ enabledAccount["archive-storage-smb-path"] }}</td>
                    <td>{{ enabledAccount["enable-archive"] }}</td>
                    <td>
                      {{
                        enabledAccount["last-run-start-time"] | date: "short"
                      }}
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </mat-tab>
          <mat-tab>
            <ng-template mat-tab-label>
              <span (click)="tableChange(button.key, button.value)"
                >Disabled</span
              >
            </ng-template>
            <div class="col-12">
              <p-table
                #dt
                id="disabledTable"
                [value]="button.value.disabled"
                [rows]="10"
                [showCurrentPageReport]="true"
                [rowsPerPageOptions]="[10, 25, 50]"
                [paginator]="true"
                [rowHover]="true"
              >
                <ng-template pTemplate="header">
                  <tr>
                    <th pSortableColumn="name">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Account Name
                        <p-sortIcon field="name"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="owner">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Connect ID
                        <p-sortIcon field="owner"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="part">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Enabled Archive
                        <p-sortIcon field="part"></p-sortIcon>
                      </div>
                    </th>
                    <th pSortableColumn="start">
                      <div class="p-d-flex p-jc-between p-ai-center">
                        Webapp Domain
                        <p-sortIcon field="start"></p-sortIcon>
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-disabledAccount>
                  <tr>
                    <td>{{ disabledAccount["account-name"] }}</td>
                    <td>{{ disabledAccount["connect-id"] }}</td>
                    <td>{{ disabledAccount["enable-archive"] }}</td>
                    <td>{{ disabledAccount["webapp-domain"] }}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </mat-tab>
        </mat-tab-group>
      </mat-tab>
    </mat-tab-group>
  </div>

  <!-- <table *ngIf="tableDisplay == 'smq' && tableToggle == 'enabled'" id="smq-table" datatable class="table row-border hover">
        <thead>
            <tr>
                <th>Account Name</th>
                <th>Connect ID</th>
                <th>Path</th>
                <th>Enabled</th>
                <th>Last Run Time</th>
            </tr>
        </thead>
        <tbody>
            <tr (click)="showBackupDetails(account['connect-id'])" *ngFor="let account of smqResp.enabled">
                <td>{{account['account-name']}}</td>
                <td>{{account['connect-id']}}</td>
                <td>{{account['archive-storage-smb-path']}}</td>
                <td>{{account['enable-archive']}}</td>
                <td>{{account['last-run-start-time'] | date: 'short'}}</td>
            </tr>
        </tbody>
    </table>

    <table *ngIf="tableDisplay == 'smq' && tableToggle == 'disabled'" id="smq-table" datatable class="table row-border hover">
        <thead>
            <tr>
                <th>Account Name</th>
                <th>Connect ID</th>
                <th>Enabled Archive</th>
                <th>Webapp Domain</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let account of smqResp.disabled">
                <td>{{account['account-name']}}</td>
                <td>{{account['connect-id']}}</td>
                <td>{{account['enable-archive']}}</td>
                <td>{{account['webapp-domain']}}</td>
            </tr>
        </tbody>
    </table>
    <table *ngIf="tableDisplay == 'sjc' && tableToggle == 'enabled'" id="sjc-table" datatable class="table row-border hover">
        <thead>
            <tr>
                <th>Account Name</th>
                <th>Connect ID</th>
                <th>Path</th>
                <th>Enabled</th>
                <th>Last Run Time</th>
            </tr>
        </thead>
        <tbody>
            <tr (click)="showBackupDetails(account['connect-id'])" *ngFor="let account of sjcResp.enabled">
                <td>{{account['account-name']}}</td>
                <td>{{account['connect-id']}}</td>
                <td>{{account['archive-storage-smb-path']}}</td>
                <td>{{account['enable-archive']}}</td>
                <td>{{account['last-run-start-time'] | date: 'short'}}</td>
            </tr>
        </tbody>
    </table>
    <table *ngIf="tableDisplay == 'sjc' && tableToggle == 'disabled'" id="sjc-table" datatable class="table row-border hover">
        <thead>
            <tr>
                <th>Account Name</th>
                <th>Connect ID</th>
                <th>Enabled Archive</th>
                <th>Webapp Domain</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let account of sjcResp.disabled">
                <td>{{account['account-name']}}</td>
                <td>{{account['connect-id']}}</td>
                <td>{{account['enable-archive']}}</td>
                <td>{{account['webapp-domain']}}</td>
            </tr>
        </tbody>
    </table>
    <table *ngIf="tableDisplay == 'lcy' && tableToggle == 'enabled'" id="lcy-table" datatable class="table row-border hover">
        <thead>
            <tr>
                <th>Account Name</th>
                <th>Connect ID</th>
                <th>Path</th>
                <th>Enabled</th>
                <th>Last Run Time</th>
            </tr>
        </thead>
        <tbody>
            <tr (click)="showBackupDetails(account['connect-id'])" *ngFor="let account of lonResp.enabled">
                <td>{{account['account-name']}}</td>
                <td>{{account['connect-id']}}</td>
                <td>{{account['archive-storage-smb-path']}}</td>
                <td>{{account['enable-archive']}}</td>
                <td>{{account['last-run-start-time'] | date: 'short'}}</td>
            </tr>
        </tbody>
    </table>
    <table *ngIf="tableDisplay == 'lcy' && tableToggle == 'disabled'" id="lcy-table" datatable class="table row-border hover">
        <thead>
            <tr>
                <th>Account Name</th>
                <th>Connect ID</th>
                <th>Enabled Archive</th>
                <th>Webapp Domain</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let account of lonResp.disabled">
                <td>{{account['account-name']}}</td>
                <td>{{account['connect-id']}}</td>
                <td>{{account['enable-archive']}}</td>
                <td>{{account['webapp-domain']}}</td>
            </tr>
        </tbody>
    </table>
    <table *ngIf="tableDisplay == 'hkg' && tableToggle == 'enabled'" id="hkg-table" datatable class="table row-border hover">
        <thead>
            <tr>
                <th>Account Name</th>
                <th>Connect ID</th>
                <th>Path</th>
                <th>Enabled</th>
                <th>Last Run Time</th>
            </tr>
        </thead>
        <tbody>
            <tr (click)="showBackupDetails(account['connect-id'])" *ngFor="let account of hkResp.enabled">
                <td>{{account['account-name']}}</td>
                <td>{{account['connect-id']}}</td>
                <td>{{account['archive-storage-smb-path']}}</td>
                <td>{{account['enable-archive']}}</td>
                <td>{{account['last-run-start-time'] | date: 'short'}}</td>
            </tr>
        </tbody>
    </table>
    <table *ngIf="tableDisplay == 'hkg' && tableToggle == 'disabled'" id="hkg-table" datatable class="table row-border hover">
        <thead>
            <tr>
                <th>Account Name</th>
                <th>Connect ID</th>
                <th>Enabled Archive</th>
                <th>Webapp Domain</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let account of hkResp.disabled">
                <td>{{account['account-name']}}</td>
                <td>{{account['connect-id']}}</td>
                <td>{{account['enable-archive']}}</td>
                <td>{{account['webapp-domain']}}</td>
            </tr>
        </tbody>
    </table> -->
  <div *ngIf="detailsShow == true" class="row">
    <h2 class="col-12">{{ detailsAccountName }}</h2>
    <div *ngIf="dbBackupStatus == true" class="col-6">
      <h4>Archive Details</h4>
      <div *ngFor="let backup of backupDetailsArr">
        <p>
          <b>{{ backup.title }}:</b> {{ backup.value }}
        </p>
      </div>
      <p><b>Archive Start:</b> {{ backupStartTime | date: "short" }}</p>
      <p><b>Archive End:</b> {{ backupEndTime | date: "short" }}</p>
    </div>
    <div *ngIf="contentBackupStatus == true" class="col-6">
      <h4>Content Details</h4>
      <div *ngFor="let content of contentDetailsArr">
        <p>
          <b>{{ content.title }}:</b> {{ content.value }}
        </p>
      </div>
    </div>
  </div>
  <div *ngIf="noDataToDisplay == true" class="row">
    <div class="col-12">
      <p style="text-align: center; font-size: 20px; font-weight: bold">
        There is no data for this install currently
      </p>
    </div>
  </div>
</div>
