<div class="content-card">
  <div class="meetingsContainer">
    <div *ngIf="controls.length === 0">
      <div [hidden]="controlsSpinner" id="controls-spinner">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading Controls...</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="controls.length > 0" class="control-container">
      <div
        class="controlNugget"
        [ngClass]="{ active: activeNugget === nugget.name }"
        *ngFor="let nugget of controls; let i = index"
      >
        <a
          (click)="selectInstallControl(nugget['connect-id'], nugget.name)"
          attr.data-value="{{ nugget['connect-id'] }}"
        >
          <h2 title="{{ nugget.name }}">{{ nugget.name }}</h2>
          <p>{{ nugget.response.meetingCount }} Meetings</p>
          <p>{{ nugget.response.meetingUsersCount }} Users</p>
        </a>
      </div>
    </div>
    <div *ngIf="meetingTable" class="meetingsTable">
      <h2>Active Meetings - {{ meetingTableName }}</h2>
      <table datatable class="table row-border hover">
        <thead>
          <tr>
            <th scope="col">{{ meetingTableHeads[1] }}</th>
            <th scope="col">{{ meetingTableHeads[2] }}</th>
            <th scope="col">{{ meetingTableHeads[3] }}</th>
            <th scope="col">{{ meetingTableHeads[4] }}</th>
            <th scope="col">{{ meetingTableHeads[5] }}</th>
            <th scope="col">{{ meetingTableHeads[8] }}</th>
            <th scope="col" *ngIf="killMeetingEntitlement == true"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="meetingRows"
            [ngClass]="{ 'active-row': activeMeetingRow === meetings[0] }"
            (click)="
              renderUserTable(
                meetings[0],
                meetings[9],
                meetingTableID,
                meetings[1]
              )
            "
            *ngFor="let meetings of meetingTableRows"
          >
            <td
              style="
                max-width: 200px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              "
            >
              {{ meetings[1] }}
            </td>
            <td>{{ meetings[2] }}</td>
            <td>{{ meetings[3] }}</td>
            <td>{{ meetings[4].toLocaleString() }}</td>
            <td>{{ meetings[5] }}</td>
            <td>{{ meetings[8] }}</td>
            <td
              style="min-width: 110px"
              scope="col"
              *ngIf="killMeetingEntitlement == true"
            >
              <button
                style="position: relative; top: 0px"
                (click)="killMeeting(meetings[0])"
                class="btn btn-danger"
              >
                END MEETING
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="width: 100%">
        <button
          style="width: 250px; margin: auto; display: block"
          class="btn btn-outline-secondary"
          (click)="downloadrptcsv()"
        >
          <span style="position: relative; top: 5px"
            ><mat-icon>arrow_downward</mat-icon></span
          >
          Download CSV
        </button>
      </div>
    </div>
    <div *ngIf="userTable">
      <h2>Meeting User Report - {{ userTableName }}</h2>
      <table datatable class="table row-border hover">
        <thead>
          <tr>
            <th scope="col">{{ userTableHeads[4] }}</th>
            <th scope="col">{{ userTableHeads[5] }}</th>
            <th scope="col">{{ userTableHeads[6] }}</th>
            <th scope="col">{{ userTableHeads[7] }}</th>
            <th scope="col">{{ userTableHeads[11] }}</th>
            <th scope="col">{{ userTableHeads[12] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let users of userTableRows">
            <td>{{ users[4].toLocaleString() }}</td>
            <td>{{ users[5] }}</td>
            <td>{{ users[6] }}</td>
            <td>{{ users[7] }}</td>
            <td>{{ users[11] }}</td>
            <td>{{ users[12].toLocaleString() }}</td>
          </tr>
        </tbody>
      </table>
      <div style="width: 100%">
        <button
          style="width: 250px; margin: auto; display: block"
          class="btn btn-outline-secondary"
          (click)="downloadusercsv()"
        >
          <span style="position: relative; top: 5px"
            ><mat-icon>arrow_downward</mat-icon></span
          >
          Download CSV
        </button>
      </div>
    </div>
  </div>
</div>
