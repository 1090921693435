import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import { Router } from '@angular/router';
import { UtilsService } from '../shared/services/utils.service';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-cclogs',
  templateUrl: './cclogs.component.html',
  styleUrls: ['./cclogs.component.scss'],
})
export class CCLogsComponent implements OnInit {
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private router: Router,
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
  ) {
    this.fromDate = calendar.getToday();
    this.fromDate.day = this.fromDate.day - 1;
    this.toDate = calendar.getToday();
  }

  userTableHeads;
  userTableName;
  userTableRows;
  userTableID;
  userTableSize;
  userPage;
  userPageSize;
  installList;
  logsSelectedInstall = 'Please Select Connect ID';
  ccLogsInstallSelect;
  renderDrop = false;
  installArr: Array<any> = [];
  logsSelectedLogType = 'access';
  serverList;
  connectIDSelected;
  logsSelectedServer = null;
  agentPastDays = '0';
  maxFetchCount = '20';
  telephonyStartTime;
  telephonyEndTime;
  telephonyPastMin = null;
  debugPastMin = null;
  startingTime = null;
  endingTime = null;
  debugDateDisabled = false;
  telephonyDateDisabled = false;
  archiveBool = false;
  startDate = new Date();
  startDateDay = this.startDate.getDate();
  startDateMonth = this.startDate.getMonth() + 1;
  startDateYear = this.startDate.getFullYear();
  prevDateDay = this.startDateDay - 1;
  meetingTableHeads;
  meetingTableName;
  meetingTableRows;
  machineName;
  meetingTableID;
  meetingTableSize;
  meetingPage;
  meetingPageSize;
  meetingTable = false;
  scoSet;
  graphLoaded = false;
  selectedItem;
  meetingStart;
  meetingEnd = '';
  userTable = false;
  meetingAsset;
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3.5,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          ticks: {
            display: true,
            autoSkip: true,
            beginAtZero: false,
            labelOffset: 0,
            maxTicksLimit: 15,
            maxRotation: 30,
            minRotation: 0,
          },
          type: 'time',
          distribution: 'series',
          time: {
            tooltipFormat: 'MMM D Y h:mm a',
            displayFormats: {
              minute: 'MMM D h:mm a',
              hour: 'dddd LT',
              day: 'MMM D Y',
            },
          },
          gridLines: {
            borderDash: [8, 4],
            color: '#808080',
          },
        },
      ],
      yAxes: [
        {
          id: '1',
          position: 'left',
        },
      ],
    },
  };
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  public lineChartColors: Color[] = [
    {
      // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
    {
      // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)',
    },
    {
      // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  startDateObj = {
    year: this.startDateYear,
    month: this.startDateMonth,
    day: this.startDateDay,
  };
  prevDateObj = {
    year: this.startDateYear,
    month: this.startDateMonth,
    day: this.prevDateDay,
  };
  // selectedConnectID = await this.selectedConnectID(Object.keys(installList.data)[0]);
  // getLog = await this.getlogsAPI(Object.keys(installList.data)[0])

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
    console.log(this.fromDate, this.toDate);
  }

  getMeetings = () => {
    this.meetingTable = false;
    this.userTable = false;
    this.machineName = false;
    if (
      this.logsSelectedLogType == 'application' ||
      this.logsSelectedLogType == 'meeting'
    ) {
      this.fetchMeetings();
    } else {
      return;
    }
  };

  sendSco = (
    scoParam,
    activeParam,
    meetingStartTime,
    assetParam,
    machineNameParam,
  ) => {
    this.scoSet = scoParam;
    this.selectedItem = activeParam;
    this.meetingStart = meetingStartTime.toISOString();
    this.meetingAsset = assetParam;
    this.machineName = machineNameParam;
    this.renderUserTable();
  };

  renderUserTable = async () => {
    var data = {
      domain: 'data-lake',
      api: 'meetingUsersReport',
      properties: {
        'connect-id': this.logsSelectedInstall,
        'cacheable-sco-id': this.scoSet,
        'cacheable-asset-id': this.meetingAsset,
      },
    };
    var response = await this.apiCallsService.sendApi(data);
    this.renderMeetingUsers(response.data.data['query-results']);
  };
  renderMeetingUsers = (userTableParam) => {
    this.graphLoaded = false;
    this.userTable = true;
    this.userTableHeads = userTableParam.headings;
    this.userTableRows = userTableParam.rows;
    for (var i = 0; i < this.userTableRows.length; i++) {
      if (this.userTableRows[i][4] === '') {
        this.userTableRows[i][4] = 'Open';
      } else {
        this.userTableRows[i][4] = new Date(this.userTableRows[i][4]);
      }
    }
    for (var i = 0; i < this.userTableRows.length; i++) {
      this.userTableRows[i][12] = new Date(this.userTableRows[i][12]);
    }
    this.userPage = 1;
    this.userPageSize = 10;
    this.userTableSize = this.userTableRows.length;
    this.fetchGraph();
  };

  fetchMeetings = async () => {
    var data = {
      domain: 'data-lake',
      api: 'activeMeetingsReport',
      properties: {
        'connect-id': this.logsSelectedInstall,
      },
    };
    var response = await this.apiCallsService.sendApi(data);
    if (response.status == 'FAILED_INVALID') {
      alert(
        'The Connect instance does not have proper data. Try another Server',
      );
      return;
    }
    this.renderMeetings(
      response.data.data['query-results'],
      this.logsSelectedInstall,
    );
  };

  renderMeetings = (tableParam, installID) => {
    this.meetingTable = true;
    this.meetingTableID = installID;
    this.meetingTableHeads = tableParam.headings;
    this.meetingTableRows = tableParam.rows;
    for (var i = 0; i < this.meetingTableRows.length; i++) {
      this.meetingTableRows[i][4] = new Date(this.meetingTableRows[i][4]);
    }
    this.meetingPage = 1;
    this.meetingPageSize = 10;
    this.meetingTableSize = tableParam.rows.length;
  };

  getAnalysisApi = async () => {
    var data = {
      domain: 'data-lake',
      api: 'ccRequestMeetingAnalysis',
      properties: {
        'connect-id': this.machineName,
        'sco-id': this.scoSet,
        'asset-id': this.meetingAsset,
      },
    };
    var response = await this.apiCallsService.sendApi(data);
    this.downloadRedirect(response.data.data['download-key']);
  };

  fetchGraph = async () => {
    var data = {
      domain: 'data-lake',
      api: 'getTranscriptCount',
      properties: {
        'connect-id': this.logsSelectedInstall,
        'sco-id': this.scoSet,
        begin: this.meetingStart,
      },
    };
    var response = await this.apiCallsService.sendApi(data);
    this.renderGraph(response.data.data);
  };

  renderGraph = (graphData) => {
    this.lineChartData = [];
    this.lineChartLabels = [];
    for (var k = 0; k < graphData.chartData.length; k++) {
      this.lineChartData.push(graphData.chartData[k]);
    }
    for (var i = 0; i < graphData.labels.length; i++) {
      var dateObj = new Date(graphData.labels[i]);
      this.lineChartLabels.push(dateObj.toLocaleString());
    }
    console.log(this.lineChartLabels, this.lineChartData);
    this.graphLoaded = true;
  };

  checkPastMin = () => {
    if (
      this.debugPastMin == '0' ||
      this.debugPastMin == '' ||
      this.debugPastMin == null
    ) {
      this.debugDateDisabled = false;
    } else {
      this.debugDateDisabled = true;
    }
    if (
      this.telephonyPastMin == '0' ||
      this.telephonyPastMin == '' ||
      this.telephonyPastMin == null
    ) {
      this.telephonyDateDisabled = false;
    } else {
      this.telephonyDateDisabled = true;
    }
  };

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  getinstallList = async () => {
    var data = {
      domain: 'data-lake',
      api: 'ccListAccounts',
    };
    var response = await this.apiCallsService.sendApi(data);
    return response;
  };

  connectIDToggle = (param) => {
    var arrList = [];
    for (var i = 0; i < param.length; i++) {
      if (param[i]['is-shared-storage'] == false) {
        arrList.push(param[i]);
      }
    }
    this.serverList = arrList;
    this.connectIDSelected = true;
  };

  selectConnectIDApi = async () => {
    this.meetingTable = false;
    this.scoSet = '';
    this.meetingStart = '';
    this.meetingEnd = '';
    this.meetingAsset = '';
    this.userTable = false;
    this.logsSelectedLogType = 'access';
    if (this.logsSelectedInstall !== 'Please Select Connect ID') {
      var data = {
        domain: 'data-lake',
        api: 'list-connect-install-servers',
        properties: {
          'connect-id': this.logsSelectedInstall,
        },
      };
      var response = await this.apiCallsService.sendApi(data);
    } else {
      this.connectIDSelected = false;
    }
    this.connectIDToggle(response.data['server-list']);
  };

  downloadRedirect = (param) => {
    var baseURL = this.apiCallsService.urlUtil();
    var downloadURL = baseURL + '/download/' + param;
    window.open(downloadURL);
  };

  dateConvert = () => {
    var tempstartingTime = new Date();
    var tempendingTime = new Date();
    var isoStart;
    var isoEnd;
    tempendingTime.setMonth(this.toDate.month - 1);
    tempendingTime.setDate(this.toDate.day);
    tempendingTime.setFullYear(this.toDate.year);
    tempstartingTime.setMonth(this.fromDate.month - 1);
    tempstartingTime.setDate(this.fromDate.day);
    tempstartingTime.setFullYear(this.fromDate.year);
    console.log(tempendingTime, tempstartingTime);
    isoStart = tempstartingTime.toISOString();
    isoEnd = tempendingTime.toISOString();
    this.startingTime = isoStart.slice(0, 19);
    this.endingTime = isoEnd.slice(0, 19);
  };

  getConfigArchive = async () => {
    var data = {
      domain: 'data-lake',
      api: 'getConfigArchive',
      properties: {
        'connect-id': this.logsSelectedServer,
      },
    };
    var response = await this.apiCallsService.sendApi(data);
    this.downloadRedirect(response.data.data['download-key']);
  };

  getlogsAPI = async () => {
    if (this.logsSelectedServer !== null) {
      var data = {};
      switch (this.logsSelectedLogType) {
        case 'agent':
          data = {
            domain: 'data-lake',
            api: 'getLogs',
            properties: {
              'connect-id': this.logsSelectedServer,
              'log-type': this.logsSelectedLogType,
              'days-ago': this.agentPastDays,
            },
          };
          break;
        case 'application':
          if (this.machineName == false) {
            alert('Please Select a meeting before downloading');
            return;
          }
          data = {
            domain: 'data-lake',
            api: 'getLogs',
            properties: {
              'connect-id': this.machineName,
              'log-type': this.logsSelectedLogType,
              'sco-id': this.scoSet.toString(),
            },
          };
          break;
        case 'meeting':
          if (this.machineName == false) {
            alert('Please Select a meeting before downloading');
            return;
          }
          this.dateConvert();
          data = {
            domain: 'data-lake',
            api: 'getLogs',
            properties: {
              'connect-id': this.machineName,
              'log-type': this.logsSelectedLogType,
              'sco-id': this.scoSet.toString(),
              'start-time': this.meetingStart,
              'end-time': this.meetingEnd,
            },
          };
          break;
        case 'access':
        case 'diagnostic':
        case 'error':
          data = {
            domain: 'data-lake',
            api: 'getLogs',
            properties: {
              'connect-id': this.logsSelectedServer,
              'log-type': this.logsSelectedLogType,
            },
          };
          break;
        case 'gc':
          data = {
            domain: 'data-lake',
            api: 'getLogs',
            properties: {
              'connect-id': this.logsSelectedServer,
              'log-type': this.logsSelectedLogType,
              archive: this.archiveBool,
            },
          };
          break;
        case 'telephony':
          if (
            this.telephonyPastMin == null ||
            this.telephonyPastMin == '' ||
            this.telephonyPastMin == '0'
          ) {
            this.dateConvert();
            data = {
              domain: 'data-lake',
              api: 'getLogs',
              properties: {
                'connect-id': this.logsSelectedServer,
                'log-type': this.logsSelectedLogType,
                'start-time': this.startingTime,
                'end-time': this.endingTime,
              },
            };
          } else {
            data = {
              domain: 'data-lake',
              api: 'getLogs',
              properties: {
                'connect-id': this.logsSelectedServer,
                'log-type': this.logsSelectedLogType,
                'past-minutes': this.telephonyPastMin,
              },
            };
          }
          break;
        case 'debug':
          if (
            this.debugPastMin == null ||
            this.debugPastMin == '' ||
            this.debugPastMin == '0'
          ) {
            this.dateConvert();
            data = {
              domain: 'data-lake',
              api: 'getLogs',
              properties: {
                'connect-id': this.logsSelectedServer,
                'log-type': this.logsSelectedLogType,
                'start-time': this.startingTime,
                'end-time': this.endingTime,
              },
            };
          } else {
            data = {
              domain: 'data-lake',
              api: 'getLogs',
              properties: {
                'connect-id': this.logsSelectedServer,
                'log-type': this.logsSelectedLogType,
                'past-minutes': this.debugPastMin,
              },
            };
          }
          break;
        default:
        // code block
      }
      if (this.logsSelectedLogType == 'agent') {
      }
      var response = await this.apiCallsService.sendApi(data);
      this.downloadRedirect(response.data.data['download-key']);
    } else {
      alert('You must select a server');
      return;
    }
  };

  async ngOnInit(): Promise<any> {
    this.installList = await this.getinstallList();
    // for (var i = 0; i < Object.keys(this.installList.data).length; i++) {
    //   this.installArr.push({})
    //   this.installArr[i].connectID = (Object.keys(this.installList.data)[i])
    //   for (var key in this.installList.data)
    //   if (key === Object.keys(this.installList.data)[i]) {
    //   var val = this.installList.data[key]
    //   this.installArr[i].name = val.account_name
    //   }

    // }
    this.renderDrop = true;
  }
}
