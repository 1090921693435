<div class="content-card">
  <div class="row">
    <div class="col-sm-2">
      <label for="dataType">Select Datacenter</label>
      <select
        [(ngModel)]="dataCenter"
        class="form-control"
        (change)="getDest()"
        name="range"
        id="rangeDrop"
      >
        <option>Select Datacenter</option>
        <option
          *ngFor="let datacenter of datacenters"
          [ngValue]="datacenter.id"
        >
          {{ datacenter.name }}
        </option>
      </select>
    </div>
    <div *ngIf="dataCenter !== 'Select Datacenter'" class="col-sm-2">
      <label for="dcType">Select Type</label>
      <select
        [(ngModel)]="destSelection"
        class="form-control"
        (change)="getDest()"
        name="dcType"
      >
        <option value="ext">Datacenter</option>
        <option value="int">TCP echo servers</option>
        <option value="acct">Account</option>
      </select>
    </div>
    <div *ngIf="destSelection == 'int'" class="col-sm-2">
      <label for="tcp">Select Test Server</label>
      <select
        [(ngModel)]="TCPSelection"
        class="form-control"
        (change)="getMonitors()"
        name="tcp"
      >
        <option *ngFor="let TCP of TCPResponse" [ngValue]="TCP.id">
          {{ TCP.name }}
        </option>
        <option *ngIf="TCPResponse.length == 0">
          there are no TCP echo servers for this datacenter
        </option>
      </select>
    </div>
    <div *ngIf="destSelection == 'acct'" class="col-sm-2">
      <label for="connectID">Select Account</label>
      <select
        class="form-control"
        id="type"
        (change)="getMonitors()"
        [(ngModel)]="perfMonSelectedInstall"
        name="connectID"
      >
        <option selected>Select Account</option>
        <option
          *ngFor="let install of installList; let i = index"
          [ngValue]="install.id"
        >
          {{ install.name }}
        </option>
      </select>
    </div>
    <div *ngIf="dataCenter !== 'Select Datacenter'" class="col-sm-2">
      <label for="connectID">Select Monitor</label>
      <select
        class="form-control"
        id="type"
        (change)="getApisPer()"
        [(ngModel)]="perfMonSelectedServer"
        name="connectID"
      >
        <option selected>Select Monitor</option>
        <option
          *ngFor="let monitor of monitorList; let i = index"
          [ngValue]="monitor.id"
        >
          {{ monitor.name }}
        </option>
      </select>
    </div>
    <div *ngIf="perfMonSelectedServer !== 'Select Monitor'" class="col-sm-2">
      <label for="connectID">Select Test Type</label>
      <select
        class="form-control"
        id="type"
        (change)="sendMonitorAPI()"
        [(ngModel)]="testType"
        name="connectID"
      >
        <option
          *ngFor="let test of testTypes; let i = index"
          [ngValue]="test.id"
        >
          {{ test.name }}
        </option>
      </select>
    </div>
    <!-- <div *ngIf="destSelection =='ext'" class="col-sm-2">
        <label for="server">Select Monitor</label>
        <select [(ngModel)]="perfMonSelectedServer" (change)="sendMonitorAPI()" class="form-control" name="server">
          <option selected>Select Monitor</option>
            <option *ngFor="let server of serverList; let i = index" [ngValue]="server.id">
                {{server.name}}
            </option>
      </select>
      </div> -->
  </div>
  <div style="margin-top: 25px" class="row">
    <!--<div *ngIf="perfMonSelectedServer !== 'Select Monitor'" class="col-sm-2">
    <label for="monitorDrop">Monitor Type</label>
    <select [(ngModel)]="selectedMonitor" class="form-control"  (change)="sendMonitorAPI()"  name="monitorDrop" id="monitorDrop">
        <option selected>Select Monitor Type</option>
        <option *ngFor="let monitor of monitorList; let i = index" [ngValue]="monitor.id" >
            {{monitor.name}}
        </option>
    </select>
</div> -->
    <!-- <div *ngIf="perfMonSelectedServer !== 'Please Select Monitor'" class="offset-sm-1 col-sm-1">
    <label for="monitorDrop">Monitor Type</label>
    <select [(ngModel)]="selectedMonitor" class="form-control"  (change)="sendMonitorAPI()"  name="monitorDrop" id="monitorDrop">
        <option value="CPU">CPU</option>
    <optgroup label="Memory">
        <option value="MemSize">Memory</option>
        <option value="MemPages">Hard PG</option>
        <option value="MemSwitch">C Switch</option>
    </optgroup>
    <optgroup label="Disk">
        <option value="DiskQ">R/W Queue</option>
        <option value="DiskT">MS per R/W</option>
    </optgroup>
    <optgroup label="TCP">
        <option value="NetConn">Conn</option>
        <option value="NetErr">Errs</option>
        <option value="NetSeg">Segs</option>
        <option value="NetRetrans">Retr</option>
    </optgroup>
    </select>
</div> -->
    <div *ngIf="perfMonSelectedServer !== 'Select Monitor'" class="col-sm-2">
      <div>
        <p><b>Start</b></p>
        <span style="font-size: 12px; margin-right: 10px">Range</span
        ><mat-slide-toggle
          (change)="toggleTimeRange()"
          [(ngModel)]="dayToggle"
        ></mat-slide-toggle
        ><span style="font-size: 12px; margin-left: 10px">Custom</span>
      </div>
    </div>
    <div
      *ngIf="perfMonSelectedServer !== 'Select Monitor' && dayToggle == false"
      class="col-sm-2"
    >
      <label for="range">Range</label>
      <select
        [(ngModel)]="selectedTimeRange"
        class="form-control"
        (change)="sendMonitorAPI()"
        name="range"
        id="rangeDrop"
      >
        <option value="10">10 Minutes</option>
        <option value="15">15 Minutes</option>
        <option value="20">20 Minutes</option>
        <option value="30">30 Minutes</option>
        <option value="45">45 Minutes</option>
        <option value="60">1 Hour</option>
        <option value="120">2 Hours</option>
        <option value="180">3 Hours</option>
        <option value="240">4 Hours</option>
        <option value="300">5 Hours</option>
        <option value="360">6 Hours</option>
        <option value="420">7 Hours</option>
        <option value="480">8 Hours</option>
        <option value="540">9 Hours</option>
        <option value="600">10 Hours</option>
        <option value="660">11 Hours</option>
        <option value="720">12 Hours</option>
        <option value="780">13 Hours</option>
        <option value="840">14 Hours</option>
        <option value="900">15 Hours</option>
        <option value="960">16 Hours</option>
        <option value="1020">17 Hours</option>
        <option value="1080">18 Hours</option>
        <option value="1140">19 Hours</option>
        <option value="1200">20 Hours</option>
        <option value="1260">21 Hours</option>
        <option value="1320">22 Hours</option>
        <option value="1380">23 Hours</option>
        <option value="1440">24 Hours</option>
      </select>
    </div>
    <div
      *ngIf="perfMonSelectedServer !== 'Select Monitor' && dayToggle == true"
      class="col-sm-2"
    >
      <label for="startDate">Start Date</label>
      <form class="form-inline">
        <div style="width: 100%" class="form-group">
          <div class="input-group">
            <input
              class="form-control date-input"
              placeholder="yyyy-mm-dd"
              name="startDate"
              [maxDate]="maxDate"
              [(ngModel)]="selectedStartDate"
              (ngModelChange)="sendMonitorAPI()"
              ngbDatepicker
              #d="ngbDatepicker"
            />
            <div class="input-group-append">
              <button
                class="btn btn-outline-secondary calendar"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div
      *ngIf="perfMonSelectedServer !== 'Select Monitor' && dayToggle == true"
      class="col-sm-2"
    >
      <label for="timeSelect">Enter Time</label>
      <div class="form-group">
        <div class="time-input input-group">
          <ngb-timepicker
            name="timeSelect"
            (ngModelChange)="sendTime()"
            [(ngModel)]="startTime"
          ></ngb-timepicker>
        </div>
      </div>
    </div>
    <div *ngIf="perfMonSelectedServer !== 'Select Monitor'" class="col-sm-2">
      <div>
        <p><b>End</b></p>
        <span style="font-size: 12px; margin-right: 10px">Now</span
        ><mat-slide-toggle
          (change)="toggleDate()"
          [(ngModel)]="dateToggle"
        ></mat-slide-toggle
        ><span style="font-size: 12px; margin-left: 10px">Custom</span>
      </div>
    </div>
    <div *ngIf="perfMonSelectedServer !== 'Select Monitor'" class="col-sm-2">
      <label for="endDate">End Date</label>
      <form class="form-inline">
        <div style="width: 100%" class="form-group">
          <div class="input-group">
            <input
              [disabled]="dateDisabled == true"
              class="form-control date-input"
              placeholder="yyyy-mm-dd"
              name="endDate"
              [maxDate]="maxDate"
              [(ngModel)]="endDate"
              (ngModelChange)="sendMonitorAPI()"
              ngbDatepicker
              #d="ngbDatepicker"
            />
            <div class="input-group-append">
              <button
                [disabled]="dateDisabled == true"
                class="btn btn-outline-secondary calendar"
                (click)="d.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div *ngIf="perfMonSelectedServer !== 'Select Monitor'" class="col-sm-2">
      <label for="timeSelect">Enter Time</label>
      <div class="form-group">
        <div class="input-group time-input">
          <ngb-timepicker
            [disabled]="dateDisabled == true"
            name="timeSelect"
            (ngModelChange)="sendTime()"
            [(ngModel)]="time"
          ></ngb-timepicker>
        </div>
      </div>
    </div>
  </div>
  <hr class="col-sm-12" *ngIf="perfMonSelectedServer !== 'Select Monitor'" />
  <div class="row">
    <div
      style="text-align: center"
      [hidden]="testType !== 'meeting-tcp-test'"
      class="col-sm-4 offset-sm-8"
    >
      <span style="color: #269ad0">Current Percentile: </span>
      <span
        style="
          margin-left: 5px;
          padding: 10px;
          border-radius: 30px;
          background-color: #269ad0;
          color: white;
        "
        ><b> {{ sliderSendVal }}</b></span
      ><span
        [ngbPopover]="popContent"
        [popoverTitle]="popTitle"
        placement="bottom"
        class="percentile-info"
        ><mat-icon class="percentile-icon">info</mat-icon></span
      >
      <ng-template #popContent
        ><p>
          The TCP echo test sends multiple meeting video and streams from a
          remote monitor to simulate a Connect meeting over the course of 60
          seconds. We capture latency every 1/10 of a second, then sort the
          latency numbers in increasing order.
        </p>
        <p>
          The percentile slider lets you select the percentile of the latency
          you are viewing for each data point. So for example if you select the
          95th percentile, then you are getting the latency (in milliseconds) at
          the 95th percentile. That means that 95% of the latency numbers were
          *better* than this value and 5% of the percentile numbers were
          *worse*. If you select the 100% percentile then you are seeing the
          single *worst* latency number across the entire 60 seconds of the
          test.
        </p>
        <p>
          A useful place to start looking for patterns is to expand the timeline
          out to 24 hours or more, then select the 90th or 95th percentile
        </p>
      </ng-template>
      <ng-template #popTitle><b>TCP Echo Test</b></ng-template>
    </div>
    <div *ngIf="graphLoaded == true" class="col-sm-12 chart">
      <canvas
        id="monitorGraph"
        baseChart
        [datasets]="lineChartData"
        [labels]="lineChartLabels"
        [options]="lineChartOptions"
        [colors]="lineChartColors"
        [legend]="lineChartLegend"
        [chartType]="lineChartType"
        [plugins]="lineChartPlugins"
      >
      </canvas>
    </div>
    <div *ngIf="noDataShow == true" class="no-data">
      <p>There is no data for this chart</p>
    </div>
  </div>
  <hr class="col-sm-12" *ngIf="graphLoaded == true" />
  <div
    style="margin-top: 35px"
    id="tcp-slider"
    [hidden]="testType !== 'meeting-tcp-test'"
  >
    <mat-slider
      #tcpSlider
      (input)="updateSliderValue()"
      thumbLabel
      [(ngModel)]="sliderVal"
      tickInterval="5"
      step="0.5"
      min="0"
      max="100"
    ></mat-slider>
  </div>
  <div *ngIf="testType == 'datacenter-ping-test'" class="row">
    <h2 class="col-sm-10">Traceroute Data</h2>
    <ngb-carousel [interval]="false" class="col-sm-10 offset-sm-1">
      <ng-template *ngFor="let trace of tracertData" ngbSlide>
        <p class="col-sm-12 text-center">{{ trace.time | date: "long" }}</p>
        <textarea
          disabled
          style="overflow: scroll; width: 100%"
          name=""
          id=""
          rows="25"
          >{{ trace.trace }}</textarea
        >
      </ng-template>
    </ngb-carousel>
  </div>
</div>
