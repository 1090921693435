import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Color, Label } from 'ng2-charts';
import { MatTabGroup } from '@angular/material/tabs';
import { ApiCallsService } from '../shared/services/api-calls.service';
import { Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'app-cc-search',
  templateUrl: './cc-search.component.html',
  styleUrls: ['./cc-search.component.scss'],
})
export class CcSearchComponent implements OnInit {
  @Output()
  buttonClicked: EventEmitter<any> = new EventEmitter<any>();

  sessionSearch;
  sessionTable = false;
  scoTable = false;
  userTable = false;
  scoSearch;
  userSearch;
  indexVal;
  connectID;
  scoCheck = false;
  userCheck = false;
  sessionCheck = false;
  timeCheck = false;
  sessionTableResp;
  scoTableResp;
  userTableResp;
  meetingTableResp;
  meetingTable = false;

  constructor(
    private router: Router,
    private apiCallsService: ApiCallsService,
  ) {}

  ngOnInit(): void {
    this.connectID = JSON.parse(sessionStorage.getItem('ccConnectID'));
  }

  search = async (type) => {
    var searchParam;
    var nameParam;
    var timeframe;
    switch (type) {
      case 'session':
        searchParam = this.sessionSearch;
        nameParam = this.sessionCheck;
        break;
      case 'sco':
        searchParam = this.scoSearch;
        nameParam = this.scoCheck;
        break;
      case 'user':
        searchParam = this.userSearch;
        nameParam = this.userCheck;
        break;
      default:
      // code block
    }

    if (this.timeCheck == false) {
      timeframe = '3days';
    } else {
      timeframe = 'all';
    }

    var data: { [k: string]: any } = {
      domain: 'data-lake',
      api: 'ccSearch',
      properties: {
        'connect-id': this.connectID,
        search: searchParam,
        type: type,
        name: nameParam,
      },
    };
    if (type == 'session') {
      data.properties.time = timeframe;
    }
    var response = await this.apiCallsService.sendApi(data);
    switch (type) {
      case 'session':
        this.sessionTableResp = response.data.data.sessions;
        this.sessionTable = true;
        break;
      case 'sco':
        this.scoTableResp = response.data.data.scos;
        this.scoTable = true;
        this.meetingTableResp = response.data.data.meetings;
        this.meetingTable = true;
        break;
      case 'user':
        this.userTableResp = response.data.data.users;
        this.userTable = true;
        break;
      default:
      // code block
    }
  };

  clickButton(sco, asset) {
    this.buttonClicked.emit([sco, asset]);
  }

  scoSearchTable = (sco) => {
    this.scoSearch = sco;
    this.search('sco');
  };
}
