import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color, BaseChartDirective } from 'ng2-charts';
import { UtilsService } from '../shared/services/utils.service';
import { ApiCallsService } from '../shared/services/api-calls.service';
import jsPDF from 'jspdf';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;
  insightsUrl = this.apiCallsService.urlUtil();
  domain = this.apiCallsService.domainUtil();
  seshKey = this.apiCallsService.seshKeyUtil();
  todayDate = new Date();
  reportChartReady = false;
  peakMeetingSlice;
  peakAttendeesSlice;
  peakTimeSlice;
  averageAttendeesSlice;
  totalLaunchesSlice;
  averageLaunchesSlice;
  peakLaunchesSlice;
  averageMeetingSlice;
  averageTimeSlice;
  totalAttendeesSlice;
  totalMeetingsSlice;
  totalTimeSlice;
  oneInstall;
  public barChartLabels: Label[] = ['', '', '', '', '', '', '', '', ''];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];
  public reportInstallSelect = JSON.parse(sessionStorage.getItem('installs'));
  public reportSelectedInstall = this.reportInstallSelect[0];
  public timeRange = 'WEEK';
  public chartTitle = 'Meeting Activity';
  chartTypes = this.utilsService.chartTypes();
  public selectedChartType = 'MeetingActivityRpt';
  public selectedUserType = 'ALL';
  public barChartData: Array<any> = [{}];
  public barChartColors = [
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#25408F',
      borderWidth: 0,
    },
    {
      backgroundColor: '#269AD0',
      borderColor: '#269AD0',
      borderWidth: 1,
    },
  ];

  public chart2Colors = [
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#25408F',
      borderWidth: 0,
    },
    {
      backgroundColor: '#269AD0',
      borderColor: '#269AD0',
      borderWidth: 1,
    },
  ];

  barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 2.5,
    animation: {
      duration: 0,
    },
    elements: {
      point: {
        radius: 2,
      },
    },
    scales: {
      yAxes: [{}],
      xAxes: [{}],
    },
    tooltips: {
      //  		mode: 'nearest',
      intersect: true,
      mode: 'point',
      filter: function (tooltipItem, data) {
        if (tooltipItem.value == '1200') {
          return false;
        } else {
          return true;
        }
      },
    },
    legend: {},
    legendTemplate:
      '<ul class="<%=name.toLowerCase()%>-legend"><% for (var i=0; i<subdata.datasets.length; i++){%><li><span style="background-color:<%=subdata.datasets[i].strokeColor%>"></span><%if(subdata.datasets[i].label){%><%=subdata.datasets[i].label+"GB"%><%}%></li><%}%></ul>',
  };

  constructor(
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
  ) {}

  ngOnInit() {
    this.rptUpdate();
    this.oneInstall = localStorage.getItem('OnlyOne');
    if (this.oneInstall == 'true') {
      this.oneInstall = true;
    } else {
      this.oneInstall = false;
    }
  }

  findCol = (headers, colname) => {
    var col = headers.findIndex(function (header) {
      return colname === header;
    });
    return col;
  };

  typeChanged = (titleParam, value) => {
    this.chartTitle = titleParam;
    var title = titleParam;
    var chrtgroup = value;
    var index: any = 0;

    for (var key in this.chartTypes) {
      index = this.chartTypes[key].chartName === chrtgroup ? key : index;
    }
    var chartDef = this.chartTypes[index];
    this.rptUpdate();
  };

  GetChartData = async (chartDef, installNames) => {
    // returns a promise array for each active install
    var sessionkey = '';
    var actives = []; //name,
    var qdata = [];
    var installs = this.reportSelectedInstall;
    const sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
    installNames.push(installs.name);
    var target = []; // needed to force value not reference
    actives.push(installs); // array of active installs

    for (var key in actives) {
      // loop over all the installs and find the active ones
      if (chartDef.object) {
        // loop over all the installs and find the active ones
        var ccUrl = actives[0]['connect-id'];
        var data = {
          domain: 'data-lake',
          api: 'dailyMetrics',
          'session-key': this.seshKey,
          properties: {
            'session-key': this.seshKey,
            'connect-id': ccUrl,
            monthly: false,
            range: this.timeRange.toLocaleLowerCase(),
          },
        };

        var response = await this.apiCallsService.sendApi(data);
        qdata.push(response);
      }
      /*
      // second load from the api
      */
      var reportSlices = '';
      var d: any = new Date();
      var period = this.timeRange;

      if (period === 'WEEK') {
        d.setDate(d.getDate() - 7);
        reportSlices = 'DAY';
      }
      if (period === 'MONTH') {
        d.setMonth(d.getMonth() - 1);
        reportSlices = 'DAY';
      }
      if (period === '2MONTHS') {
        d.setMonth(d.getMonth() - 2);
        reportSlices = 'WEEK';
      }
      if (period === '3MONTHS') {
        d.setMonth(d.getMonth() - 3);
        reportSlices = 'WEEK';
      }
      if (period === '6MONTHS') {
        d.setMonth(d.getMonth() - 6);
        reportSlices = 'WEEK';
      }
      if (period === '12MONTHS') {
        d.setMonth(d.getMonth() - 13);
        reportSlices = 'MONTH';
      }
      if (period === 'YEAR') {
        d.setMonth(d.getMonth() - (d.getMonth() + 1));
        reportSlices = 'MONTH';
      }

      var startDate = this.utilsService
        .getFDate(d, 'short')
        .replace('-', '/')
        .replace('-', '/');
      var endDate = this.utilsService
        .getFDate(new Date(), 'short')
        .replace('-', '/')
        .replace('-', '/');

      for (var f in chartDef.filtering) {
        if (this.selectedUserType) {
          var userType: any = this.selectedUserType;
        } else {
          var userType: any = chartDef.filtering[f].userType;
        }
        var contentType = chartDef.filtering[f].contentType;
        //			var reportSlices = chartDef.filtering[f].reportSlices;
        var data2 = {
          domain: this.domain,
          api: 'data.getLearningData',
          username: sessionStorage.getItem('username'),
          'session-key': this.seshKey,
          filtering: {
            userType: userType,
            contentType: contentType,
            startDate: startDate,
            endDate: endDate,
            reportSlices: reportSlices,
            installationKeys: [actives[key].key],
          },
        };
        var rec = await this.apiCallsService.sendApi(data2);
        qdata.push(rec);
      }
    } //end for
    return Promise.all(qdata);
  };

  GetColorArray = () => {
    var colorArr: Array<any> = [17];
    colorArr[2] = '#D6923B';
    colorArr[1] = '#DB0001';
    colorArr[0] = '#5494C1';
    return colorArr;
  };

  ConvertMinutes = (num) => {
    var days = Math.floor(num / 1440);
    var hours = Math.floor((num % 1440) / 60);
    var minutes = (num % 1440) % 60;
    return {
      days: days,
      hours: hours,
      minutes: minutes,
    };
  };

  rptUpdate = async () => {
    this.reportChartReady = false;
    var datasets = [{}],
      labels = [],
      Names = [],
      headers = [];
    var num: any;
    var rows, c, o, r: any;
    var tot = [0],
      avg = [0],
      max = [0];
    var pointtot: any = 0,
      pointavg: any = 0,
      pointmax: any = 0,
      colorindex: any = 0,
      num: any = 0,
      filterindex: any = 0;
    const installNames = [];
    const chartName = this.selectedChartType;

    const chartDefs = this.utilsService.chartTypes();
    for (var k in chartDefs) {
      //find out chart def
      if (chartDefs[k].chartName === chartName) {
        var chartDef = chartDefs[k];
      }
    }
    let jqxr = await this.GetChartData(chartDef, installNames); // get the data

    var lastDat = [],
      datcol = [],
      cdat = [],
      gdat = [],
      subdata: any = [],
      dataset: any = [],
      headers = [];

    var subchartindex;
    var yAxisID = '';
    var axises = ['A', 'B', 'C', 'D'];
    subdata.datasets = [];
    var scaleLabels = [];
    var datasetIndex = 0;
    this.barChartOptions.scales = {
      xAxes: [
        {
          gridLines: { display: false },
          stacked: true,
        },
      ],
      yAxes: [],
    };

    const colors = this.GetColorArray();
    for (k in jqxr) {
      // each dataset - should be three

      labels = [];
      o = await jqxr[k];

      /* return from insights api call - should draw 2 lines*/
      if (o.status === 'ok' && o) {
        // return from api call
        var cdat = [];
        rows = o.response.results;
        var datcol = [];
        var gdat = [];
        var dataset: any = [];
        subchartindex = 0; /// only one subchart in reports

        for (r = 0; r < rows.length; r++) {
          num = parseInt(rows[r].totalStarts || 0);
          cdat.push(num);
          labels[r] = rows[r].startDate;
          pointtot = parseInt(pointtot) + num;
          pointavg = pointtot / (r + 1);
          //pointmax = num > pointmax ? num : pointmax;
          if (num > pointmax) {
            pointmax = num;
            maxdate = rows[r]['startDate'];
          }
        }

        dataset.data = cdat;
        yAxisID = axises[datasetIndex];

        if (jqxr.length > 2) {
          dataset.type = 'line';
        } else {
          dataset.type = 'bar';
        }
        scaleLabels[yAxisID] = this.toTitleCase(
          chartDef.filtering[filterindex].axisLabel,
        );

        dataset.label = this.toTitleCase(
          installNames[0] + ' ' + chartDef.filtering[filterindex++].axisLabel,
        );

        dataset.yAxisID = yAxisID;
        dataset.borderColor = colors[colorindex];
        dataset.pointBorderColor = colors[colorindex];
        dataset.pointBackgroundColor = colors[colorindex];
        dataset.pointHoverBackgroundColor = colors[colorindex];
        dataset.pointHoverBorderColor = colors[colorindex];
        dataset.backgroundColor = colors[colorindex];
        this.barChartOptions.scales.yAxes.push({
          id: axises[datasetIndex],
          stacked: false,
          scaleLabel: {
            display: true,
            labelString: this.toTitleCase(scaleLabels[axises[datasetIndex]]),
          },
          ticks: {
            max: pointmax,
            beginAtZero: true,
          },
          type: 'linear',
          position: 'right',
        });

        var targetl = []; // needed to force value not reference
        Object.assign(targetl, dataset);
        subdata.datasets[datasetIndex++] = targetl; //[datasetIndex++]
        colorindex++;
        Names.push(installNames[k]);

        /* return from Sales force action.do call
        we draw a bars with this one*/
      } else if (o.status == 'SUCCESS') {
        // this is the return from one install in actions.do - one item per day

        rows = o.data.rows;
        headers = o.data.headers;
        datcol.length = 0;
        var legend = [];
        var days = [];
        var dayvals = [];
        cdat.length = 0;
        dataset.length = 0;
        subchartindex = 0; /// only one subchart in reports
        cdat = new Array(headers.length).fill(0);
        tot = new Array(headers.length).fill(0);
        avg = new Array(headers.length).fill(0);
        max = new Array(headers.length).fill(0);
        var maxdate = '';

        var offsets = chartDef.subcharts[subchartindex].offsets; // the col num we need
        for (c = 0; c < offsets.length; c++) {
          datcol[c] = this.findCol(headers, offsets[c]);
          legend[c] = headers[datcol[c]].toUpperCase();
        }
        // only one column here - but multiple days
        // sum up days for the chart
        var d = new Date();
        var inc = 1;
        var summer = 400;
        var lbldate: any = d.setDate(d.getDate() + 70); // way in the future;
        var period = this.timeRange;
        if (period === 'WEEK') {
          inc = 1;
        }
        if (period === 'MONTH') {
          inc = 1;
        }
        if (period === '2MONTHS') {
          inc = 7;
        }
        if (period === '3MONTHS') {
          inc = 7;
        }
        if (period === '6MONTH') {
          inc = 1;
        }
        if (period === '12MONTH') {
          inc = 30;
        }
        if (period === 'YEAR') {
          inc = 30;
        }

        for (r = 0; r < rows.length; r++) {
          // get the totals and averages
          cdat[r] = new Array(headers.length);
          for (var i in headers) {
            num = parseInt(rows[r][i] || 0);
            tot[i] = tot[i] + num;
            avg[i] = tot[i] / (r + 1);

            if (num > max[i]) {
              max[i] = num;
              if (parseInt(i) === datcol[0]) {
                maxdate = rows[r][0];
              }
            }
            cdat[r][i] = num;
          }

          num = parseInt(rows[r][datcol[0]] || 0);
          if (inc === 1) {
            dayvals[this.utilsService.getFDate(rows[r][0], 'short')] = num;
          } else if (summer < inc) {
            dayvals[lbldate] += num;
            summer++;
          } else {
            d = new Date(rows[r][0]);
            d.setDate(d.getDate() + inc);
            lbldate = this.utilsService.getFDate(d, 'short');
            dayvals[lbldate] = num;
            summer = 1;
          }
        }
        var bardata = [];

        for (var val in dayvals) {
          bardata.push(dayvals[val]);
        }

        //for (c in offsets){
        dataset.data = bardata;
        dataset.type = 'bar';
        var t = ''; // needed to force value not reference

        t = chartDef.axisLabel;
        dataset.label = this.toTitleCase(installNames[k] + ' ' + t);
        //dataset.yAxisID = 'A';
        dataset.yAxisID = axises[datasetIndex];
        scaleLabels[dataset.yAxisID] = this.toTitleCase(t);

        dataset.borderColor = colors[colorindex];
        dataset.pointBorderColor = colors[colorindex];
        dataset.pointBackgroundColor = colors[colorindex];
        dataset.pointHoverBackgroundColor = colors[colorindex];
        dataset.pointHoverBorderColor = colors[colorindex];
        dataset.backgroundColor = colors[colorindex];

        dataset.pointHoverBorderWidth = 1;
        dataset.pointRadius = 1;
        dataset.fill = false;

        this.barChartOptions.scales.yAxes.push({
          id: axises[datasetIndex],
          stacked: true,
          scaleLabel: {
            display: true,
            labelString: scaleLabels['A'],
          },
          type: 'linear',
          position: 'left',
          gridLines: {
            drawOnChartArea: true,
            borderDash: [8, 4],
            color: '#ffffff',
          },
          ticks: {
            beginAtZero: true,
          },
        });

        var target = []; // needed to force value not reference
        Object.assign(target, dataset);
        //subdata.datasets[2]=target;
        subdata.datasets[datasetIndex++] = target;
        colorindex++;

        Names.push(installNames[k]);
      } // end if(o.trans  - once through for each install
      if (dataset.type === 'bar') {
        dataset.data = new Array(dataset.data.length).fill(1200); // don't chaange the 1200
        dataset.type = 'bar';
        dataset.yAxisID = axises[datasetIndex];
        this.barChartOptions.scales.yAxes.push({
          id: axises[datasetIndex],
          stacked: true,
          gridLines: { display: false },
          scaleLabel: { display: false },
          display: false,
          ticks: {
            beginAtZero: true,
          },
        });
        dataset.borderColor = '#EEE';
        dataset.pointBorderColor = '#EEE';
        dataset.pointBackgroundColor = '#EEE';
        dataset.pointHoverBackgroundColor = '#EEE';
        dataset.pointHoverBorderColor = '#EEE';
        dataset.pointHoverRadius = 0;
        dataset.backgroundColor = '#EEE';
        dataset.label = '';
        dataset.pointHoverBorderWidth = 0;
        dataset.pointRadius = 0;
        subdata.datasets[datasetIndex++] = dataset;
      }
    } //end for all datasets

    // this.options.legend= {
    //    labels: {
    //      filter: function(legendItem, chartData) {
    //     if (legendItem.text==="") {
    //       return false;
    //     }
    //      return true;
    //      }
    //   }
    // }
    subdata.labels = labels;
    var newChartData: Array<any> = [];
    for (var p = 0; p < subdata.datasets.length; p++) {
      newChartData.unshift({});
      newChartData[0].data = subdata.datasets[p].data;
      newChartData[0].label = subdata.datasets[p].label;
      newChartData[0].type = subdata.datasets[p].type;
      newChartData[0].yAxisID = subdata.datasets[p].yAxisID;
    }
    this.barChartLabels = [];
    this.barChartData = [];
    this.barChartColors = [];
    this.barChartLabels = labels;
    this.barChartData = newChartData;
    var period = this.timeRange;

    var reportSlices = '';
    if (period === 'WEEK') {
      reportSlices = 'DAY';
    }
    if (period === 'MONTH') {
      reportSlices = 'DAY';
    }
    if (period === '2MONTH') {
      reportSlices = 'WEEK';
    }
    if (period === '3MONTH') {
      reportSlices = 'WEEK';
    }
    if (period === '6MONTH') {
      reportSlices = 'WEEK';
    }
    if (period === '12MONTH') {
      reportSlices = 'MONTH';
    }
    if (period === 'YEAR') {
      reportSlices = 'MONTH';
    }

    var totals = [];
    totals.push(headers);
    totals.push(tot);
    totals.push(avg);
    totals.push(max);
    totals.push(pointtot);
    totals.push(pointavg);
    totals.push(pointmax);
    totals.push(maxdate);
    totals.push(reportSlices);
    totals.push(chartDef.object);

    var col;

    if (totals[9]) {
      //tot && tot[1] > 0){
      if (document.getElementById('totals')) {
        col = this.findCol(headers, 'meeting count');
        this.totalMeetingsSlice = 'Meetings: ' + tot[col].toLocaleString();
        col = this.findCol(headers, 'meeting sessions');
        this.totalAttendeesSlice = 'Attendees: ' + tot[col].toLocaleString();
        col = this.findCol(headers, 'meeting minutes');
        var m = this.ConvertMinutes(tot[col]);
        this.totalTimeSlice =
          'Meeting Time Spent: ' +
          m.days +
          ' Days, ' +
          m.hours +
          ' Hours, and ' +
          m.minutes.toFixed(0) +
          ' Minutes'; //+ tot[col];
      }
      if (document.getElementById('average')) {
        col = this.findCol(headers, 'meeting count');
        this.averageMeetingSlice =
          'Meetings: ' + avg[col].toFixed(0).toLocaleString();
        col = this.findCol(headers, 'meeting sessions');
        this.averageAttendeesSlice =
          'Attendees: ' + avg[col].toFixed(0).toLocaleString();
        col = this.findCol(headers, 'meeting minutes');
        var m = this.ConvertMinutes(avg[col]);
        this.averageTimeSlice =
          'Meeting Time Spent: ' +
          m.days +
          ' Days, ' +
          m.hours +
          ' Hours, and ' +
          m.minutes.toFixed(0) +
          ' Minutes'; //+ avg[col].toFixed(0)
      }
      if (document.getElementById('peaks')) {
        col = this.findCol(headers, 'meeting count');
        this.peakMeetingSlice = 'Meetings: ' + max[col].toLocaleString();
        col = this.findCol(headers, 'meeting sessions');
        this.peakAttendeesSlice = 'Attendees: ' + max[col].toLocaleString();
        col = this.findCol(headers, 'meeting minutes');
        var m = this.ConvertMinutes(max[col]);
        this.peakTimeSlice =
          'Meeting Time Spent: ' +
          m.days +
          ' Days, ' +
          m.hours +
          ' Hours, and ' +
          m.minutes.toFixed(0) +
          ' Minutes'; //+ max[col];
      }
    } else {
      if (document.getElementById('totals')) {
        this.totalLaunchesSlice =
          'Total Launches: ' + (pointtot || 0).toLocaleString();
        this.averageLaunchesSlice =
          'Launches: ' +
          (pointavg.toFixed(0) || 0).toLocaleString() +
          ' (per ' +
          reportSlices +
          ')';
        if (maxdate) {
          var date = this.utilsService.getFDate(new Date(maxdate), 'today');
          this.peakLaunchesSlice =
            'Peak ' +
            (reportSlices || 0).toLocaleString() +
            ': ' +
            date +
            ' (' +
            pointmax +
            ' launches)';
        } else {
          this.peakLaunchesSlice =
            'Peak ' +
            (reportSlices || 0).toLocaleString() +
            ':  (' +
            pointmax +
            ' launches)';
        }
      }
    }

    sessionStorage.setItem('totals', JSON.stringify(totals));
    if (subdata.datasets[2] && subdata.datasets[2].type === 'line') {
      const swapPositions = (array, a, b) => {
        [array[a], array[b]] = [array[b], array[a]];
      };

      swapPositions(subdata.datasets, 2, 0);
      swapPositions(subdata.datasets, 3, 1);
    }
    //   BarChart.data = subdata;
    //   BarChart.options = options;
    //   if (period !== "WEEK") {
    //   for (var i = 0; i < BarChart.data.labels.length; i++) {
    //     if (BarChart.data.datasets[2].data.length < BarChart.data.labels.length) {
    //       BarChart.data.datasets[2].data.unshift(0)
    //       BarChart.data.datasets[3].data.unshift(BarChart.data.datasets[3].data[0])
    //     }
    //   }
    // }
    //     BarChart.update();

    // set up csv data

    var dates: any = [0];
    dates[0] = ['Date/Time'];
    dates.push(labels);
    for (c = 0; c < subdata.datasets.length; c++) {
      dates[0].push(subdata.datasets[c].label);
      dates.push(subdata.datasets[c].data);
    }
    sessionStorage.setItem('csvDatasets', JSON.stringify(dates));
    if (subdata.datasets.length > 3) {
      if (this.barChartOptions.scales.yAxes[2]['ticks'].max == 0) {
        this.barChartOptions.scales.yAxes[2]['ticks'].max = 5;
      }
      this.barChartColors.push(
        {
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: '#F7A209',
          borderWidth: 3,
        },
        {
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: '#db0001',
          borderWidth: 3,
        },
        {
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: '#25408F',
          borderWidth: 0,
        },
        {
          backgroundColor: '#269AD0',
          borderColor: '#269AD0',
          borderWidth: 1,
        },
      );
    } else {
      this.barChartColors.push(
        {
          backgroundColor: 'rgba(0,0,0,0)',
          borderColor: '#25408F',
          borderWidth: 0,
        },
        {
          backgroundColor: '#269AD0',
          borderColor: '#269AD0',
          borderWidth: 1,
        },
      );
    }
    this.chart.update();
    this.reportChartReady = true;
    //	document.getElementById("loadSpinner").classList.add("uk-hidden");
    return datasets;
  };
  toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  downloadcsv = () => {
    var data = JSON.parse(sessionStorage.getItem('csvDatasets'));
    var csv = data[0].toString() + '%0D%0A';
    for (var c = 0; c < data[1].length; c++) {
      for (var i = 1; i < data.length; i++) {
        csv += data[i][c] + ',';
      }
      csv += '%0D%0A';
    }

    var link = document.createElement('a');
    link.href =
      'data:application/octet-stream,field1%2Cfield2%0Afoo%2Cbar%0Agoo%2Cgai%0A';
    link.href = 'data:application/octet-stream,' + csv;
    link.download = 'myChart.csv';
    link.click();
  };

  download = () => {
    var srcCanvas = <HTMLCanvasElement>document.getElementById('reportsChart');
    var destinationCanvas = document.createElement('canvas');
    destinationCanvas.width = srcCanvas.width;
    destinationCanvas.height = srcCanvas.height;
    var destCtx = destinationCanvas.getContext('2d');
    //create a rectangle with the desired color
    destCtx.fillStyle = '#FFFFFF';
    destCtx.fillRect(0, 0, srcCanvas.width, srcCanvas.height);
    //draw the original canvas onto the destination canvas
    destCtx.drawImage(srcCanvas, 0, 0);
    //finally use the destinationCanvas.toDataURL() method to get the desired output;
    var link = document.createElement('a');
    link.href = destinationCanvas.toDataURL();
    link.download = 'ReportsChart.jpeg';
    link.click();
  };
  downloadpdf = () => {
    var srcCanvas = <HTMLCanvasElement>document.getElementById('reportsChart');
    var pdfNameDate: any = new Date();
    pdfNameDate = pdfNameDate.toDateString();
    var destinationCanvas = document.createElement('canvas');
    destinationCanvas.width = srcCanvas.width;
    destinationCanvas.height = srcCanvas.height;
    var destCtx = destinationCanvas.getContext('2d');
    //create a rectangle with the desired color
    destCtx.fillStyle = '#FFFFFF';
    destCtx.fillRect(0, 0, srcCanvas.width, srcCanvas.height);
    //draw the original canvas onto the destination canvas
    destCtx.drawImage(srcCanvas, 0, 0);
    var doc: any = new jsPDF('landscape');
    doc.setFontSize(20);
    doc.text(15, 15, 'Super Cool Chart');
    doc.addImage(destinationCanvas, 'JPEG', 10, 10, 280, 100);
    doc.save('Report Chart ' + pdfNameDate + '.pdf');
  };
}
