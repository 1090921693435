<div class="content-card">
  <div class="recentContentContainer">
    <div *ngIf="controls.length === 0">
      <div [hidden]="controlsSpinner" id="controls-spinner">
        <div class="d-flex justify-content-center">
          <div class="spinner-border text-light" role="status">
            <span class="sr-only">Loading Controls...</span>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="controls.length > 0" class="control-container">
      <div
        [ngClass]="{ active: activeNugget === nugget.name }"
        class="controlNugget"
        *ngFor="let nugget of controls; let i = index"
      >
        <a
          (click)="selectInstallControl(nugget['connect-id'], nugget.name)"
          attr.data-value="{{ nugget['connect-id'] }}"
        >
          <h2 title="{{ nugget.name }}">{{ nugget.name }}</h2>
          <p>{{ nugget.response.meetingCount }} Meetings</p>
          <p>{{ nugget.response.meetingUsersCount }} Users</p>
        </a>
      </div>
    </div>
    <div *ngIf="recentContentTable">
      <h2>Recent Content - {{ recentContentTableName }}</h2>
      <table datatable class="table row-border table-striped">
        <thead>
          <tr>
            <th scope="col">{{ recentContentTableHeads[0] }}</th>
            <th scope="col">{{ recentContentTableHeads[1] }}</th>
            <th scope="col">{{ recentContentTableHeads[2] }}</th>
            <th scope="col">{{ recentContentTableHeads[3] }}</th>
            <th scope="col">{{ recentContentTableHeads[4] }}</th>
            <th scope="col">{{ recentContentTableHeads[5] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let recentContent of recentContentTableRows">
            <td style="min-width: 180px">
              {{ recentContent[0].toLocaleString() }}
            </td>
            <td>{{ recentContent[1] }}</td>
            <td>{{ recentContent[2] }}</td>
            <td>{{ recentContent[3] }}</td>
            <td>{{ recentContent[4] }}</td>
            <td>{{ recentContent[5] }}</td>
          </tr>
        </tbody>
      </table>
      <div></div>
      <div style="width: 100%">
        <button
          style="width: 250px; margin: auto; display: block"
          class="btn btn-outline-secondary"
          (click)="downloadrptcsv()"
        >
          <span style="position: relative; top: 5px"
            ><mat-icon>arrow_downward</mat-icon></span
          >
          Download CSV
        </button>
      </div>
    </div>
  </div>
</div>
