import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import { Router } from '@angular/router';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-cc-connect-backups',
  templateUrl: './cc-connect-backups.component.html',
  styleUrls: ['./cc-connect-backups.component.scss'],
})
export class CcConnectBackupsComponent implements OnInit {
  listResponse;
  tableDisplay;
  smqResp;
  sjcResp;
  hkgResp;
  lcyResp;
  disabledResp;
  backupDetailsObj;
  backupTime;
  backupDetailsArr;
  contentDetailsObj;
  contentTime;
  contentDetailsArr = [];
  detailsShow = false;
  contentBackupStatus = false;
  dbBackupStatus = false;
  detailsAccountName;
  noDataToDisplay = false;
  indexVal;
  tableObj;
  tablesLoaded;

  constructor(
    private router: Router,
    private apiCallsService: ApiCallsService,
  ) {}

  async ngOnInit(): Promise<any> {
    this.listResponse = await this.getInitialList();
    this.tablesLoaded = true;
    this.indexVal = 0;
  }

  showBackupDetails = (param, datacenter) => {
    this.backupDetailsArr = [];
    this.contentDetailsArr = [];
    var backupArr = [];
    backupArr.push(this.listResponse.data[datacenter]);

    for (var i = 0; i < backupArr[0].length; i++) {
      if (backupArr[0][i]['connect-id'] == param) {
        this.detailsAccountName =
          backupArr[0][i]['account-name'] +
          ' (' +
          backupArr[0][i]['connect-id'] +
          ')';
        if (backupArr[0][i]['db-backup-status']) {
          this.backupDetailsObj = backupArr[0][i]['db-backup-status'].data;
          for (var key in this.backupDetailsObj) {
            if (
              key == 'last-log-backup-timestamp' ||
              key == 'last-full-backup-timestamp'
            ) {
              continue;
            } else {
              var newObj = {
                title: key,
                value: this.backupDetailsObj[key],
              };
              this.backupDetailsArr.push(newObj);
            }
          }
          this.backupTime = backupArr[0][i]['db-backup-status'].time;
          this.dbBackupStatus = true;
        } else {
          this.dbBackupStatus = false;
        }
        if (backupArr[0][i]['content-backup-status']) {
          this.contentDetailsObj =
            backupArr[0][i]['content-backup-status'].data;
          for (var key in this.contentDetailsObj) {
            var newObj = {
              title: key,
              value: this.contentDetailsObj[key],
            };
            this.contentDetailsArr.push(newObj);
          }
          this.contentTime = backupArr[0][i]['content-backup-status'].time;
          this.contentBackupStatus = true;
        } else {
          this.contentBackupStatus = false;
        }
      }
    }
    if (this.contentBackupStatus == false && this.dbBackupStatus == false) {
      this.detailsShow = false;
      this.noDataToDisplay = true;
    } else {
      this.noDataToDisplay = false;
      this.detailsShow = true;
    }
  };

  getInitialList = async () => {
    var data = {
      domain: 'data-lake',
      api: 'list-connect-backup-statuses',
      properties: {},
    };
    var response = await this.apiCallsService.sendApi(data);
    return response;
  };

  tableChange = (name, object) => {
    this.detailsShow = false;
    this.noDataToDisplay = false;
    this.tableObj = object;
  };
}
