import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { InstallsSelectDashComponent } from './installs-select-dash/installs-select-dash.component';
import { DashHeaderComponent } from './globalComponents/dash-header/dash-header.component';
import { SidebarNavComponent } from './globalComponents/sidebar-nav/sidebar-nav.component';
import { ApiCallsService } from './shared/services/api-calls.service';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MeetingTablesComponent } from './meeting-tables/meeting-tables.component';
import { RecentLoginsComponent } from './recent-logins/recent-logins.component';
import { RecentContentComponent } from './recent-content/recent-content.component';
import { AdminComponent } from './admin/admin.component';
import { TechSupportComponent } from './tech-support/tech-support.component';
import { TicketComponent } from './ticket/ticket.component';
import { ChartsModule } from 'ng2-charts';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ReportsComponent } from './reports/reports.component';
import { MetricsComponent } from './metrics/metrics.component';
import { CCLogsComponent } from './cclogs/cclogs.component';
import { CCInstallSelectComponent } from './ccinstall-select/ccinstall-select.component';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { AuditComponent } from './audit/audit.component';
import { DisableuserComponent } from './disableuser/disableuser.component';
import { CCPerfMonComponent } from './ccperf-mon/ccperf-mon.component';
import { LicenseReportComponent } from './license-report/license-report.component';
import { CcRemoteMonitorComponent } from './cc-remote-monitor/cc-remote-monitor.component';
import { NgbdSortableHeader } from './shared/services/sortable.service';
import { DataTablesModule } from 'angular-datatables';
import { Ng5SliderModule } from 'ng5-slider';
import { MatSliderModule } from '@angular/material/slider';
import { CcListAccountComponent } from './cc-list-account/cc-list-account.component';
import { CcConnectBackupsComponent } from './cc-connect-backups/cc-connect-backups.component';
import { CcJVMComponent } from './cc-jvm/cc-jvm.component';
import { CcS3Component } from './cc-s3/cc-s3.component';
import { CustomMonitorComponent } from './custom-monitor/custom-monitor.component';
import { TableModule } from 'primeng/table';
import { PrimeNGConfig } from 'primeng/api';
import { AccordionModule } from 'primeng/accordion';
import { CcExtrasComponent } from './cc-extras/cc-extras.component';
import { CcSearchComponent } from './cc-search/cc-search.component';
import { TestParentComponent } from './test-parent/test-parent.component';
import { TestChildComponent } from './test-child/test-child.component';
import { CcRecentMeetingsComponent } from './cc-recent-meetings/cc-recent-meetings.component';
import { EditTicketComponent } from './ticket/edit-ticket/edit-ticket.component';
import { InstallComponent } from './admin/install/install.component';
import { ViewInstallComponent } from './admin/view-install/view-install.component';
import { EditInstallComponent } from './admin/edit-install/edit-install.component';
import { DatePipe } from '@angular/common';
import { EditUserComponent } from './admin/edit-user/edit-user.component';
import { SSOLogsComponent } from './sso-logs/sso-logs.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InstallsSelectDashComponent,
    DashHeaderComponent,
    SidebarNavComponent,
    MeetingTablesComponent,
    RecentLoginsComponent,
    RecentContentComponent,
    AdminComponent,
    TechSupportComponent,
    TicketComponent,
    DashboardComponent,
    ReportsComponent,
    MetricsComponent,
    CCLogsComponent,
    CCInstallSelectComponent,
    AuditComponent,
    DisableuserComponent,
    CCPerfMonComponent,
    LicenseReportComponent,
    CcRemoteMonitorComponent,
    NgbdSortableHeader,
    CcListAccountComponent,
    CcConnectBackupsComponent,
    CcJVMComponent,
    CcS3Component,
    CustomMonitorComponent,
    CcExtrasComponent,
    CcSearchComponent,
    TestParentComponent,
    TestChildComponent,
    CcRecentMeetingsComponent,
    EditTicketComponent,
    InstallComponent,
    ViewInstallComponent,
    EditInstallComponent,
    EditUserComponent,
    SSOLogsComponent,
  ],
  imports: [
    FormsModule,
    TableModule,
    AccordionModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    ChartsModule,
    NgSelectModule,
    MatIconModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatTabsModule,
    MatDatepickerModule,
    DataTablesModule,
    // Ng5SliderModule,
    MatSliderModule,
    MatSlideToggleModule,
  ],
  providers: [ApiCallsService, NgbdSortableHeader, CcJVMComponent, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
