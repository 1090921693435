import { Component, OnInit } from '@angular/core';
import { CcJVMComponent } from '../cc-jvm/cc-jvm.component';
import { ApiCallsService } from '../shared/services/api-calls.service';
import { Router } from '@angular/router';
import { UtilsService } from '../shared/services/utils.service';
import {
  NgbDate,
  NgbCalendar,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbTimepickerConfig,
  NgbTimeStruct,
} from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'app-ccperf-mon',
  templateUrl: './ccperf-mon.component.html',
  styleUrls: ['./ccperf-mon.component.scss'],
})
export class CCPerfMonComponent implements OnInit {
  constructor(
    public config: NgbTimepickerConfig,
    private jvm: CcJVMComponent,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private router: Router,
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
  ) {}

  renderJvm = 'first';
  installList;
  showJVMCharts;
  connectIDSelected = false;
  serverList;
  jvmTimeRange: any = '-15';
  renderDrop;
  datacenterList = [];
  perfMonSelectedServer;
  selectedDatacenter = 'Please Select Datacenter';
  perfMonSelectedInstall = 'Please Select Connect ID';
  selectedMonitor = 'CPU';
  connectIDList = [];
  showConnect = false;
  stopTime = '';
  graphLoaded;
  serverSelected;
  showTests = true;
  dateDisabled = true;
  dateToggle = false;
  tabVal;
  indexVal = 0;
  dayToggle = false;

  // Date Picker

  selectedTimeRange = '30';
  selectedStartDate;
  startTime;
  maxDate;
  selectedIndex;
  fullEndDate;
  fullStartDate;
  time;
  endDate;

  //

  // Metrics
  selectedSubchart = 'PeakMeeting';
  selectedChartType = 'line';
  missingData;
  missingDataToast;
  metricsChartLabels;
  metricsChartData;
  metricsChartReady;
  selectedMetricsChart = 'HourlyPeak';
  chartTypes = this.utilsService.chartTypes();
  //
  public lineChartData: ChartDataSets[] = [];
  public lineChartLabels: Label[] = [];
  public lineChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3.5,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          ticks: {
            display: true,
            autoSkip: true,
            beginAtZero: false,
            labelOffset: 0,
            maxTicksLimit: 15,
            maxRotation: 30,
            minRotation: 0,
          },
          type: 'time',
          distribution: 'series',
          time: {
            tooltipFormat: 'MMM D Y h:mm a',
            displayFormats: {
              minute: 'MMM D h:mm a',
              hour: 'dddd LT',
              day: 'MMM D Y',
            },
          },
          gridLines: {
            borderDash: [8, 4],
            color: '#808080',
          },
        },
      ],
      yAxes: [
        {
          id: 1,
          position: 'left',
        },
      ],
    },
  };
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  public lineChartColors: Color[] = [
    {
      // grey
      backgroundColor: 'rgba(148,159,177,0.2)',
      borderColor: 'rgba(148,159,177,1)',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
    {
      // dark grey
      backgroundColor: 'rgba(77,83,96,0.2)',
      borderColor: 'rgba(77,83,96,1)',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(77,83,96,1)',
    },
    {
      // red
      backgroundColor: 'rgba(255,0,0,0.3)',
      borderColor: 'red',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)',
    },
  ];

  public firstChartData: ChartDataSets[] = [];
  public firstChartLabels: Label[] = [];
  public secondChartData: ChartDataSets[] = [];
  public secondChartLabels: Label[] = [];
  public thirdChartData: ChartDataSets[] = [];
  public thirdChartLabels: Label[] = [];
  public fourthChartData: ChartDataSets[] = [];
  public fourthChartLabels: Label[] = [];
  public firstChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3.5,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          ticks: {
            display: true,
            autoSkip: true,
            beginAtZero: false,
            labelOffset: 0,
            maxTicksLimit: 15,
            maxRotation: 30,
            minRotation: 0,
          },
          type: 'time',
          distribution: 'series',
          time: {
            tooltipFormat: 'MMM D Y h:mm a',
            displayFormats: {
              minute: 'MMM D h:mm a',
              hour: 'dddd LT',
              day: 'MMM D Y',
            },
          },
          gridLines: {
            borderDash: [8, 4],
            color: '#808080',
          },
        },
      ],
      yAxes: [],
    },
  };
  public firstChartLegend = true;
  public firstChartType = 'line';
  public firstChartPlugins = [];

  public secondChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3.5,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          ticks: {
            display: true,
            autoSkip: true,
            beginAtZero: false,
            labelOffset: 0,
            maxTicksLimit: 15,
            maxRotation: 30,
            minRotation: 0,
          },
          type: 'time',
          distribution: 'series',
          time: {
            tooltipFormat: 'MMM D Y h:mm a',
            displayFormats: {
              minute: 'MMM D h:mm a',
              hour: 'dddd LT',
              day: 'MMM D Y',
            },
          },
          gridLines: {
            borderDash: [8, 4],
            color: '#808080',
          },
        },
      ],
      yAxes: [],
    },
  };
  public secondChartLegend = true;
  public secondChartType = 'line';
  public secondChartPlugins = [];

  public thirdChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3.5,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          ticks: {
            display: true,
            autoSkip: true,
            beginAtZero: false,
            labelOffset: 0,
            maxTicksLimit: 15,
            maxRotation: 30,
            minRotation: 0,
          },
          type: 'time',
          distribution: 'series',
          time: {
            tooltipFormat: 'MMM D Y h:mm a',
            displayFormats: {
              minute: 'MMM D h:mm a',
              hour: 'dddd LT',
              day: 'MMM D Y',
            },
          },
          gridLines: {
            borderDash: [8, 4],
            color: '#808080',
          },
        },
      ],
      yAxes: [],
    },
  };
  public thirdChartLegend = true;
  public thirdChartType = 'line';
  public thirdChartPlugins = [];

  public fourthChartOptions = {
    responsive: true,
    maintainAspectRatio: true,
    aspectRatio: 3.5,
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      xAxes: [
        {
          ticks: {
            display: true,
            autoSkip: true,
            beginAtZero: false,
            labelOffset: 0,
            maxTicksLimit: 15,
            maxRotation: 30,
            minRotation: 0,
          },
          type: 'time',
          distribution: 'series',
          time: {
            tooltipFormat: 'MMM D Y h:mm a',
            displayFormats: {
              minute: 'MMM D h:mm a',
              hour: 'dddd LT',
              day: 'MMM D Y',
            },
          },
          gridLines: {
            borderDash: [8, 4],
            color: '#808080',
          },
        },
      ],
      yAxes: [],
    },
  };
  public fourthChartLegend = true;
  public fourthChartType = 'line';
  public fourthChartPlugins = [];

  async ngOnInit(): Promise<any> {
    this.installList = await this.getinstallList();
    for (var i = 0; i < this.installList.data.length; i++) {
      if (
        this.datacenterList.indexOf(
          this.installList.data[i]['datacenter_key'],
        ) == -1
      ) {
        if (this.installList.data[i]['datacenter_key'] == null) {
          if (this.datacenterList.indexOf('No Datacenter') == -1) {
            this.datacenterList.push('No Datacenter');
            continue;
          } else {
            continue;
          }
        }
        this.datacenterList.push(this.installList.data[i]['datacenter_key']);
      }
    }
    var today = new Date();
    this.endDate = this.calendar.getToday();
    this.time = {
      hour: today.getHours(),
      minute: today.getMinutes(),
      second: 0,
    };
    this.selectedStartDate = this.calendar.getToday();
    this.startTime = {
      hour: today.getHours(),
      minute: today.getMinutes(),
      second: 0,
    };
    this.maxDate = this.calendar.getToday();
    this.config.seconds = false;
    this.config.spinners = false;
    this.renderDrop = true;
  }

  showCharts = (param) => {
    if (param == 'JVM') {
      this.showJVMCharts = true;
      this.graphLoaded = false;
      this.indexVal = 1;
    } else {
      this.showJVMCharts = false;
      this.graphLoaded = true;
      this.indexVal = 0;
    }
  };

  setServer = (param) => {
    this.perfMonSelectedServer = param;
    this.sendMonitorAPI();
  };

  getConnectIds = () => {
    this.connectIDList = [];
    for (var i = 0; i < this.installList.data.length; i++) {
      if (
        this.installList.data[i]['datacenter_key'] == this.selectedDatacenter
      ) {
        this.connectIDList.push(this.installList.data[i]);
      } else if (this.installList.data[i]['datacenter_key'] == null) {
        this.connectIDList.push(this.installList.data[i]);
      }
    }
    this.showConnect = true;
  };

  sendMonitorAPI = async () => {
    if (this.indexVal == 2) {
      this.metUpdate();
    } else {
      if (this.perfMonSelectedServer == null) {
        return;
      }
      if (this.indexVal == 1) {
        this.getJvmChart();
        return;
      }
      var tempEndDate = new Date();
      var tempStartDate = new Date();
      var range = parseInt(this.selectedTimeRange);
      var target;
      tempEndDate.setMonth(this.endDate.month - 1);
      tempEndDate.setDate(this.endDate.day);
      tempEndDate.setFullYear(this.endDate.year);

      if (this.time !== undefined) {
        tempEndDate.setHours(this.time.hour);
        tempEndDate.setMinutes(this.time.minute + 1);
        tempEndDate.setSeconds(this.time.second);
      }
      this.fullEndDate = tempEndDate;
      if (this.dayToggle == true) {
        tempStartDate.setMonth(this.selectedStartDate.month - 1);
        tempStartDate.setDate(this.selectedStartDate.day);
        tempStartDate.setFullYear(this.selectedStartDate.year);
        if (this.startTime !== undefined) {
          tempStartDate.setHours(this.startTime.hour);
          tempStartDate.setMinutes(this.startTime.minute + 1);
          tempStartDate.setSeconds(this.startTime.second);
        }
        this.fullStartDate = tempStartDate;
        if (this.fullStartDate.getTime() == this.fullEndDate.getTime()) {
          this.fullStartDate.setDate(this.fullEndDate.getDate() - 1);
          this.startTime.minute = this.time.minute;
          this.startTime.hour = this.fullEndDate.getHours();
          var ngbDate = NgbDate.from(this.endDate);
          this.selectedStartDate = this.calendar.getPrev(ngbDate, 'd', 1);
        } else {
          tempStartDate.setMinutes(this.startTime.minute);
        }
      } else {
        this.fullStartDate = new Date(tempEndDate);
        this.fullStartDate.setMinutes(this.fullStartDate.getMinutes() - range);
      }

      if (this.fullStartDate.getTime() >= this.fullEndDate.getTime()) {
        let today = new Date();
        alert('You cannot set the start time to be after the end time');
        this.startTime = {
          hour: this.fullEndDate.getHours(),
          minute: this.fullEndDate.getMinutes(),
          second: 0,
        };
        this.fullStartDate = new Date(this.fullEndDate);
        this.fullStartDate.setMinutes(this.fullEndDate.getMinutes() - 15);
        this.startTime.minute = this.fullStartDate.getMinutes();
        if (this.fullEndDate.getMinutes() < 15) {
          this.startTime.hour = this.fullEndDate.getHours() - 1;
        }
        var ngbDate = NgbDate.from(this.endDate);
        this.selectedStartDate = this.calendar.getPrev(
          ngbDate,
          'd',
          0.0104166666666667,
        );
        this.selectedStartDate.month = this.fullEndDate.getMonth() + 1;
        this.selectedStartDate.day = this.fullEndDate.getDate();
        this.selectedStartDate.year = this.fullEndDate.getFullYear();
      }
      var endDate = new Date();
      var range = parseInt(this.selectedTimeRange);
      var startdate = endDate;
      startdate.setMinutes(startdate.getMinutes() - range);
      var data = {
        domain: 'data-lake',
        api: 'ccGetServerTelemetry',
        properties: {
          'connect-id': this.perfMonSelectedInstall[0],
          server: this.perfMonSelectedServer,
          category: this.selectedMonitor,
          start: this.fullStartDate,
          stop: this.fullEndDate,
        },
      };
      var response = await this.apiCallsService.sendApi(data);
      var chartResp = await this.renderMonitorGraph(response);
    }
  };

  getJvmChart = async () => {
    var tempEndDate = new Date();
    var tempStartDate = new Date();
    var range = parseInt(this.selectedTimeRange);
    var target;
    tempEndDate.setMonth(this.endDate.month - 1);
    tempEndDate.setDate(this.endDate.day);
    tempEndDate.setFullYear(this.endDate.year);

    if (this.time !== undefined) {
      tempEndDate.setHours(this.time.hour);
      tempEndDate.setMinutes(this.time.minute + 1);
      tempEndDate.setSeconds(this.time.second);
    }
    this.fullEndDate = tempEndDate;
    if (this.dayToggle == true) {
      tempStartDate.setMonth(this.selectedStartDate.month - 1);
      tempStartDate.setDate(this.selectedStartDate.day);
      tempStartDate.setFullYear(this.selectedStartDate.year);
      if (this.startTime !== undefined) {
        tempStartDate.setHours(this.startTime.hour);
        tempStartDate.setMinutes(this.startTime.minute + 1);
        tempStartDate.setSeconds(this.startTime.second);
      }
      this.fullStartDate = tempStartDate;
      if (this.fullStartDate.getTime() == this.fullEndDate.getTime()) {
        this.fullStartDate.setDate(this.fullEndDate.getDate() - 1);
        this.startTime.minute = this.time.minute;
        this.startTime.hour = this.fullEndDate.getHours();
        var ngbDate = NgbDate.from(this.endDate);
        this.selectedStartDate = this.calendar.getPrev(ngbDate, 'd', 1);
      } else {
        tempStartDate.setMinutes(this.startTime.minute);
      }
    } else {
      this.fullStartDate = new Date(tempEndDate);
      this.fullStartDate.setMinutes(this.fullStartDate.getMinutes() - range);
    }

    if (this.fullStartDate.getTime() >= this.fullEndDate.getTime()) {
      let today = new Date();
      alert('You cannot set the start time to be after the end time');
      this.startTime = {
        hour: this.fullEndDate.getHours(),
        minute: this.fullEndDate.getMinutes(),
        second: 0,
      };
      this.fullStartDate = new Date(this.fullEndDate);
      this.fullStartDate.setMinutes(this.fullEndDate.getMinutes() - 15);
      this.startTime.minute = this.fullStartDate.getMinutes();
      if (this.fullEndDate.getMinutes() < 15) {
        this.startTime.hour = this.fullEndDate.getHours() - 1;
      }
      var ngbDate = NgbDate.from(this.endDate);
      this.selectedStartDate = this.calendar.getPrev(
        ngbDate,
        'd',
        0.0104166666666667,
      );
      this.selectedStartDate.month = this.fullEndDate.getMonth() + 1;
      this.selectedStartDate.day = this.fullEndDate.getDate();
      this.selectedStartDate.year = this.fullEndDate.getFullYear();
    }
    var endDate = new Date();
    var range = parseInt(this.selectedTimeRange);
    var startdate = endDate;
    startdate.setMinutes(startdate.getMinutes() - range);
    var data = {
      domain: 'data-lake',
      api: 'get-server-jmx-reports',
      properties: {
        'server-key': this.perfMonSelectedServer,
        'start-time': this.fullStartDate.getTime(),
        'end-time': this.fullEndDate.getTime(),
      },
    };
    var response = await this.apiCallsService.sendApi(data);
    var jvmResp = await this.renderJvmGraph(response.data);
    return response;
  };
  renderJvmGraph = async (param) => {
    this.showJVMCharts = false;
    for (var key in param) {
      switch (key) {
        case 'gc-5-minute':
          this.firstChartData = param[key].chartDataSets;
          this.firstChartLabels = param[key].lineChartLabels;
          for (var i = 0; i < param[key].yAxisCount; i++) {
            this.firstChartOptions.scales.yAxes.push(param[key].yAxes[i]);
          }
          break;
        case 'gc-60-minute':
          this.secondChartData = param[key].chartDataSets;
          this.secondChartLabels = param[key].lineChartLabels;
          for (var i = 0; i < param[key].yAxisCount; i++) {
            this.secondChartOptions.scales.yAxes.push(param[key].yAxes[i]);
          }
          break;
        case 'jvm-mem':
          this.thirdChartData = param[key].chartDataSets;
          this.thirdChartLabels = param[key].lineChartLabels;
          for (var i = 0; i < param[key].yAxisCount; i++) {
            this.thirdChartOptions.scales.yAxes.push(param[key].yAxes[i]);
          }
          break;
        case 'jvm-threads':
          this.fourthChartData = param[key].chartDataSets;
          this.fourthChartLabels = param[key].lineChartLabels;
          for (var i = 0; i < param[key].yAxisCount; i++) {
            this.fourthChartOptions.scales.yAxes.push(param[key].yAxes[i]);
          }
          break;
        default:
          return;
      }
    }
    this.showCharts('JVM');
    return;
  };

  changeGraph = (param) => {
    switch (param) {
      case 'first':
        this.renderJvm = 'first';
        break;
      case 'second':
        this.renderJvm = 'second';
        break;
      case 'third':
        this.renderJvm = 'third';
        break;
      case 'fourth':
        this.renderJvm = 'fourth';
        break;
      default:
        return;
    }
  };

  renderMonitorGraph = async (param) => {
    this.graphLoaded = false;
    this.lineChartData = param.data.chartData;
    this.lineChartLabels = param.data.labels;
    this.serverSelected = true;
    this.graphLoaded = true;
    return true;
  };

  selectConnectIDApi = async () => {
    if (this.perfMonSelectedInstall !== 'Please Select Connect ID') {
      var data = {
        domain: 'data-lake',
        api: 'list-connect-install-servers',
        properties: {
          'connect-id': this.perfMonSelectedInstall[0],
        },
      };
      var response = await this.apiCallsService.sendApi(data);
    } else {
      this.connectIDSelected = false;
    }
    this.connectIDToggle(response.data['server-list']);
  };
  getinstallList = async () => {
    var data = {
      domain: 'data-lake',
      api: 'ccListAccounts',
    };
    var response = await this.apiCallsService.sendApi(data);
    return response;
  };

  connectIDToggle = (param) => {
    var arrList = [];
    for (var i = 0; i < param.length; i++) {
      if (param[i]['is-shared-storage'] == false) {
        arrList.push(param[i]);
      }
    }
    this.serverList = arrList;
    this.perfMonSelectedServer = this.serverList[0]['server-key'];
    this.connectIDSelected = true;
  };

  // Time Component Functions
  sendTime = async () => {
    setTimeout(() => {
      this.sendMonitorAPI();
    }, 100);
  };

  toggleTimeRange = () => {
    if (this.dayToggle == false) {
      this.selectedTimeRange = '30';
    } else {
      var ngbDate = NgbDate.from(this.endDate);
      this.selectedStartDate = this.calendar.getPrev(ngbDate, 'd', 1);
    }
    this.sendMonitorAPI();
  };

  toggleDate = () => {
    if (this.dateToggle == false) {
      this.setTimeNow();
      this.dateDisabled = true;
    } else {
      this.dateDisabled = false;
    }
  };

  setTimeNow = () => {
    var today = new Date();
    this.time = {
      hour: today.getHours(),
      minute: today.getMinutes(),
      second: 0,
    };
    this.endDate = this.calendar.getToday();
    this.sendMonitorAPI();
  };

  // METRICS

  metUpdate = async () => {
    if (window.innerWidth < 960) {
      var elms = document.querySelectorAll('.uk-sortable');
      for (var c: any = 0; c < elms.length; c++) {
        elms[c].classList.add('uk-sortable-nodrag');
      }
    } else {
      var elms = document.querySelectorAll('.uk-sortable');
      for (c = 0; c < elms.length; c++) {
        elms[c].classList.remove('uk-sortable-nodrag');
      }
    }

    var datasets = [{}];
    var labels = [];
    var num: any, rows: any, c: any, o: any, tots: any[], r: any;
    var Names = [];
    var headers = [];
    var installs = JSON.parse(sessionStorage.getItem('installs'));
    const installNames = [];
    const chartName = this.selectedMetricsChart;

    const chartDefs = this.utilsService.chartTypes();
    for (var k in chartDefs) {
      if (chartDefs[k].chartName === chartName) {
        var chartDef = chartDefs[k];
      }
    }
    let jqxr = await this.GetChartData(chartDef, installNames);
    if (chartDef.chartName !== 'HourlyPeak') {
      console.log(jqxr);

      var lastDat = [];
      var datcol = [];
      var subchartindex, subchartName;
      var legend = [];
      var cdat = [];
      var data: any = [];
      data.datasets = [];
      var dataset: any = [];
      var tots = Array(28).fill(0);
      num = 0;
      headers = [];
      var slegend = [];
      // save the legend hidden status
      // for(c=0; c<LineChart.legend.legendItems.length;c++){
      // 	slegend[c]=LineChart.legend.legendItems[c].hidden;
      // }
      for (k in jqxr) {
        // each installation

        labels = [];
        o = jqxr[k];
        if (o) {
          subchartName = this.selectedSubchart; //sessionStorage.getItem("subchartName");
          for (var l = 0; l < chartDef.subcharts.length; l++) {
            // just get the subchart we need
            if (chartDef.subcharts[l].name === subchartName) {
              subchartindex = l;
              break; // we use the value of r below
            }
          }
          if (o == undefined) {
            this.missingData = true;
            continue;
          }
          rows = o.rows;
          headers = o.headers;
          datcol.length = 0;
          legend = [];
          cdat.length = 0;
          dataset.length = 0;

          var offsets = chartDef.subcharts[subchartindex].offsets; // the col num we need

          for (c = 0; c < offsets.length; c++) {
            datcol[c] = this.findCol(headers, offsets[c]);
            if (datcol[c] == -1) {
              continue;
            } else {
              legend[c] = this.toTitleCase(headers[datcol[c]]);
            }
            cdat[c] = [];
          }
          if (subchartName === 'GuestUserAccess') {
            // exception for "GuestUserAccess" chart which has math between datapoints
            for (r in rows) {
              num = rows[r][datcol[0]] - rows[r][datcol[2]] || null;
              if (num !== null && num < 0) {
                num = 0;
              }
              cdat[0].push(num);
              num = rows[r][datcol[1]] || null;
              cdat[1].push(num);
              labels[r] = new Date(rows[r][0]).toLocaleString();
            }
            legend.pop();
            cdat.pop();
            datcol.pop();
          } else {
            for (r in rows) {
              for (c in offsets) {
                num = rows[r][datcol[c]];
                if (datcol[c] == -1) {
                  continue;
                } else {
                  cdat[c].push(num);
                }
              }
              labels[r] = new Date(rows[r][0]).toLocaleString();
            }
          }

          if (chartName === 'MonthlyMetrics') {
            dataset.data = cdat[0];
            var t = ''; // needed to force value not reference
            t = legend[0];
            dataset.label = this.toTitleCase(
              this.perfMonSelectedInstall[1] + ' ' + t,
            );
            var i = parseInt(k) + parseInt(c);
            var target = []; // needed to force value not reference
            Object.assign(target, dataset);
            data.datasets.push(target);
          } else {
            for (c in offsets) {
              dataset.data = cdat[c];
              var t = ''; // needed to force value not reference
              t = legend[c];
              dataset.label = this.toTitleCase(
                this.perfMonSelectedInstall[1] + ' ' + t,
              );
              var i = parseInt(k) + parseInt(c);
              var target = []; // needed to force value not reference
              Object.assign(target, dataset);
              if (dataset.data !== undefined) {
                data.datasets.push(target);
              } else {
                continue;
              }
            }
          }
          Names.push(this.perfMonSelectedInstall[1]);
        } // end if(o.trans  - once through for each install

        // for (r in installs){  // set error messages for servers that have gone away
        // 	if (installs[r].name === installNames[k]){
        // 		installs[r].status = o.messageList[0] ? o.messageList[0].key : o.transactionStatus.toUpperCase();
        // 	}
        // }// for
      } //for
      if (this.missingData == true) {
        this.missingDataToast = true;
        setTimeout(function () {
          this.missingDataToast = false;
        }, 2000);
      }
      data.labels = labels;
      // if (chartName === "HourlyPeak") {
      //   this.barChartOptions.scales.xAxes[0].time.tooltipFormat = "dddd LT"
      // }
      // else {
      //   this.barChartOptions.scales.xAxes[0].time.tooltipFormat = "MMM D Y h:mm a"
      // }
      var labelArr: Array<any> = [];
      var newChartData: Array<any> = [];
      for (var p = 0; p < data.datasets.length; p++) {
        newChartData.push({});
        newChartData[p].data = data.datasets[p].data;
        newChartData[p].label = data.datasets[p].label;
      }

      for (var s = 0; s < newChartData.length; s++) {
        newChartData[s].spanGaps = true;
      }

      for (var p = 0; p < labels.length; p++) {
        var tempDate = new Date(labels[p].toUpperCase());
        labelArr.push(tempDate.toLocaleString('en-US'));
      }

      this.metricsChartLabels = labelArr;
      this.metricsChartData = newChartData;

      var totals = [];
      totals.push(headers);
      totals.push(tots);
      sessionStorage.setItem('totals', JSON.stringify(totals));
      // LineChart.data = data;

      var d: any = [0];
      d[0] = ['Date/Time'];
      d.push(labels);
      for (c = 0; c < data.datasets.length; c++) {
        d[0].push(data.datasets[c].label);
        d.push(data.datasets[c].data);
      }
      sessionStorage.setItem('csvDatasets', JSON.stringify(d));
      this.metricsChartReady = true;

      //debugger

      //updateChart(data.datasets,LineChart,Names);
      //localStorage.setItem("installations",JSON.stringify(installs));
      //	document.getElementById("loadSpinner").classList.add("uk-hidden");
      return datasets;
    } else {
      this.metricsChartData = jqxr;
      this.metricsChartReady = true;
    }
  };

  GetChartData = async (chartDef, installNames) => {
    this.missingData = false;
    var tempEndDate = new Date();
    var tempStartDate = new Date();
    var range = parseInt(this.selectedTimeRange);
    var target;
    tempEndDate.setMonth(this.endDate.month - 1);
    tempEndDate.setDate(this.endDate.day);
    tempEndDate.setFullYear(this.endDate.year);

    if (this.time !== undefined) {
      tempEndDate.setHours(this.time.hour);
      tempEndDate.setMinutes(this.time.minute + 1);
      tempEndDate.setSeconds(this.time.second);
    }
    this.fullEndDate = tempEndDate;
    if (this.dayToggle == true) {
      tempStartDate.setMonth(this.selectedStartDate.month - 1);
      tempStartDate.setDate(this.selectedStartDate.day);
      tempStartDate.setFullYear(this.selectedStartDate.year);
      if (this.startTime !== undefined) {
        tempStartDate.setHours(this.startTime.hour);
        tempStartDate.setMinutes(this.startTime.minute + 1);
        tempStartDate.setSeconds(this.startTime.second);
      }
      this.fullStartDate = tempStartDate;
      if (this.fullStartDate.getTime() == this.fullEndDate.getTime()) {
        this.fullStartDate.setDate(this.fullEndDate.getDate() - 1);
        this.startTime.minute = this.time.minute;
        this.startTime.hour = this.fullEndDate.getHours();
        var ngbDate = NgbDate.from(this.endDate);
        this.selectedStartDate = this.calendar.getPrev(ngbDate, 'd', 1);
      } else {
        tempStartDate.setMinutes(this.startTime.minute);
      }
    } else {
      this.fullStartDate = new Date(tempEndDate);
      this.fullStartDate.setMinutes(this.fullStartDate.getMinutes() - range);
    }

    if (this.fullStartDate.getTime() >= this.fullEndDate.getTime()) {
      let today = new Date();
      alert('You cannot set the start time to be after the end time');
      this.startTime = {
        hour: this.fullEndDate.getHours(),
        minute: this.fullEndDate.getMinutes(),
        second: 0,
      };
      this.fullStartDate = new Date(this.fullEndDate);
      this.fullStartDate.setMinutes(this.fullEndDate.getMinutes() - 15);
      this.startTime.minute = this.fullStartDate.getMinutes();
      if (this.fullEndDate.getMinutes() < 15) {
        this.startTime.hour = this.fullEndDate.getHours() - 1;
      }
      var ngbDate = NgbDate.from(this.endDate);
      this.selectedStartDate = this.calendar.getPrev(
        ngbDate,
        'd',
        0.0104166666666667,
      );
      this.selectedStartDate.month = this.fullEndDate.getMonth() + 1;
      this.selectedStartDate.day = this.fullEndDate.getDate();
      this.selectedStartDate.year = this.fullEndDate.getFullYear();
    }
    var endDate = new Date();
    var range = parseInt(this.selectedTimeRange);
    var startdate = endDate;
    startdate.setMinutes(startdate.getMinutes() - range);
    var sessionkey = '';
    var actives = []; //name,
    var data;
    var qdata = [];
    if (this.selectedMetricsChart == 'PerMinute') {
      this.dayToggle = false;
      data = {
        domain: 'data-lake',
        api: 'getPerMinuteStats',
        properties: {
          'connect-id': this.perfMonSelectedInstall[0],
          beg: this.fullStartDate.getTime(),
          end: this.fullEndDate.getTime(),
        },
      };
    }
    if (this.selectedMetricsChart == 'HourlyPeak') {
      var offsetTime = new Date();
      this.dateToggle = false;
      this.dateDisabled = true;
      this.endDate = this.calendar.getToday();
      data = {
        domain: 'data-lake',
        api: 'hourlyPeakConcurrency',
        properties: {
          'connect-id': this.perfMonSelectedInstall[0],
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          offset: offsetTime.getTimezoneOffset(),
        },
      };
    }
    var response = await this.apiCallsService.sendApi(data);
    if (this.selectedMetricsChart == 'HourlyPeak') {
      for (var p = 0; p < response.data.yAxes.length; p++) {
        if (this.selectedSubchart == response.data.yAxes[p].label) {
          for (var i = 0; i < response.data.yAxes[p].dataSeries.length; i++) {
            qdata.push(response.data.yAxes[p].dataSeries[i]);
          }
        }
      }
      this.metricsChartLabels = response.data.xAxis.labels;
    } else {
      qdata.push(response.data);
    }
    return qdata;
  };

  toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  typeChanged = (titleParam, value) => {
    var title = titleParam;
    var chrtgroup = value;
    var index: any = 0;

    for (var key in this.chartTypes) {
      index = this.chartTypes[key].chartName === chrtgroup ? key : index;
    }
    var chartDef = this.chartTypes[index];
    switch (this.selectedMetricsChart) {
      case 'PerMinute':
        this.selectedSubchart = 'ActiveMeetings';
        break;
      case 'HourlyPeak':
        this.selectedSubchart = 'PeakMeeting';
        break;
      case 'ExtendedHourly':
        this.selectedSubchart = 'MaxConcurrentMeetingUsers';
        break;
      case 'DailyMetrics':
        this.selectedSubchart = 'TotalMeetings';
        break;
      case 'MonthlyMetrics':
        this.selectedSubchart = 'TotalMeetings';
        break;
      default:
      // code block
    }
    this.subtypechanged();
  };

  subtypechanged = () => {
    // var title =document.getElementById("selectChartType").selectedOptions[0].text+
    //  	" / "+document.getElementById("subchoices").selectedOptions[0].text;
    //  document.getElementById("loadCardTitle").innerHTML = title;
    var chrt = this.selectedMetricsChart;
    var subchrt = this.selectedSubchart;

    sessionStorage.setItem('chartName', chrt); // the charttypes record to render
    sessionStorage.setItem('subreportName', subchrt);
    this.metUpdate();
  };

  findCol = (headers, colname) => {
    var col = headers.findIndex(function (header) {
      return colname === header;
    });
    return col;
  };
}
