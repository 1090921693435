<div *ngIf="oneInstall !== true && showBar == true" class="content-card-blue">
  <div
    (click)="toggleInstallSelect('false')"
    style="
      cursor: pointer;
      color: white;
      position: absolute;
      top: 10px;
      right: 10px;
    "
    class="hide-installbar"
  >
    <mat-icon
      style="cursor: pointer; width: 30px; height: 30px; font-size: 30px"
      >expand_less</mat-icon
    >
  </div>
  <p
    style="
      color: white;
      position: relative;
      top: -15px;
      right: 10px;
      margin-bottom: 0;
      font-size: 18px;
    "
  >
    <span
      ><mat-icon
        style="
          height: 40px;
          width: 40px;
          font-size: 40px;
          margin-right: 15px;
          position: relative;
          top: 14px;
        "
        >dvr</mat-icon
      ></span
    >Please select installations to view on the chart below:
  </p>
  <div style="overflow: auto">
    <div
      class="install-selections"
      *ngFor="let install of installationChecks; let i = index"
    >
      <div *ngIf="i == 0">
        <label for="{{ install.name }}">{{ install.name }}</label>
        <input
          type="checkbox"
          name="{{ install.name }}"
          [checked]="install.checked == true"
          (change)="installChecked(install, $event.target.checked)"
        />
      </div>
      <div *ngIf="0 < i">
        <label for="{{ install.name }}">{{ install.name }}</label>
        <input
          type="checkbox"
          name="{{ install.name }}"
          [checked]="install.checked == true ? true : false"
          (change)="installChecked(install, $event.target.checked)"
        />
      </div>
    </div>
  </div>
</div>
<div style="text-align: right" class="no-bar" *ngIf="showBar == false">
  <button (click)="toggleInstallSelect('true')" class="btn btn-primary">
    Show Install Select
  </button>
</div>
<div class="content-card dashboard-card">
  <h2>
    Current System Activity - Live
    <span id="dashDate">{{ todayDate | date: "fullDate" }}</span>
  </h2>
  <ngb-tabset style="margin-bottom: 10px" [destroyOnHide]="false">
    <ngb-tab>
      <ng-template ngbTabTitle>
        <div style="padding: 10px 15px" (click)="getChartData('Meetings')">
          MEETINGS
        </div>
      </ng-template>
      <ng-template ngbTabContent>
        <div
          *ngIf="meetingsChartReady == true"
          class="col-sm-12 form-group"
          style="display: block; height: 50vh"
        >
          <canvas
            id="meetings"
            baseChart
            [datasets]="meetingChartData"
            [labels]="meetingLabels"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [plugins]="lineChartPlugins"
          >
          </canvas>
        </div>
      </ng-template>
      <div class="underline"></div>
    </ngb-tab>
    <ngb-tab>
      <ng-template ngbTabTitle>
        <div style="padding: 10px 15px" (click)="getChartData('Users')">
          USERS
        </div>
      </ng-template>
      <ng-template ngbTabContent>
        <div
          *ngIf="usersChartReady == true"
          class="col-sm-12 form-group"
          style="display: block; height: 50vh"
        >
          <canvas
            id="meetings"
            baseChart
            [datasets]="usersChartData"
            [labels]="usersLabels"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [plugins]="lineChartPlugins"
          >
          </canvas>
        </div>
      </ng-template>
    </ngb-tab>
    <!-- <ngb-tab>
      <ng-template ngbTabTitle>
        <div style="padding: 10px 15px" (click)="getChartData('Content')">CONTENT</div>
      </ng-template>
      <ng-template ngbTabContent>
        <div *ngIf="contentChartReady == true" class="col-sm-12 form-group" style="display: block; height:50vh;">
          <canvas id="meetings" baseChart [datasets]="contentChartData" [labels]="contentLabels"
            [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
            [chartType]="lineChartType" [plugins]="lineChartPlugins">
          </canvas>
        </div>
      </ng-template>
    </ngb-tab> -->
  </ngb-tabset>
  <p style="color: #666; font-size: 0.876rem; font-weight: bold">
    Modify Minutes
  </p>
  <!-- <ng5-slider  [(value)]="sliderVal" [options]="sliderOptions"></ng5-slider> -->
  <select
    [(ngModel)]="sliderVal"
    (change)="sliderChange()"
    class="form-control"
  >
    <option selected value="15">15</option>
    <option value="30">30</option>
    <option value="45">45</option>
    <option value="60">60</option>
  </select>
</div>
<div class="content-card">
  <h2>Current System Activity - Summary</h2>
  <div class="row">
    <div style="margin-bottom: 15px" class="col-md-12 col-lg-6 col-xl-4">
      <div id="user-total" class="col-12">
        <div class="row">
          <div class="col-4 icon-col">
            <img
              style="margin-top: 23px; width: 110px; height: 120px"
              src="../../../assets/images/icon_total_users.svg"
            />
          </div>
          <div style="margin-top: 25px" class="col-8">
            <span class="summary-numbers">{{ userTot || "0" }}</span>
            <span style="display: block; text-align: center">TOTAL USERS</span>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 15px" class="col-md-12 col-lg-6 col-xl-4">
      <div id="content-total" class="col-12">
        <div class="row">
          <p style="margin: 0; margin-top: 5px" class="col-12">
            <span
              style="display: inline; text-align: left; margin-right: 20px"
              class="summary-numbers"
              >{{ userTot - contentTot || "0" }}</span
            >
            <span style="display: inline-block; position: relative; top: -10px"
              >LIVE USERS</span
            >
          </p>
          <div class="col-12">
            <hr style="margin: 0; border-color: rgba(255, 255, 255, 0.2)" />
          </div>
          <p style="margin: 0" class="col-12">
            <span
              style="display: inline; text-align: left; margin-right: 20px"
              class="summary-numbers"
              >{{ contentTot || "0" }}</span
            >
            <span style="display: inline-block; position: relative; top: -10px"
              >ON-DEMAND USERS</span
            >
          </p>
        </div>
      </div>
    </div>
    <div style="margin-bottom: 15px" class="col-md-12 col-lg-6 col-xl-4">
      <div id="meeting-total" class="col-12">
        <div class="row">
          <div class="col-4 icon-col">
            <img
              style="margin-top: 35px; height: 90px"
              src="../../../assets/images/icon_live_rooms.svg"
            />
          </div>
          <div style="margin-top: 25px" class="col-8">
            <span class="summary-numbers">{{ meetingTot || "0" }}</span>
            <span style="display: block; text-align: center"
              >LIVE MEETINGS</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="dash-ticket">
  <app-tech-support></app-tech-support>
</div>
