import { ValueConverter } from '@angular/compiler/src/render3/view/template';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert.service.service';
import { ApiCallsService } from 'src/app/shared/services/api-calls.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-edit-ticket',
  templateUrl: './edit-ticket.component.html',
  styleUrls: ['./edit-ticket.component.scss'],
})
export class EditTicketComponent implements OnInit {
  editFlag: boolean = null;
  edit_comment: any;
  delete_comment: any;
  editedComment: string;
  isLoading: boolean;
  isTicketEditorDelete: any;
  lastModifieddate: any;
  constructor(
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
  ) {
    this.utilsService.getSalesforceProduct();
  }

  public ticketFromParam: any = undefined;
  filesToUpload: Array<any> = [];
  ticketID;
  sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
  ticketNew;
  commentToast = false;
  commentdelete = false;
  insightsUrl = this.apiCallsService.urlUtil();
  domain = this.apiCallsService.domainUtil();
  seshKey = this.apiCallsService.seshKeyUtil();
  renderForm;
  newTicketAuthor = sessionStorage.getItem('fullname');
  options = this.utilsService.SalesForceOptions();
  selectStatus = 'New';
  selectPriority = 'P4 - Low';
  selectType = 'Issue';
  inputComment;
  updateComment;
  IsPublished: boolean = true;
  selectReporter = sessionStorage.getItem('username');
  statusArr = [];
  priorityArr = [];
  reporterArr = [];
  typeArr = [];
  inputURL;
  inputSubject;
  inputDesc;
  suppliedCompay = '';
  suppliedURL = '';
  selectProduct = 'Adobe Connect';
  commentBody;
  commentsArr = [];
  attachmentArr = [];
  createdName;
  ticketSubject;
  lastModifiedName;
  ticketNumber;
  renderTicket = false;
  renderNewTicket = false;

  async ngOnInit(): Promise<any> {
    setTimeout(() => {
      this.options = this.utilsService.SalesForceOptions();
      for (var key in this.options.statusMap) {
        for (var m = 0; m < this.options.statusMap[key].length; m++) {
          this.statusArr.push(this.options.statusMap[key][m]);
        }
      }
    }, 1000);
    // for (var key in this.options.statusMap) {
    //   for (var m = 0; m < this.options.statusMap[key].length; m++) {
    //     this.statusArr.push(this.options.statusMap[key][m])
    //   }
    // }
    this.ticketFromParam = this.route.snapshot.paramMap.get('id');
    if (this.ticketFromParam == null) {
      this.renderNewTicket = true;
    }
    if (this.ticketFromParam !== null) {
      this.ticketNew = false;

      var data = {
        domain: this.domain,
        api: 'support.getTickets',
        username: sessionStorage.getItem('email'),
        sessionKeys: {
          smqcc: this.sessionKeys.smqcc,
          sjccc: this.sessionKeys.sjccc,
        },
        'session-key': this.seshKey,
        filtering: {
          state: 'ALL',
          id: this.ticketFromParam,
        },
      };
      var ticketResponse = await this.apiCallsService.sendApi(data);
      if (ticketResponse.status == 'ok') {
        this.UpdateDOM(ticketResponse.response.records[0]);
        this.renderTicket = true;
      }
    } else {
      this.ticketNew = true;
      var n = ['new'];
    }
    // 		let dropArea = document.getElementById("drop-area")
    // 				// Prevent default drag behaviors
    // 		;['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
    // 		  document.getElementById("drop-area").addEventListener(eventName, this.preventDefaults, false)
    // 		  document.body.addEventListener(eventName, this.preventDefaults, false)
    // 		})

    // 		// Highlight drop area when item is dragged over it
    // 		;['dragenter', 'dragover'].forEach(eventName => {
    // 		  document.getElementById("drop-area").addEventListener(eventName, this.highlight, false)
    // 		})

    // 		;['dragleave', 'drop'].forEach(eventName => {
    // 		 document.getElementById("drop-area").addEventListener(eventName, this.unhighlight, false)
    // 		})
    // // Handle dropped files
    // 		document.getElementById("drop-area").addEventListener('drop', this.handleDrop, false)
  }

  TicketRequest = async (type, params) => {
    // wrapper for fetch
    var sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
    var data: any = [];
    if (
      this.inputDesc == '' ||
      this.inputDesc == undefined ||
      this.inputDesc == null
    ) {
      this.alertService.showAlert('You Must Enter Description.', 'error');
      this.renderTicket = true;
      return;
    }
    if (
      this.inputSubject == '' ||
      this.inputSubject == undefined ||
      this.inputSubject == null
    ) {
      this.alertService.showAlert('You Must Enter Subject.', 'error');
      this.renderTicket = true;
      return;
    }
    switch (type) {
      case 'UpdateTicket':
        data = {
          api: 'support.updateTicket',
          username: sessionStorage.getItem('username'),
          sessionKeys: {
            smqcc: sessionKeys.smqcc,
            sjccc: sessionKeys.sjccc,
          },
          id: this.ticketFromParam,
          suppliedEmail: this.selectReporter,
          type: this.selectType,
          origin: 'Web',
          // "reason": document.getElementById("loadReason").value,
          status: this.selectStatus,
          subject: this.inputSubject,
          priority: this.selectPriority,
          description: this.inputDesc,
          suppliedCompany: this.suppliedCompay,
          product: this.selectProduct,
          URL: this.inputURL,
        };
        break;

      case 'AddTicket':
        data = {
          api: 'support.addTicket',
          username: sessionStorage.getItem('username'),
          sessionKeys: {
            smqcc: sessionKeys.smqcc,
            sjccc: sessionKeys.sjccc,
          },
          type: this.selectType,
          origin: 'Web',
          // "reason": document.getElementById("loadReason").value,
          status: this.selectStatus,
          subject: this.inputSubject,
          priority: this.selectPriority,
          description: this.inputDesc,
          suppliedEmail: this.selectReporter,
          suppliedCompany: this.suppliedCompay,
          product: this.selectProduct,
          URL: this.inputURL,
        };
        break;

      case 'AddAttachment':
        data = {
          api: 'support.addAttachment',
          username: sessionStorage.getItem('username'),
          sessionKeys: {
            smqcc: sessionKeys.smqcc,
            sjccc: sessionKeys.sjccc,
          },
          parentId: params.id,
          name: params.name,
          description: params.description,
          contentType: params.contentType,
          body: params.base64,
        };
        break;

      /*
        case "UpdateAttachment":
          data = {
            "domain": "insights",
            "api": "support.updateAttachment",
            "username": sessionStorage.getItem("username"),
            "sessionKeys": {
              "smqcc": sessionKeys.smqcc,
              "sjccc": sessionKeys.sjccc
            },
            "id": "ATTACHMENT_ID",
            "name": "FILE_NAME",
            "description": "String",
            "contentType":  "MIME_CONTENT_TYPE"
          }
          break;
      */

      case 'GetAttachment':
        data = {
          api: 'support.getAttachment',
          username: sessionStorage.getItem('username'),
          sessionKeys: {
            smqcc: sessionKeys.smqcc,
            sjccc: sessionKeys.sjccc,
          },
          id: params.id,
        };
        break;

      case 'DeleteAttachment':
        data = {
          api: 'support.deleteAttachment',
          username: sessionStorage.getItem('username'),
          sessionKeys: {
            smqcc: sessionKeys.smqcc,
            sjccc: sessionKeys.sjccc,
          },
          id: params,
        };
        break;

      //customer attachment coso support attachments

      case 'AddComment':
        data = {
          api: 'support.addComment',
          username: sessionStorage.getItem('username'),
          sessionKeys: {
            smqcc: sessionKeys.smqcc,
            sjccc: sessionKeys.sjccc,
          },
          parentId: this.ticketFromParam,
          commentBody: this.inputComment,
        };
        break;

      // case "UpdateComment":
      //   data = {
      //     "domain": "insights",
      //     "api": "support.updateComment",
      //     "username": sessionStorage.getItem("username"),
      //     "sessionKeys": {
      //       "smqcc": sessionKeys.smqcc,
      //       "sjccc": sessionKeys.sjccc
      //     },
      //     "id": "COMMENT_ID",
      //     "commentBody":  "String"
      //   }
      //   break;

      case 'DeleteComment':
        data = {
          domain: 'insights',
          api: 'support.deleteComment',
          username: sessionStorage.getItem('username'),
          sessionKeys: {
            smqcc: sessionKeys.smqcc,
            sjccc: sessionKeys.sjccc,
          },
          id: 'COMMENT_ID',
        };
        break;

      default:
        break;
    } // end switch

    var response = await this.apiCallsService.sendApi(data);
    return response;
  };

  handleFiles = async (e) => {
    var files = e.currentTarget.files;
    // debugger
    if (files.length === undefined) {
      files = files.target.files;
    }

    for (var c = 0; c < files.length; c++) {
      var checkFile = files[c];
      checkFile.base64 = await this.getBase64(checkFile);
      var duplicateFlag = false;
      for (var g = 0; g < this.filesToUpload.length; g++) {
        if (
          this.filesToUpload[g].base64 == checkFile.base64 &&
          this.filesToUpload[g].name == checkFile.name
        ) {
          duplicateFlag = true;
          break;
        }
      }
      if (!duplicateFlag) {
        this.filesToUpload.push(checkFile);
        this.previewFile(checkFile);
      }
    }
    //initializeProgress(files.length)
  };

  previewFile = (file) => {
    let reader = new FileReader();
    var fileType = file.type;
    var typePos = fileType.indexOf('image/');
    reader.readAsDataURL(file);
    if (typePos == -1) {
      reader.onloadend = function () {
        let img = document.createElement('img');
        img.src = '/assets/images/doc-icon.png';
        img.style.maxWidth = '60px';
        img.style.maxHeight = '60px';
        document.getElementById('gallery').appendChild(img);
      };
    } else {
      reader.onloadend = function () {
        let img: any = document.createElement('img');
        img.src = reader.result;
        img.style.maxWidth = '60px';
        img.style.maxHeight = '60px';
        document.getElementById('gallery').appendChild(img);
      };
    }
  };

  commentUpdate = async () => {
    var sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
    var data = {
      domain: this.domain,
      api: 'support.getTickets',
      username: sessionStorage.getItem('email'),
      sessionKeys: {
        smqcc: sessionKeys.smqcc,
        sjccc: sessionKeys.sjccc,
      },
      filtering: {
        state: 'ALL',
        id: this.ticketID,
      },
    };
    var response = await this.apiCallsService.sendApi(data);
    return response;
  };

  editComment = async (data: any) => {
    this.IsPublished = data.published;
    this.editFlag = null;
    // this.edit_comment = data;
    // this.ngOnInit()
    this.edit_comment = data;
    this.inputComment = '';
    // var edit_comment = await this.commentUpdate();
    // this.commentsArr = edit_comment.response.records[0].comments;
  };

  onIsPublishedChange(event: any) {
    this.IsPublished = event.target.checked;
  }

  saveEditComment(flag: any) {
    if (flag == 'cancel') {
      this.editFlag = null;
    } else if (flag == 'save') {
      this.renderNewTicket = false;
      this.renderTicket = false;
      let val = (<HTMLInputElement>document.getElementById('editCommentValue'))
        .value;
      var sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
      var data = {
        domain: this.domain,
        api: 'support.updateComment',
        id: this.edit_comment.id,
        commentBody: val,
        IsPublished: this.IsPublished,
      };
      this.apiCallsService.sendApi(data).then((response) => {
        this.editFlag = null;
        this.commentToast = true;
        setTimeout(() => {
          this.commentToast = false;
        }, 2000);
        this.commentUpdate().then((updatedComments) => {
          this.commentsArr = updatedComments.response.records[0].comments;
          // this.alertService.showAlert("Edit Ticket has been successfully.", "success");
        });
        this.renderTicket = true;
      });
    }
  }

  deleteComment(comment: any) {
    this.isLoading = true;

    this.delete_comment = comment;
    var sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
    var data = {
      domain: this.domain,
      api: 'support.deleteComment',
      id: this.delete_comment.id,
    };

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        var response = this.apiCallsService.sendApi(data);
        response
          .then(() => {
            // Filter out the deleted comment from the comments array
            this.commentsArr = this.commentsArr.filter(
              (c: any) => c.id !== this.delete_comment.id,
            );
            Swal.fire('Deleted!', 'Your comment has been deleted.', 'success');
            this.commentdelete = true;
            setTimeout(() => {
              this.commentdelete = false;
            }, 2);
          })
          .catch((error: any) => {
            console.error('Error deleting comment:', error);
            Swal.fire('Error!', 'Failed to delete the comment.', 'error');
          });
      }
    });
  }

  commentAdded = async () => {
    this.commentToast = true;
    this.inputComment = '';
    var commentAdded = await this.commentUpdate();
    setTimeout(() => {
      this.commentToast = false;
    }, 2000);
    this.commentsArr = commentAdded.response.records[0].comments;
  };

  addSaveComment = async () => {
    try {
      // make sure the ticket is saved first !!!
      var params = {};
      var jqxr = await this.TicketRequest('AddComment', params);
      //window.location.href = window.location.href +"&3";
    } catch (err) {
      alert(err);
    } finally {
      this.inputComment;
      this.commentAdded();
    }
  };

  getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  UpdateDOM = async (param) => {
    console.log(param);
    this.isTicketEditorDelete = param.isTicketEditorDelete;
    this.lastModifiedName = param.lastModifiedByName;
    this.lastModifieddate = param.lastModifiedDate;
    console.log('lastModifiedName', this.lastModifiedName);
    this.ticketNumber = parseInt(param.caseNumber);
    this.createdName = param.suppliedName;
    this.ticketID = param.id;
    this.ticketSubject = param.subject;
    this.selectStatus = param.status;
    this.selectPriority = param.priority;
    this.selectType = param.type;
    this.selectReporter = param.suppliedEmail;
    this.inputURL = param.URL;
    this.inputSubject = param.subject;
    this.inputDesc = param.description;
    this.selectProduct = param.product;
    this.commentsArr = param.comments;
    this.suppliedCompay = param.suppliedCompany;
    this.renderAttachments(param);
  };

  renderAttachments = async (param) => {
    if (param.attachments.length !== 0) {
      var attIncrement = 1;
      for (var key in param.attachments) {
        var obj = param.attachments[key];
        var id = obj.id;
        var data = {
          api: 'support.getAttachment',
          username: sessionStorage.getItem('username'),
          sessionKeys: {
            smqcc: this.sessionKeys.smqcc,
            sjccc: this.sessionKeys.sjccc,
          },
          id: id,
        };
        var attachmentRes = await this.fetchAttachments(data);
        this.attachmentArr.push(attachmentRes.response);
      }
    }
  };

  fetchAttachments = async (data) => {
    var response = await this.apiCallsService.sendApi(data);
    return response;
  };

  downloadAttachment(attachment: any) {
    const link = document.createElement('a');
    link.href = `data:${attachment.contentType};base64,${attachment.body}`;
    link.download = attachment.name;
    link.click();
  }

  deleteAttachment(index: number) {
    this.delete_comment = Comment;

    var sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));
    var data = {
      api: 'support.deleteAttachment',
      username: sessionStorage.getItem('username'),
      sessionKeys: {
        smqcc: sessionKeys.smqcc,
        sjccc: sessionKeys.sjccc,
      },
      id: this.attachmentArr[index].id, // Specify the attachment ID
    };

    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        this.apiCallsService.sendApi(data).then(
          (response: any) => {
            // Handle success response
            this.attachmentArr.splice(index, 1); // Remove the attachment from the array
            Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
          },
          (error: any) => {
            // Handle error response
            console.error('Error deleting attachment:', error);
            Swal.fire('Error!', 'Failed to delete the file.', 'error');
          },
        );
      }
    });
  }

  addSaveTicket = async () => {
    var params: any = {};
    this.isLoading = true;
    this.renderNewTicket = false;
    this.renderTicket = false;
    let response;
    if (this.ticketFromParam !== null) {
      try {
        if (this.filesToUpload.length > 0) {
          for (var c = 0; c < this.filesToUpload.length; c++) {
            params.id = this.ticketFromParam;
            params.url = this.insightsUrl;
            params.name = this.filesToUpload[c].name;
            params.base64 = this.filesToUpload[c].base64.split(',')[1];
            params.contentType = this.filesToUpload[c].type;
            params.description = this.filesToUpload[c].name;
            try {
              var jqxr = await this.TicketRequest('AddAttachment', params);
            } catch (err) {
              // alert("There was an error uploading your attachment. The request either timed out due to size, or the size of the file exceeded the max. The rest of your ticket will be updated and you will be redirected."); // TypeError: failed to fetch
              this.alertService.showAlert(
                'There was an error uploading your attachment. The request either timed out due to size, or the size of the file exceeded the max. The rest of your ticket will be updated and you will be redirected.',
                'error',
              );
            }
          }
        }
        this.alertService.showAlert(
          'Ticket Is Update Successfully.',
          'success',
        );
        this.renderNewTicket = false;
        var updateResponse = await this.TicketRequest('UpdateTicket', params);
      } catch (err) {
        console.log(err);
      } finally {
        if (updateResponse.response.errorCode) {
          if (updateResponse.response.errorCode == 'INVALID_FIELD') {
          } else {
            this.redirectSuccess();
          }
        } else if (updateResponse.status === 'error') {
        } else {
          this.redirectSuccess();
        }
      }
    } else {
      try {
        response = await this.TicketRequest('AddTicket', params);
        let data = response;

        if (data == false) {
          return;
        }

        if (this.filesToUpload.length > 0) {
          for (var c = 0; c < this.filesToUpload.length; c++) {
            params.id = data.response.id;
            params.url = this.insightsUrl;
            params.name = this.filesToUpload[c].name;
            params.base64 = this.filesToUpload[c].base64.split(',')[1];
            params.contentType = this.filesToUpload[c].type;
            params.description = this.filesToUpload[c].name;
            try {
              var jqxr = await this.TicketRequest('AddAttachment', params);
            } catch (err) {
              if (err == 'TypeError: Failed to fetch') {
              } else {
                alert(err); // TypeError: failed to fetch
              }
            }
          }
        }
        this.redirectSuccess();
      } catch (err) {
        if (err == 'TypeError: Failed to fetch') {
        } else {
          alert(err);
        }
      }
      setTimeout(() => {
        this.isLoading = false;
        this.renderNewTicket = false;
      }, 2000);
    }
  };

  preventDefaults = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  highlight = (e) => {
    document.getElementById('drop-area').classList.add('highlight');
  };

  unhighlight = (e) => {
    document.getElementById('drop-area').classList.remove('active');
  };

  handleDrop = (e) => {
    var dt = e.dataTransfer;
    var files = dt.files;

    this.handleFiles(files);
  };

  redirectSuccess = () => {
    this.router.navigate(['/dashboard']);
  };
}
