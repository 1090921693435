import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Component, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, of, Subscription } from 'rxjs';
import {
  tap,
  startWith,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map,
} from 'rxjs/operators';
import {
  CanActivate,
  Router,
  RouterStateSnapshot,
  ActivatedRouteSnapshot,
} from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiCallsService {
  url: string;
  apiDomain: string;
  opts = [];
  constructor(
    private router: Router,
    private http: HttpClient,
  ) {}

  loginApi = async (data) => {
    if (data.domain !== 'data-lake') {
      data.domain = this.domainUtil();
    } else {
      data.domain = 'data-lake';
    }
    var url = this.urlUtil();
    var dataPackage = {
      domain: data.domain,
      api: 'authentication.login',
      username: data.username,
      password: data.password,
    };
    return fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(dataPackage), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json());
  };

  logoutApi = async (data) => {
    if (data.domain !== 'data-lake') {
      data.domain = this.domainUtil();
    } else {
      data.domain = 'data-lake';
    }
    var sessionKey = this.seshKeyUtil();
    var url = this.urlUtil();
    var dataPackage = {
      domain: data.domain,
      api: 'authentication.logout',
      username: sessionStorage.getItem('email'),
      'session-key': sessionKey,
      sessionKeys: {
        smqcc: data.keysmqcc,
        sjccc: data.keysjccc,
      },
    };
    return fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(dataPackage), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json());
  };

  getData(data) {
    if (data.domain !== 'data-lake') {
      data.domain = this.domainUtil();
    } else {
      data.domain = 'data-lake';
    }
    var sessionKey = this.seshKeyUtil();
    data['session-key'] = sessionKey;
    var url = this.urlUtil();
    return this.opts.length
      ? of(this.opts)
      : this.http
          .post<any>(this.url, data)
          .pipe(tap((data) => (this.opts = data)));
  }

  // supportApi = async (data) => {
  //   var url = this.urlUtil();
  // }

  sendApi = async (data) => {
    if (data.domain !== 'data-lake') {
      data.domain = this.domainUtil();
    } else {
      data.domain = 'data-lake';
    }
    if (data.data) {
      console.log('forgot password');
      data.data['session-key'] = this.seshKeyUtil();
      if (data.data['connect-id'] == true) {
        data.data['connect-id'] = this.fetchSelectedInstall();
      }
    }
    if (
      !data.properties &&
      data.api !== 'authentication.requestResetPasswordEmail' &&
      data.api !== 'authentication.checkduoauth'
    ) {
      data.properties = {};
      data.properties['session-key'] = this.seshKeyUtil();
      data['session-key'] = this.seshKeyUtil();
    } else if (data.api !== 'authentication.requestResetPasswordEmail') {
      data.properties['session-key'] = this.seshKeyUtil();
      data['session-key'] = this.seshKeyUtil();
      if (data.properties['connect-id'] == true) {
        data.properties['connect-id'] = this.fetchSelectedInstall();
      }
    }
    if (data.baseUrl == true) {
      if (window.location.origin === environment.frontendBaseURL) {
        // data.baseUrl = "http://insights-beta.eng.cosocloud.com:8080";
        // data.baseUrl = "https://insights.cosocloud.com"
        data.baseUrl = environment.baseUrl;
        // data.baseUrl = "http://192.168.0.106:5000";
      } else {
        data.baseUrl = window.location.origin;
      }
    }
    var url = this.urlUtil();
    return fetch(url, {
      method: 'POST', // or 'PUT'
      body: JSON.stringify(data), // data can be `string` or {object}!
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(async (response) => {
      if (response.ok) {
        var authCheck = await response.json();
        if (authCheck.status == 'ERROR_NOT_AUTHENTICATED') {
          this.router.navigate(['/login']);
        } else if (authCheck.status == 'SECURITY_EXCEPTION') {
          if (authCheck['status-detail'] == 'no session') {
            alert('Your session has expired');
            this.router.navigate(['/login']);
          } else {
            return authCheck;
          }
        } else if (authCheck.data !== undefined) {
          if (authCheck.properties !== undefined) {
            if (authCheck.properties['download-key'] !== undefined) {
              this.downloadRedirect(authCheck.properties['download-key']);
            } else {
              return authCheck;
            }
          } else {
            return authCheck;
          }
        } else {
          return authCheck;
        }
      } else {
        throw new Error("Server response wasn't OK");
      }
    });
  };

  downloadRedirect = (param) => {
    var baseURL = this.urlUtil();
    var downloadURL = baseURL + '/download/' + param;
    window.open(downloadURL);
  };

  urlUtil = () => {
    var baseUrl = window.location.origin;
    if (environment.frontendBaseURL === baseUrl) {
      baseUrl = environment.baseUrl;
    }
    var url = baseUrl + '/api';
    return url;
  };
  domainUtil = () => {
    var apiDomain;
    var domain = window.location.hostname.split('.');
    if (domain[0] === 'insights-beta') {
      apiDomain = 'insights';
    } else if (domain[0] === 'localhost') {
      apiDomain = 'insights';
    } else {
      apiDomain = 'insights';
    }
    return apiDomain;
  };
  seshKeyUtil = () => {
    var seshkeyraw = sessionStorage.getItem('session-key');
    return seshkeyraw;
  };
  fetchSelectedInstall = () => {
    var connectId = localStorage.getItem('selectedInstall');
    return connectId;
  };
}
