<div class="content-card">
  <div class="form-group">
    <div class="row">
      <div class="col-sm-3">
        <label for="connectID">Select Install</label>
        <select
          *ngIf="renderDrop == true"
          class="form-control"
          id="type"
          (change)="selectConnectIDApi()"
          [(ngModel)]="logsSelectedInstall"
          name="connectID"
        >
          <option selected>Please Select Connect ID</option>
          <option
            *ngFor="let install of installList.data; let i = index"
            [ngValue]="install.connect_id"
          >
            {{ install.account_name }}
          </option>
        </select>
      </div>
      <div
        *ngIf="
          connectIDSelected == true &&
          logsSelectedLogType !== 'application' &&
          logsSelectedLogType !== 'meeting'
        "
        class="col-sm-3 offset-sm-1"
      >
        <label for="server">Select Server</label>
        <select
          [(ngModel)]="logsSelectedServer"
          class="form-control"
          name="server"
        >
          <option
            *ngFor="let server of serverList; let i = index"
            [ngValue]="server['server-key']"
          >
            {{ server["machine-name"] }}
          </option>
        </select>
      </div>
      <div class="col-sm-3 offset-sm-1">
        <label
          *ngIf="logsSelectedServer && logsSelectedServer !== ''"
          for="logType"
          >Select Log Type</label
        >
        <select
          name="logType"
          *ngIf="logsSelectedServer && logsSelectedServer !== ''"
          (change)="getMeetings()"
          [(ngModel)]="logsSelectedLogType"
          class="form-control"
        >
          <option value="access" selected>Access</option>
          <option value="agent">Agent</option>
          <option value="application">Application</option>
          <option value="debug">Debug</option>
          <option value="diagnostic">Diagnostic</option>
          <option value="error">Error</option>
          <option value="gc">GC</option>
          <option value="meeting">Meeting</option>
          <option value="telephony">Telephony</option>
          <option value="configArchive">Config Archive</option>
        </select>
      </div>
    </div>
    <div *ngIf="logsSelectedLogType == 'agent'" class="agentControls">
      <label for="pastDays">Days Ago</label>
      <input
        class="form-control"
        type="text"
        placeholder="0"
        [(ngModel)]="agentPastDays"
        name="pastDays"
      />
    </div>
    <div *ngIf="logsSelectedLogType == 'debug'" class="debugControls">
      <form class="form-inline">
        <div class="form-group hidden">
          <div class="input-group">
            <input
              [disabled]="debugDateDisabled == true"
              name="datepicker"
              class="form-control"
              placeholder="yyyy-mm-dd"
              ngbDatepicker
              #datepicker="ngbDatepicker"
              [startDate]="prevDateObj"
              [maxDate]="startDateObj"
              [autoClose]="'outside'"
              (dateSelect)="onDateSelection($event)"
              [displayMonths]="2"
              [dayTemplate]="t"
              outsideDays="hidden"
            />
            <ng-template #t let-date let-focused="focused">
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input
              [disabled]="debugDateDisabled == true"
              #dpFromDate
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dpFromDate"
              [value]="formatter.format(fromDate)"
              (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
            />
            <div class="input-group-append">
              <button
                [disabled]="debugDateDisabled == true"
                class="btn btn-outline-secondary calendar"
                (click)="datepicker.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
        <div class="form-group ml-2">
          <div class="input-group">
            <input
              [disabled]="debugDateDisabled == true"
              #dpToDate
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dpToDate"
              [value]="formatter.format(toDate)"
              (input)="toDate = validateInput(toDate, dpToDate.value)"
            />
            <div class="input-group-append">
              <button
                [disabled]="debugDateDisabled == true"
                class="btn btn-outline-secondary calendar"
                (click)="datepicker.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
        <label for="debugMin">Past Minutes</label>
        <input
          class="form-control"
          (change)="checkPastMin()"
          [(ngModel)]="debugPastMin"
          type="text"
          placeholder="0"
          name="debugMin"
        />
      </form>
    </div>
    <div *ngIf="logsSelectedLogType == 'gc'" class="debugControls">
      <div class="row">
        <div class="col-sm-3">
          <label for="archive">Archive</label>
          <input [(ngModel)]="archiveBool" type="checkbox" name="archive" />
        </div>
      </div>
    </div>
    <div *ngIf="logsSelectedLogType == 'telephony'" class="debugControls">
      <form class="form-inline">
        <div class="form-group hidden">
          <div class="input-group">
            <input
              [disabled]="telephonyDateDisabled == true"
              name="datepicker"
              class="form-control"
              ngbDatepicker
              #datepicker="ngbDatepicker"
              [autoClose]="'outside'"
              [startDate]="prevDateObj"
              [maxDate]="startDateObj"
              (dateSelect)="onDateSelection($event)"
              [displayMonths]="2"
              [dayTemplate]="t"
              outsideDays="hidden"
              [startDate]="fromDate!"
            />
            <ng-template #t let-date let-focused="focused">
              <span
                class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null"
              >
                {{ date.day }}
              </span>
            </ng-template>
          </div>
        </div>
        <div class="form-group">
          <div class="input-group">
            <input
              [disabled]="telephonyDateDisabled == true"
              #dpFromDate
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dpFromDate"
              [value]="formatter.format(fromDate)"
              (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
            />
            <div class="input-group-append">
              <button
                [disabled]="telephonyDateDisabled == true"
                class="btn btn-outline-secondary calendar"
                (click)="datepicker.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
        <div class="form-group ml-2">
          <div class="input-group">
            <input
              [disabled]="telephonyDateDisabled == true"
              #dpToDate
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dpToDate"
              [value]="formatter.format(toDate)"
              (input)="toDate = validateInput(toDate, dpToDate.value)"
            />
            <div class="input-group-append">
              <button
                [disabled]="telephonyDateDisabled == true"
                class="btn btn-outline-secondary calendar"
                (click)="datepicker.toggle()"
                type="button"
              ></button>
            </div>
          </div>
        </div>
        <label for="telephonyMin">Past Minutes</label>
        <input
          class="form-control"
          (change)="checkPastMin()"
          [(ngModel)]="telephonyPastMin"
          type="text"
          placeholder="0"
          name="telephonyMin"
        />
      </form>
    </div>
    <div
      class="ticket-loader"
      *ngIf="
        (logsSelectedLogType == 'application' && !meetingTable) ||
        (logsSelectedLogType == 'meeting' && !meetingTable)
      "
    >
      <h2>Please Wait -- Meetings are Loading</h2>
      <div class="progress-bar">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </div>
    <div *ngIf="meetingTable" class="meetingsTable">
      <h3>Select a meeting</h3>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">{{ meetingTableHeads[0] }}</th>
            <th scope="col">{{ meetingTableHeads[1] }}</th>
            <th scope="col">{{ meetingTableHeads[2] }}</th>
            <th scope="col">{{ meetingTableHeads[3] }}</th>
            <th scope="col">{{ meetingTableHeads[4] }}</th>
            <th scope="col">{{ meetingTableHeads[5] }}</th>
            <th scope="col">{{ meetingTableHeads[6] }}</th>
            <th scope="col">{{ meetingTableHeads[7] }}</th>
            <th scope="col">{{ meetingTableHeads[8] }}</th>
            <th scope="col">{{ meetingTableHeads[9] }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            class="meetingRows"
            [ngClass]="{ active: selectedItem == meetings }"
            (click)="
              sendSco(
                meetings[0],
                meetings,
                meetings[4],
                meetings[9],
                meetings[6]
              )
            "
            *ngFor="
              let meetings of meetingTableRows
                | slice
                  : (meetingPage - 1) * meetingPageSize
                  : (meetingPage - 1) * meetingPageSize + meetingPageSize
            "
          >
            <td>{{ meetings[0] }}</td>
            <td>{{ meetings[1] }}</td>
            <td>{{ meetings[2] }}</td>
            <td>{{ meetings[3] }}</td>
            <td>{{ meetings[4].toLocaleString() }}</td>
            <td>{{ meetings[5] }}</td>
            <td>{{ meetings[6] }}</td>
            <td>{{ meetings[7] }}</td>
            <td>{{ meetings[8] }}</td>
            <td>{{ meetings[9] }}</td>
          </tr>
        </tbody>
      </table>
      <div>
        <ngb-pagination
          [ellipses]="true"
          [collectionSize]="meetingTableSize"
          [(page)]="meetingPage"
          [maxSize]="10"
          [rotate]="true"
          [boundaryLinks]="true"
        >
        </ngb-pagination>
      </div>
    </div>
    <hr *ngIf="userTable" />
    <mat-tab-group *ngIf="userTable" id="meetingDetailsTabs" class="col-sm-12">
      <mat-tab class="row" label="Meeting Users">
        <div class="col-sm-12">
          <div *ngIf="userTable">
            <h2>Meeting User Report</h2>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th scope="col">{{ userTableHeads[4] }}</th>
                  <th scope="col">{{ userTableHeads[5] }}</th>
                  <th scope="col">{{ userTableHeads[6] }}</th>
                  <th scope="col">{{ userTableHeads[7] }}</th>
                  <th scope="col">{{ userTableHeads[11] }}</th>
                  <th scope="col">{{ userTableHeads[12] }}</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="
                    let users of userTableRows
                      | slice
                        : (userPage - 1) * userPageSize
                        : (userPage - 1) * userPageSize + userPageSize
                  "
                >
                  <td>{{ users[4].toLocaleString() }}</td>
                  <td>{{ users[5] }}</td>
                  <td>{{ users[6] }}</td>
                  <td>{{ users[7] }}</td>
                  <td>{{ users[11] }}</td>
                  <td>{{ users[12].toLocaleString() }}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <ngb-pagination
                [maxSize]="10"
                [ellipses]="true"
                [collectionSize]="userTableRows.length"
                [(page)]="userPage"
                [boundaryLinks]="true"
                [pageSize]="userPageSize"
              >
              </ngb-pagination>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab class="row" label="Attendee Graph">
        <div class="ticket-loader" *ngIf="graphLoaded == false">
          <h2>Please Wait -- Meetings are Loading</h2>
          <div class="progress-bar">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
          </div>
        </div>
        <div *ngIf="graphLoaded == true" class="col-sm-12 form-group">
          <canvas
            id="meetings"
            baseChart
            [datasets]="lineChartData"
            [labels]="lineChartLabels"
            [options]="lineChartOptions"
            [colors]="lineChartColors"
            [legend]="lineChartLegend"
            [chartType]="lineChartType"
            [plugins]="lineChartPlugins"
          >
          </canvas>
        </div>
      </mat-tab>
    </mat-tab-group>

    <!-- <select [(ngModel)]="logsSelectedLogType" class="form-control">
      <option>5</option>
      <option>5</option>
      <option>5</option>
      <option>5</option>
      <option>5</option>
</select>
  <input type="text" [(ngModel)]="logsMinutesBack" /> -->
    <button
      (click)="getConfigArchive()"
      class="btn btn-primary"
      *ngIf="
        connectIDSelected == true &&
        logsSelectedLogType !== 'application' &&
        logsSelectedLogType !== 'meeting' &&
        logsSelectedLogType == 'configArchive'
      "
    >
      Get Config Archive
    </button>
    <button
      (click)="getAnalysisApi()"
      class="btn btn-primary"
      *ngIf="
        (logsSelectedLogType == 'meeting' && userTable == true) ||
        (logsSelectedLogType == 'application' && userTable == true)
      "
    >
      Request Analysis
    </button>
    <button
      *ngIf="
        connectIDSelected == true &&
        logsSelectedLogType !== 'application' &&
        logsSelectedLogType !== 'meeting' &&
        logsSelectedLogType !== 'configArchive' &&
        logsSelectedServer
      "
      (click)="getlogsAPI()"
      class="btn btn-primary"
    >
      Download Log
    </button>
    <button
      *ngIf="
        (connectIDSelected == true &&
          logsSelectedLogType == 'application' &&
          userTable == true) ||
        (connectIDSelected == true &&
          logsSelectedLogType == 'meeting' &&
          userTable == true)
      "
      (click)="getlogsAPI()"
      class="btn btn-primary"
    >
      Download Log
    </button>
  </div>
</div>
