import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallsService } from '../shared/services/api-calls.service';
import {
  NgbModal,
  ModalDismissReasons,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { UtilsService } from '../shared/services/utils.service';
import { AlertService } from '../shared/services/alert.service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  url: string;
  apiDomain: string;
  loginSpinner = true;
  errorBool = undefined;
  errorMsg;
  closeResult = '';
  authError = false;
  forgotEmail;
  passwordToast;
  modalShow;
  authErrorMsg;
  modalReference;
  errorforgot;
  userErrorMsg;
  successMessage: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
    private alertService: AlertService,
  ) {}

  ngOnInit(): void {}

  open(content) {
    this.modalReference = this.modalService.open(content);
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      },
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  urlUtil = () => {
    var baseUrl = window.location.origin;
    if (baseUrl === environment.frontendBaseURL) {
      // baseUrl = "https://insights-beta.cosocloud.com";
      baseUrl = environment.baseUrl;
      // baseUrl = "http://192.168.0.106:5000"
    }
    var url = baseUrl;
    return url;
  };

  forgotPass = async () => {
    var data = {
      // "api":"authentication.requestResetPasswordEmail",
      api: 'authentication.reset',
      username: this.forgotEmail,
      baseUrl: this.urlUtil(),
    };
    var response = await this.apiCallsService.sendApi(data);
    // if (response.response.errorCode == "OK") {
    if (response.status == '200') {
      this.errorforgot = false;
      this.modalReference.close();
      this.passwordToast = true;
      // this.successMessage = response.message
      this.alertService.showAlert(response.message, 'success');
      setTimeout(() => {
        this.passwordToast = null;
      }, 12000);
    } else {
      this.errorforgot = true;
      // if (response.response.errorCode == "UNKNOWN_USERNAME") {
      if (response.status == '400') {
        // this.userErrorMsg = "There is no Insights account associated with this email address. Please contact your CoSo representative to obtain an account."
        // this.userErrorMsg = response.failed
        // this.alertService.showAlert("There is no Insights account associated with this email address. Please contact your CoSo representative to obtain an account.", "close");
        this.alertService.showAlert(response.failed, 'error');
      } else {
        this.userErrorMsg =
          'There was a problem resetting your password. Please check the email address and try again. Contact an administrator if you continue having issues.';
        this.alertService.showAlert(
          'There is no Insights account associated with this email address. Please contact your CoSo representative to obtain an account.',
          'close',
        );
      }
    }
    return response;
  };

  sendcors = () => {
    var loginObj: { [k: string]: any } = {};
    var uname = (<HTMLInputElement>document.getElementById('username')).value;
    loginObj.username = uname.toLowerCase();
    loginObj.password = (<HTMLInputElement>(
      document.getElementById('password')
    )).value;
    var response = this.apiCallsService.loginApi(loginObj);
    return response;
  };

  sendCC = () => {
    var data = {
      domain: 'data-lake',
      api: 'ccPermitUser',
    };
    var response = this.apiCallsService.sendApi(data);
    return response;
  };

  getinstallList = () => {
    var data = {
      domain: 'data-lake',
      api: 'ccListAccounts',
    };
    var response = this.apiCallsService.sendApi(data);
    return response;
  };

  selectedConnectID = (param) => {
    var data = {
      domain: 'data-lake',
      api: 'list-connect-install-servers',
      properties: {
        'connect-id': param,
      },
    };
    var response = this.apiCallsService.sendApi(data);
    return response;
  };

  getlogsAPI = (param) => {
    var data = {
      domain: 'data-lake',
      api: 'getLogs',
      properties: {
        'connect-id': param,
        'log-type': 'access',
      },
    };
    var response = this.apiCallsService.sendApi(data);
    return response;
  };

  checkAuth = async (param) => {
    var loginObj: { [k: string]: any } = {};
    var uname = (<HTMLInputElement>document.getElementById('username')).value;
    loginObj.username = uname.toLowerCase();
    loginObj.password = (<HTMLInputElement>(
      document.getElementById('password')
    )).value;
    var data = {
      domain: 'insights',
      api: 'authentication.checkduoauth',
      properties: {
        username: loginObj.username,
        password: loginObj.password,
        'auth-id': param,
      },
    };
    var response = await this.apiCallsService.sendApi(data);
    var authResp = await this.authResponses(
      response.response.errorCode,
      param,
      response.response.message,
    );
    if (authResp !== 'OK') {
      return authResp;
    } else {
      return response;
    }
  };

  sleep = async (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  authResponses = async (err, authid, message) => {
    switch (err) {
      case 'DUO_AUTH_IN_PROG':
        await this.sleep(1000);
        return err;
        break;
      case 'OK':
        return err;
        break;
      default:
        this.authError = true;
        this.authErrorMsg = message;
        return err;
        break;
      // code block
    }
  };

  login = async () => {
    this.loginSpinner = false;
    this.authError = false;
    this.errorBool = undefined;
    var authResp;
    var myJson = await this.sendcors();
    // if (myJson.status == 'PASSWORD_EXPIRED') {
    //   localStorage.setItem(
    //     'passwordRules',
    //     myJson['password-requirements-html']
    //   );
    //   localStorage.setItem('username', myJson.username);
    //   console.log("username :", localStorage.setItem('username', myJson.username));
    //   localStorage.setItem('expiryDays', myJson['expire-days']);
    //   window.location.href = '/update-password.html';
    //   return;
    // }
    if (myJson.status == 200) {
      this.loginSpinner = true;
      this.alertService.showAlert((this.errorMsg = myJson.message), 'success');
      localStorage.setItem('expiryDays', myJson['expire-days']);
      return;
    }
    if (
      myJson.response.is2fa == true &&
      myJson.response.errorCode == 'DUO_AUTH_IN_PROG'
    ) {
      do {
        authResp = await this.checkAuth(myJson.response.authId);
        await this.sleep(1000);
      } while (authResp == 'DUO_AUTH_IN_PROG');
      myJson = authResp;
    }
    sessionStorage.setItem('session-key', myJson.response.sessionKey);
    var myCCPerm = await this.sendCC();
    if (myCCPerm.status == 'SUCCESS') {
      sessionStorage.setItem('CCPerms', 'Yes');
    } else {
      sessionStorage.setItem('CCPerms', 'No');
    }
    var ccInstalls = await this.getinstallList();
    sessionStorage.setItem('ccInstalls', JSON.stringify(ccInstalls));
    if (myJson.response) {
      if (myJson.response.errorCode === 'OK') {
        for (var key in myJson.response.installations) {
          myJson.response.installations[key].status = 'unknown';
        }
        var seshKeyObj = {};
        sessionStorage.setItem('firstName', myJson.response.firstName);
        sessionStorage.setItem('email', myJson.response.email.toLowerCase());
        sessionStorage.setItem(
          'entitlements',
          JSON.stringify(myJson.response.entitlements),
        );
        sessionStorage.setItem(
          'sessionKeys',
          JSON.stringify(myJson.response.sessionKeys),
        );
        // debugger
        sessionStorage.setItem('session-key', myJson.response.sessionKey);
        sessionStorage.setItem('name', myJson.response.installations[0].name);
        sessionStorage.setItem(
          'url',
          myJson.response.installations[0]['control-center-url'],
        );

        sessionStorage.setItem('uuid', myJson.uuid);
        sessionStorage.setItem('loggedInBool', 'yes');
        sessionStorage.setItem('onlyCC', myJson.response.homepage);
        sessionStorage.setItem('superUser', myJson.response.superUser);
        //    		sessionStorage.setItem("superUser", false);
        sessionStorage.setItem(
          'fullname',
          myJson.response.firstName + ' ' + myJson.response.lastName,
        );
        sessionStorage.setItem('username', myJson.response.email.toLowerCase());
        sessionStorage.setItem(
          'isInsightsAdmin',
          myJson.response.isInsightsAdmin,
        );
        sessionStorage.setItem(
          'is_show_ssl_log',
          myJson.response.is_show_ssl_log,
        );
        sessionStorage.setItem(
          'installs',
          JSON.stringify(myJson.response.installations),
        );
        localStorage.setItem(
          'selectedInstall',
          myJson.response.installations[0]['connect-id'],
        );

        if (myJson.response.accountsWithCustomMonitors) {
          localStorage.setItem(
            'customMonitors',
            JSON.stringify(myJson.response.accountsWithCustomMonitors),
          );
          sessionStorage.setItem('customMonCheck', 'true');
        } else {
          sessionStorage.setItem('customMonCheck', 'false');
        }
        var storedInstalls = JSON.parse(
          localStorage.getItem('SelectedInstalls'),
        );

        const areturn = await Promise.all([
          this.sendVersion(myJson),
          this.getInstalls(myJson),
        ]);
        var error = 0;
        var installs = [];
        var dc = 'smqcc';
        var datacenters = [];
        var insightsInstalls = JSON.parse(sessionStorage.getItem('installs'));

        for (var k in areturn[1]) {
          if (!areturn[1][k]) {
            error += 1;
            dc = 'sjccc';
            continue;
          }
          var rows =
            areturn[1][k].actionList[0].retvalMap['installation-report'].rows;
          for (var c in rows) {
            var datacenter = rows[c][6];
            var installsKey = rows[c][4];
            for (var i = 0; i < insightsInstalls.length; i++) {
              if (installsKey === insightsInstalls[i]['key']) {
                insightsInstalls[i]['datacenter'] = datacenter;
              }
            }
            if (datacenters.indexOf(rows[c][6]) === -1) {
              datacenters.push(rows[c][6]);
            }
          }
          // toggle the dc
        }
        sessionStorage.setItem('installs', JSON.stringify(insightsInstalls));

        // if (installs.length < 2){/// deal with the bad installs return toggle
        //  	const areturn = await Promise.all ([ sendVersion(myJson),getInstalls(myJson)]);
        //  	var installs = [];
        //  	var dc = "smqcc";
        //  	for (k in areturn[1]){
        //  		if(!areturn[1][k]){
        //  			error +=1;
        //  			dc = "sjccc";
        //  			continue;
        //  		}
        //  		rows = areturn[1][k].actionList[0].retvalMap["installation-report"].rows;
        //  		for (var c in rows){
        // 		 		url = "https://"+dc+".connectsolutions.com/start/transaction/actions.do"
        //  				ins = {id:rows[c][0],key:rows[c][4], name:rows[c][3], status:rows[c][7] , url:url, datacenter:rows[c][6] ,product:rows[c][1]}
        //  				installs.push(ins);
        //  		}
        //  		// toggle the dc
        //  		dc = "sjccc";
        //  	}
        //  }
        //  window.localStorage.setItem("installations", JSON.stringify(installs));
        //  sessionStorage.setItem("installations", JSON.stringify(installs));
        sessionStorage.setItem('dataCenters', JSON.stringify(datacenters));
        // debugger;
        if (myJson.response.installations.length == 1) {
          localStorage.setItem('OnlyOne', 'true');
          localStorage.setItem(
            'SelectedInstalls',
            myJson.response.installations[0],
          );
        } else {
          localStorage.setItem('OnlyOne', 'false');
        }

        if (storedInstalls == null || storedInstalls.length == 0) {
          sessionStorage.setItem(
            'SelectedInstalls',
            JSON.stringify([myJson.response.installations[0]]),
          );

          localStorage.setItem('showInstallSelect', 'true');
          localStorage.setItem(
            'SelectedInstalls',
            JSON.stringify([myJson.response.installations[0]]),
          );
          if (myJson.response.homepage == 'cc') {
            // window.location.href = '/ccListAccounts';
            this.router.navigate(['/ccListAccounts']);
          } else if (myJson.response.entitlements['no-connect']) {
            // window.location.href = '/tech-support';
            this.router.navigate(['/tech-support']);
          } else {
            // window.location.href = '/dashboard';
            this.router.navigate(['/dashboard']);
          }
        } else {
          var instCheck1 = JSON.stringify([myJson.response.installations[0]]); // '[{"connect-id":9,"id":9,"key":9,"name":"abc","status":"unknown"}]'
          var instCheck2 = localStorage.getItem('SelectedInstalls'); // '[{"connect-id":9,"id":9,"key":9,"name":"abc","status":"unknown"}]'
          if (instCheck1 == instCheck2) {
            if (myJson.response.homepage == 'cc') {
              // window.location.href = '/ccListAccounts';
              this.router.navigate(['/ccListAccounts']);
            } else if (myJson.response.entitlements['no-connect']) {
              // window.location.href = '/tech-support';
              this.router.navigate(['/tech-support']);
            } else {
              // window.location.href = '/dashboard';
              this.router.navigate(['/dashboard']);
            }
          } else {
            localStorage.setItem(
              'SelectedInstalls',
              JSON.stringify([myJson.response.installations[0]]),
            );
            if (myJson.response.homepage == 'cc') {
              // window.location.href = '/ccListAccounts';
              this.router.navigate(['/ccListAccounts']);
            } else if (myJson.response.entitlements['no-connect']) {
              // window.location.href = '/tech-support';
              this.router.navigate(['/tech-support']);
            } else {
              // window.location.href = '/dashboard';
              this.router.navigate(['/dashboard']);
            }
          }
        }
      } else {
        this.loginSpinner = true;
        this.errorBool = true;
        // this.errorMsg = myJson.response.message
        this.alertService.showAlert(myJson.response.message, 'error');
      }
    } else {
      this.loginSpinner = false;
      this.errorBool = true;
      // this.errorMsg = "There was an error trying to reach the login API"
      this.alertService.showAlert(
        'Username / Password is incorrect. Please try again.',
        'error',
      );
    }
  };
  sendVersion = (myJson) => {
    var ins = myJson.response.installations;
    var sessionKeys = myJson.response.sessionKeys;
    for (var [key, value] of Object.entries(sessionKeys)) {
      var url = key;
      var data = {
        actions: [
          {
            definition: {
              verb: 'query',
              object: 'connect',
              type: 'validate-pgi-dashboard-version',
            },
            simpleParams: [
              { name: 'pgi-dashboard-version', type: 'long', value: 45000 },
            ],
          },
        ],
        session: { sessionKey: value },
      };
      fetch(url, {
        method: 'POST', // or 'PUT'
        body: JSON.stringify(data), // data can be `string` or {object}!
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }
    // post the version
  };

  getInstalls = async (myJson) => {
    var ins = myJson.response.installations;
    var tmpsessions = myJson.response.sessionKeys;
    var apiIncrement = 0;
    var installResponseArr = [];
    for (var [key, value] of Object.entries(tmpsessions)) {
      var url = key;
      var data = {
        actions: [
          {
            definition: {
              verb: 'query',
              object: 'reseller',
              type: 'get-reseller-installation-report',
            },
            simpleParams: [],
          },
        ],
        session: { sessionKey: value },
      };
      installResponseArr[apiIncrement] = await fetch(url, {
        method: 'POST',
        body: 'json-data=' + encodeURI(JSON.stringify(data)), // data can be `string` or {object}!
        headers: { 'Content-Type': 'application/x-www-form-urlencoded ' },
      })
        .then(function (response) {
          return response.json();
        })
        .catch(function (error) {
          window.alert('Problem with API');
        });
      apiIncrement++;
    }
    return installResponseArr;
  };
}
