import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiCallsService } from 'src/app/shared/services/api-calls.service';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-install',
  templateUrl: './view-install.component.html',
  styleUrls: ['./view-install.component.scss'],
})
export class ViewInstallComponent implements OnInit {
  installId: any;
  public installFromParam: any = undefined;

  constructor(
    private router: Router,
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
  ) {}
  baseURLSend = window.location.origin;
  seshkeyraw = sessionStorage.getItem('sessionKey');
  seshKey = JSON.parse(this.seshkeyraw);
  installationFullList;
  getInstallationData: any;

  async ngOnInit(): Promise<any> {
    this.installFromParam = this.route.snapshot.paramMap.get('id');
    if (this.baseURLSend === environment.frontendBaseURL) {
      this.baseURLSend = environment.baseUrl;
    }
    this.getInstallations();
    // this.listUserJson = await this.fetchAdminList();
    // this.adminSpinner = false;
    // this.renderAdminTable(this.listUserJson.response.userList)
    var installationResponse = await this.getInstallations();
    // console.log("installationResponse------->",installationResponse)
    // this.installationFullList = installationResponse.response.installationList;
  }
  getInstallations = async () => {
    var data = {
      // "api": "authentication.administratorlistinstallations",
      api: 'administration.administratorListInstallations',
      'session-key': this.seshKey,
      id: this.installFromParam,
    };
    var response = await this.apiCallsService.sendApi(data);
    // const response = '2023-06-14T13:52:19.649240+05:30';

    if (response.closed != '') {
      const dateTime = new Date(response.closed);
      const formattedDate = this.datePipe.transform(dateTime, 'dd MMMM yyyy');
      const formattedTime = this.datePipe.transform(dateTime, 'hh:mm a');
      response.closed = formattedDate + ' ' + formattedTime;
    }
    this.getInstallationData = response;
    return response;
  };
}
