<div class="content-card">
  <!-- <div class="ticket-loader">
        <h2>Please Wait -- Your Ticket is Updating</h2>
        <div class="progress-bar">
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        </div>
    </div> -->
  <div class="ticketBody">
    <div class="row">
      <div class="col-sm-10">
        <h2>View Installation</h2>
      </div>
      <div class="headerBar">
        <p>Install Information</p>
      </div>
      <!-- <div class="col-sm-3 createdBy">
                <p>Created By: <b></b></p>
            </div> -->
      <div class="offset-sm-1 col-sm-10">
        <div class="row" *ngIf="getInstallationData">
          <div class="col-sm-12 form-group">
            <div class="row">
              <div class="col-sm-12">
                <label for="urlInput">id</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.id"
                  id="id"
                  type="number"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Name</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.name"
                  id="subjectInput"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Dcops</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.dcops"
                  id="Dcops"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Application</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.application"
                  id="descInput"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Instance Name</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.instance_name"
                  id="instancename"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Database Name</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.dbname"
                  id="dbname"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">User Name</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.username"
                  id="username"
                  type="text"
                />
              </div>
              <div class="col-sm-12">
                <label for="urlInput">Closed</label>
                <input
                  disabled="disabled"
                  class="form-control"
                  [(ngModel)]="getInstallationData.closed"
                  id="closed"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
