import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { InstallsSelectDashComponent } from './installs-select-dash/installs-select-dash.component';
import { MeetingTablesComponent } from './meeting-tables/meeting-tables.component';
import { RecentLoginsComponent } from './recent-logins/recent-logins.component';
import { RecentContentComponent } from './recent-content/recent-content.component';
import { AdminComponent } from './admin/admin.component';
import { TechSupportComponent } from './tech-support/tech-support.component';
import { TicketComponent } from './ticket/ticket.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { MetricsComponent } from './metrics/metrics.component';
import { CCLogsComponent } from './cclogs/cclogs.component';
import { CCInstallSelectComponent } from './ccinstall-select/ccinstall-select.component';
import { DisableuserComponent } from './disableuser/disableuser.component';
import { CCPerfMonComponent } from './ccperf-mon/ccperf-mon.component';
import { LicenseReportComponent } from './license-report/license-report.component';
import { CcRemoteMonitorComponent } from './cc-remote-monitor/cc-remote-monitor.component';
import { CcListAccountComponent } from './cc-list-account/cc-list-account.component';
import { CcConnectBackupsComponent } from './cc-connect-backups/cc-connect-backups.component';
import { CcS3Component } from './cc-s3/cc-s3.component';
import { CustomMonitorComponent } from './custom-monitor/custom-monitor.component';
import { CcExtrasComponent } from './cc-extras/cc-extras.component';
import { TestParentComponent } from './test-parent/test-parent.component';
import { TestChildComponent } from './test-child/test-child.component';
import { EditTicketComponent } from './ticket/edit-ticket/edit-ticket.component';
import { InstallComponent } from './admin/install/install.component';
import { ViewInstallComponent } from './admin/view-install/view-install.component';
import { EditInstallComponent } from './admin/edit-install/edit-install.component';
import { EditUserComponent } from './admin/edit-user/edit-user.component';
import { SSOLogsComponent } from './sso-logs/sso-logs.component';
import {
  AdminGuardService,
  SSOGuardService,
} from './shared/services/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'ccs3',
    component: CcS3Component,
  },
  {
    path: 'parent',
    component: TestParentComponent,
  },
  {
    path: 'ccExtras',
    component: CcExtrasComponent,
  },
  {
    path: 'ccCustomMonitor',
    component: CustomMonitorComponent,
  },
  {
    path: 'customMonitor',
    component: CustomMonitorComponent,
  },
  {
    path: 'ccPerfMon',
    component: CCPerfMonComponent,
  },
  {
    path: 'ccConnectBackup',
    component: CcConnectBackupsComponent,
  },
  {
    path: 'ccRemoteMonitor',
    component: CcRemoteMonitorComponent,
  },
  {
    path: 'ccListAccounts',
    component: CcListAccountComponent,
  },
  {
    path: 'license-report',
    component: LicenseReportComponent,
  },
  {
    path: 'metrics',
    component: MetricsComponent,
  },
  {
    path: 'disableuser',
    component: DisableuserComponent,
  },
  {
    path: 'reports',
    component: ReportsComponent,
  },
  {
    path: 'cclogs',
    component: CCLogsComponent,
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
  },
  {
    path: 'admin',
    canActivate: [AdminGuardService],
    component: AdminComponent,
  },
  {
    path: 'admin/install',
    component: InstallComponent,
  },
  {
    path: 'admin/view-install',
    component: ViewInstallComponent,
  },
  {
    path: 'admin/view-install/:id',
    component: ViewInstallComponent,
  },
  {
    path: 'admin/edit-install',
    component: EditInstallComponent,
  },
  {
    path: 'admin/edit-install/:id',
    component: EditInstallComponent,
  },
  {
    path: 'admin/edit-user',
    component: EditUserComponent,
  },
  {
    path: 'admin/edit-user/:id',
    component: EditUserComponent,
  },
  {
    path: 'ssologs',
    canActivate: [SSOGuardService],
    component: SSOLogsComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'meetings',
    component: MeetingTablesComponent,
  },
  {
    path: 'recentuserlog',
    component: RecentLoginsComponent,
  },
  {
    path: 'recentcontent',
    component: RecentContentComponent,
  },
  {
    path: 'install-select',
    component: InstallsSelectDashComponent,
  },
  {
    path: 'tech-support',
    component: TechSupportComponent,
  },
  {
    path: 'ticket',
    component: TicketComponent,
  },
  {
    path: 'ticket/:id',
    component: TicketComponent,
  },
  {
    path: 'edit-ticket/',
    component: EditTicketComponent,
  },
  {
    path: 'edit-ticket/:id',
    component: EditTicketComponent,
  },
  {
    path: '**',
    component: LoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    // Include AdminGuardService in the providers array
    AdminGuardService,
    SSOGuardService,
  ],
})
export class AppRoutingModule {}
