import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { UtilsService } from '../shared/services/utils.service';
import { ApiCallsService } from '../shared/services/api-calls.service';
import jsPDF from 'jspdf';
import moment from 'moment';

@Component({
  selector: 'app-metrics',
  templateUrl: './metrics.component.html',
  styleUrls: ['./metrics.component.scss'],
})
export class MetricsComponent implements OnInit {
  constructor(
    private apiCallsService: ApiCallsService,
    private utilsService: UtilsService,
  ) {}
  metricsChartReady = false;
  insightsUrl = this.apiCallsService.urlUtil();
  domain = this.apiCallsService.domainUtil();
  seshKey = this.apiCallsService.seshKeyUtil();
  public metricsChartLabels: Label[] = ['', '', '', '', '', '', '', '', ''];
  public metricsChartType: ChartType = 'line';
  public metricsChartLegend = true;
  todayDate = new Date();
  oneInstall;
  missingData = false;
  showBar;
  missingDataToast = false;
  selectedInstalls = [];
  installationList = JSON.parse(sessionStorage.getItem('installs'));
  installationChecks = this.installationList;
  public metricsChartPlugins = [];
  public selectedMinutesBack: any = '0';
  public minHourRange: any = '15';
  public selectedSubchart: any = 'ActiveMeetings';
  public timeRange = 'WEEK';
  public chartTitle = 'Per Minute Metrics';
  chartTypes = this.utilsService.chartTypes();
  public selectedChartType = 'PerMinute';
  public dailyTimeRange = 'WEEK';
  // beg: any
  // end: any

  public metricsChartData: Array<any> = [{}];
  public metricsChartColors: Color[] = [
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#269AD0',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#282521',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#25408F',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#F7A209',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#6D6A67',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#8C4600',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#269AD0',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#282521',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#25408F',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#F7A209',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ed6663',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ffa372',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ffa36c',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ebdc87',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#799351',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#290001',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#87431d',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#c87941',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#dbcbbd',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ffd5cd',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#efbbcf',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#c3aed6',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#8675a9',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#1b262c',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#0f4c75',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#00b7c2',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#fdcb9e',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#cffffe',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#f9f7d9',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#fce2ce',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ffc1f3',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#efbbcf',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ffd5cd',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#c3aed6',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#8675a9',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#00bcd4',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#b2ebf2',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ff5722',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#ed6663',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#b52b65',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#3c2c3e',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#59405c',
    },
    {
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: '#8d93ab',
    },
  ];

  metricsChartOptions = {
    responsive: true,
    spanGaps: true,
    maintainAspectRatio: false,
    aspectRatio: 2.5,
    elements: {
      point: {
        radius: 0,
      },
    },

    tooltips: {
      mode: 'nearest',
      intersect: false,
    },
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
            autoSkip: true,
            beginAtZero: false,
            labelOffset: 0,
            maxTicksLimit: 15,
            maxRotation: 30,
            minRotation: 0,
          },
          type: 'time',
          distribution: 'series',
          time: {
            tooltipFormat: 'MMM D Y h:mm a',
            displayFormats: {
              minute: 'h:mm a',
              hour: 'dddd LT',
              day: 'MMM D Y',
            },
          },
          gridLines: {
            borderDash: [8, 4],
            color: '#808080',
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: true,
          },
        },
      ],
    },
  };

  public selectedUserType;
  async ngOnInit(): Promise<any> {
    this.oneInstall = localStorage.getItem('OnlyOne');
    this.showBar = localStorage.getItem('showInstallSelect');
    if (this.showBar == 'true') {
      this.showBar = true;
    } else {
      this.showBar = false;
    }
    if (this.oneInstall == 'true') {
      this.oneInstall = true;
    }
    this.selectedInstalls = JSON.parse(
      localStorage.getItem('SelectedInstalls'),
    );
    if (this.selectedInstalls !== null) {
      for (var i = 0; i < this.installationChecks.length; i++) {
        for (var o = 0; o < this.selectedInstalls.length; o++) {
          if (
            this.selectedInstalls[o].name == this.installationChecks[i].name
          ) {
            this.installationChecks[i].checked = true;
          }
        }
      }
    }
    this.metUpdate();
  }

  ngOnDestroy() {
    this.selectedInstalls = JSON.parse(
      localStorage.getItem('SelectedInstalls'),
    );
    this.selectedInstalls = [this.selectedInstalls[0]];
    localStorage.setItem(
      'SelectedInstalls',
      JSON.stringify(this.selectedInstalls),
    );
  }

  toggleInstallSelect = (param) => {
    if (param == 'true') {
      localStorage.setItem('showInstallSelect', 'true');
      this.showBar = true;
    } else {
      localStorage.setItem('showInstallSelect', 'false');
      this.showBar = false;
    }
  };

  installChecked = (install, checkvalue) => {
    if (this.selectedInstalls == null) {
      this.selectedInstalls = [];
    }
    if (checkvalue == true) {
      this.selectedInstalls.push(install);
      localStorage.setItem(
        'SelectedInstalls',
        JSON.stringify(this.selectedInstalls),
      );
    } else {
      for (var i = 0; i < this.selectedInstalls.length; i++) {
        if (this.selectedInstalls[i].name == install.name) {
          this.selectedInstalls.splice(i, 1);
        }
      }
      localStorage.setItem(
        'SelectedInstalls',
        JSON.stringify(this.selectedInstalls),
      );
    }
    this.metUpdate();
  };

  typeChanged = (titleParam, value) => {
    this.chartTitle = titleParam;
    var title = titleParam;
    var chrtgroup = value;
    var index: any = 0;

    for (var key in this.chartTypes) {
      index = this.chartTypes[key].chartName === chrtgroup ? key : index;
    }
    var chartDef = this.chartTypes[index];
    switch (this.selectedChartType) {
      case 'PerMinute':
        this.selectedSubchart = 'ActiveMeetings';
        break;
      case 'HourlyPeak':
        this.selectedSubchart = 'PeakMeeting';
        break;
      case 'ExtendedHourly':
        this.selectedSubchart = 'MaxConcurrentMeetingUsers';
        break;
      case 'DailyMetrics':
        this.selectedSubchart = 'TotalMeetings';
        break;
      case 'MonthlyMetrics':
        this.selectedSubchart = 'TotalMeetings';
        break;
      default:
      // code block
    }
    this.subtypechanged();
  };

  GetChartData = async (chartDef, installNames) => {
    this.missingData = false;
    var sessionkey = '';
    var actives = []; //name,
    var data;
    var qdata = [];
    var installs = JSON.parse(localStorage.getItem('SelectedInstalls'));
    const sessionKeys = JSON.parse(sessionStorage.getItem('sessionKeys'));

    for (var m = 0; m < installs.length; m++) {
      // loop over all the installs and find the active ones
      installNames.push(installs[m].name);
      actives.push(installs[m]);
    }
    for (var key in actives) {
      // loop over all the installs and find the active ones
      var today = new Date();
      var dateBack = new Date();
      var ccUrl = actives[key]['connect-id'];
      // var sessionKey = sessionKeys[ccUrl]
      var sp;
      for (var c in chartDef['simpleParams']) {
        sp = chartDef['simpleParams'][c];
        if (sp.name === 'connect-installation-id') {
          sp.value = actives[key].id;
        } else if (sp.name === 'minutes-back') {
          today.setMinutes(today.getMinutes() - this.selectedMinutesBack);
        } else if (sp.name === 'group-by-month') {
          if (chartDef.chartName === 'MonthlyMetrics') {
            var period = 'YEAR';
          } else {
            var period = this.timeRange;
          }
          if (period === 'WEEK') {
            dateBack.setDate(dateBack.getDate() - 8);
          }
          if (period === 'MONTH') {
            dateBack.setMonth(dateBack.getMonth() - 1);
          }
          if (period === '2MONTHS') {
            dateBack.setMonth(dateBack.getMonth() - 2);
          }
          if (period === '3MONTHS') {
            dateBack.setMonth(dateBack.getMonth() - 3);
          }
          if (period === 'YEAR') {
            dateBack.setMonth(dateBack.getMonth() - 12);
          }
        } else if (sp.name === 'minutes-range') {
          dateBack.setMinutes(dateBack.getMinutes() - this.selectedMinutesBack);
          dateBack.setMinutes(dateBack.getMinutes() - this.minHourRange);
        } else if (sp.name === 'day-count') {
          //sp.value =  sessionStorage.getItem("load-day-count") ? parseInt(sessionStorage.getItem("day-count")):15 ;
          var period = this.dailyTimeRange;
          if (period === 'WEEK') {
            dateBack.setDate(dateBack.getDate() - 8);
          }
          if (period === 'MONTH') {
            dateBack.setMonth(dateBack.getMonth() - 1);
          }
          if (period === '2MONTHS') {
            dateBack.setMonth(dateBack.getMonth() - 2);
          }
          if (period === '3MONTHS') {
            dateBack.setMonth(dateBack.getMonth() - 3);
          }
          if (period === 'YEAR') {
            dateBack.setMonth(dateBack.getMonth() - 12);
          }
        } else if (sp.name === 'report-from-date') {
          if (chartDef.chartName === 'MonthlyMetrics') {
            period = 'YEAR';
          } else {
            var period = this.dailyTimeRange;
          }
          if (period === 'WEEK') {
            dateBack.setDate(dateBack.getDate() - 8);
          }
          if (period === 'MONTH') {
            dateBack.setMonth(dateBack.getMonth() - 1);
          }
          if (period === '2MONTHS') {
            dateBack.setMonth(dateBack.getMonth() - 2);
          }
          if (period === '3MONTHS') {
            dateBack.setMonth(dateBack.getMonth() - 3);
          }
          if (period === 'YEAR') {
            dateBack.setMonth(dateBack.getMonth() - 12);
          }
        }
      }

      if (chartDef.chartName == 'PerMinute') {
        // this.beg = dateBack.getTime()
        // this.beg = moment(this.beg)
        data = {
          domain: 'data-lake',
          api: 'getPerMinuteStats',
          'session-key': this.seshKey,
          properties: {
            'session-key': this.seshKey,
            'connect-id': ccUrl,
            beg: dateBack.getTime(),
            // "beg": moment(this.beg),
            end: today.getTime(),
          },
        };
        // if (this.beg.isValid()) {
        //   console.log("if stmt : " ,this.beg.format()); // Output the formatted date
        // } else {
        //   console.error('Invalid date format!');
        // }
      }
      if (chartDef.chartName == 'ExtendedHourly') {
        data = {
          domain: 'data-lake',
          api: 'hourlyUsage',
          'session-key': this.seshKey,
          properties: {
            'session-key': this.seshKey,
            'connect-id': ccUrl,
            range: this.dailyTimeRange.toLocaleLowerCase(),
          },
        };
      }

      if (chartDef.chartName == 'MonthlyMetrics') {
        data = {
          domain: 'data-lake',
          api: 'dailyMetrics',
          'session-key': this.seshKey,
          properties: {
            'session-key': this.seshKey,
            'connect-id': ccUrl,
            monthly: true,
          },
        };
      }

      if (chartDef.chartName == 'DailyMetrics') {
        data = {
          domain: 'data-lake',
          api: 'dailyMetrics',
          'session-key': this.seshKey,
          properties: {
            'session-key': this.seshKey,
            'connect-id': ccUrl,
            monthly: false,
            range: this.dailyTimeRange.toLocaleLowerCase(),
          },
        };
      }

      if (chartDef.chartName == 'HourlyPeak') {
        var offsetTime = new Date();
        data = {
          domain: 'data-lake',
          api: 'hourlyPeakConcurrency',
          'session-key': this.seshKey,
          properties: {
            'session-key': this.seshKey,
            'connect-id': ccUrl,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            offset: offsetTime.getTimezoneOffset(),
            range: this.dailyTimeRange.toLocaleLowerCase(),
          },
        };
      }

      var response = await this.apiCallsService.sendApi(data);

      if (chartDef.chartName == 'HourlyPeak') {
        for (var p = 0; p < response.data.yAxes.length; p++) {
          if (this.selectedSubchart == response.data.yAxes[p].label) {
            for (var i = 0; i < response.data.yAxes[p].dataSeries.length; i++) {
              let ds = response.data.yAxes[p].dataSeries[i];
              ds.label = installs[key].name + ' ' + ds.label;
              qdata.push(ds);
            }
          }
        }
        this.metricsChartLabels = response.data.xAxis.labels;
      } else {
        qdata.push(response.data);
      }

      if (chartDef.chartName === 'PerMinute') {
        const qdata = [];
        const dataSeries = {
          data: [],
          label: '',
          spanGaps: true,
        };
        for (let i = 0; i < response.data.headers.length; i++) {
          const header = response.data.headers[i];
          const rowData = response.data.rows.map((row) => row[i]);
          if (i === 0) {
            // first column is the time data
            dataSeries.data = rowData;
          } else {
            dataSeries.label = header;
            dataSeries.data = rowData;
            qdata.push(Object.assign({}, dataSeries));
          }
        }
        this.metricsChartLabels = response.data.rows.map((row) => row[0]);
      }
    } //end for
    //	localStorage.setItem("installations",JSON.stringify(installs));
    // console.log("qdata--",qdata);
    return qdata;
  };

  subtypechanged = () => {
    // var title =document.getElementById("selectChartType").selectedOptions[0].text+
    //  	" / "+document.getElementById("subchoices").selectedOptions[0].text;
    //  document.getElementById("loadCardTitle").innerHTML = title;
    var chrt = this.selectedChartType;
    var subchrt = this.selectedSubchart;

    sessionStorage.setItem('chartName', chrt); // the charttypes record to render
    sessionStorage.setItem('subreportName', subchrt);
    this.metUpdate();
  };

  // parseDateFromString(dateString) {
  //   const [dayMonthYear, time] = dateString.split(', ');
  //   const [day, month, year] = dayMonthYear.split('/');
  //   const [hour, minute, second] = time.split(':');
  //   // Date expects month to be 0-indexed (January is 0, December is 11)
  //   const parsedDate = new Date(year, month - 1, day, hour, minute, second);
  //   let mdate = moment.utc(dayMonthYear + ' ' + time, "DD/MM/YYYY HH:mm:ss")
  //   console.log("mdate : ", mdate);
  //   console.log("mdate formate : ", mdate.format('DD/MM/YYYY HH:mm:ss zz'));

  //   return parsedDate;
  // }

  // parseDateFromString(dateString) {
  //   console.log("dateString : ", dateString);
  //   const [dayMonthYear, time] = dateString.split(', ');
  //   const [day, month, year] = dayMonthYear.split('/');
  //   const [hour, minute, second] = time.split(':');
  //   // Date expects month to be 0-indexed (January is 0, December is 11)
  //   // const parsedDate = new Date(dateString);
  //   // const a =  new Date(Date.parse(dateString))
  //   debugger
  //   const parsedDate = moment(dateString, "MM/DD/YYYY, h:mm:ss a").toDate()
  //   console.log("parsedDate : ", parsedDate);

  //   // let mdate = moment.utc(dayMonthYear + ' ' + time, "DD/MM/YYYY HH:mm:ss")
  //   // console.log("mdate formate : ", mdate.format('DD/MM/YYYY HH:mm:ss zz'));

  //   return parsedDate;
  // }

  parseDateFromString(dateString) {
    console.log('dateString : ', dateString);
    // dateString = "13/11/2023, 2:40:05 PM"
    const [dayMonthYear, time] = dateString.split(', ');
    const [month, day, year] = dayMonthYear.split('/');
    const [hour, minute, second] = time.split(':');

    // Concatenate the date components in a format that is recognized by Moment.js
    const momentDateString1 = `${month}/${day}/${year} ${hour}:${minute}:${second}`;
    // const momentDateString2 = `${day}/${month}/${year} ${hour}:${minute}:${second}`;
    console.log('momentDateString1 :', momentDateString1);
    let parsedDate = moment(
      momentDateString1,
      'MM/DD/YYYY, h:mm:ss a',
    ).toDate();
    console.log('parsedDate :', parsedDate);

    // If parsing with the first format fails, try the second format
    if (isNaN(parsedDate.getTime())) {
      // parsedDate = moment(momentDateString1, "MM/DD/YYYY h:mm:ss a").toDate();
      const [day, month, year] = dayMonthYear.split('/');
      const momentDateString2 = `${day}/${month}/${year} ${hour}:${minute}:${second}`;
      parsedDate = moment(momentDateString2, 'DD/MM/YYYY h:mm:ss a').toDate();
      console.log('momentDateString2 :', momentDateString2);
      console.log('parsedDate if : ', parsedDate);
    }

    return parsedDate;
  }

  formatTime(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12; // Convert 0 to 12 for 12-hour clock
    const formattedMinutes = minutes.toString().padStart(2, '0'); // Ensure minutes have leading zero if less than 10

    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  }

  metUpdate = async () => {
    if (window.innerWidth < 960) {
      var elms = document.querySelectorAll('.uk-sortable');
      for (var c: any = 0; c < elms.length; c++) {
        elms[c].classList.add('uk-sortable-nodrag');
      }
    } else {
      var elms = document.querySelectorAll('.uk-sortable');
      for (c = 0; c < elms.length; c++) {
        elms[c].classList.remove('uk-sortable-nodrag');
      }
    }

    var datasets = [{}];
    var labels = [];
    var num: any, rows: any, c: any, o: any, tots: any[], r: any;
    var Names = [];
    var headers = [];
    var installs = JSON.parse(sessionStorage.getItem('installs'));
    const installNames = [];
    const chartName = this.selectedChartType;

    const chartDefs = this.utilsService.chartTypes();
    for (var k in chartDefs) {
      if (chartDefs[k].chartName === chartName) {
        var chartDef = chartDefs[k];
      }
    }
    let jqxr = await this.GetChartData(chartDef, installNames);

    if (chartDef.chartName !== 'HourlyPeak') {
      var lastDat = [];
      var datcol = [];
      var subchartindex, subchartName;
      var legend = [];
      var cdat = [];
      var data: any = [];
      data.datasets = [];
      var dataset: any = [];
      var tots = Array(28).fill(0);
      const colors = this.GetColorArray();
      num = 0;
      headers = [];
      var slegend = [];
      // save the legend hidden status
      // for(c=0; c<LineChart.legend.legendItems.length;c++){
      // 	slegend[c]=LineChart.legend.legendItems[c].hidden;
      // }
      for (k in jqxr) {
        // each installation
        // debugger;
        labels = [];
        o = jqxr[k];
        if (o) {
          subchartName = this.selectedSubchart; //sessionStorage.getItem("subchartName");
          for (var l = 0; l < chartDef.subcharts.length; l++) {
            // just get the subchart we need
            if (chartDef.subcharts[l].name === subchartName) {
              subchartindex = l;
              break; // we use the value of r below
            }
          }

          if (o == undefined) {
            this.missingData = true;
            continue;
          }
          rows = o.rows;
          headers = o.headers;
          datcol.length = 0;
          legend = [];
          cdat.length = 0;
          dataset.length = 0;

          var offsets = chartDef.subcharts[subchartindex].offsets; // the col num we need

          for (c = 0; c < offsets.length; c++) {
            datcol[c] = this.findCol(headers, offsets[c]);
            if (datcol[c] == -1) {
              continue;
            } else {
              legend[c] = this.toTitleCase(headers[datcol[c]]); // 'Meeting Count Avg'
            }
            cdat[c] = [];
          }
          if (subchartName === 'GuestUserAccess') {
            // exception for "GuestUserAccess" chart which has math between datapoints
            for (r in rows) {
              num = rows[r][datcol[0]] - rows[r][datcol[2]] || null;
              if (num !== null && num < 0) {
                num = 0;
              }
              cdat[0].push(num);
              num = rows[r][datcol[1]] || null;
              cdat[1].push(num);
              labels[r] = new Date(rows[r][0]).toLocaleString();
            }
            legend.pop();
            cdat.pop();
            datcol.pop();
          } else {
            for (r in rows) {
              for (c in offsets) {
                num = rows[r][datcol[c]];
                if (datcol[c] == -1) {
                  continue;
                } else {
                  cdat[c].push(num);
                }
              }
              labels[r] = new Date(rows[r][0]).toLocaleString();
            }
          }

          if (chartName === 'MonthlyMetrics') {
            dataset.data = cdat[0];
            var t = ''; // needed to force value not reference
            t = legend[0];
            dataset.label = this.toTitleCase(installNames[k] + ' ' + t);

            var i = parseInt(k) + parseInt(c);
            var target = []; // needed to force value not reference
            Object.assign(target, dataset);
            data.datasets.push(target);
          } else {
            for (c in offsets) {
              dataset.data = cdat[c];
              var t = ''; // needed to force value not reference
              t = legend[c];
              dataset.label = this.toTitleCase(installNames[k] + ' ' + t); // 'Abc Meeting Count'
              var i = parseInt(k) + parseInt(c);
              var target = []; // needed to force value not reference
              Object.assign(target, dataset);
              if (dataset.data !== undefined) {
                data.datasets.push(target);
              } else {
                continue;
              }
            }
          }
          Names.push(installNames[k]);
        } // end if(o.trans  - once through for each install

        // for (r in installs){  // set error messages for servers that have gone away
        // 	if (installs[r].name === installNames[k]){
        // 		installs[r].status = o.messageList[0] ? o.messageList[0].key : o.transactionStatus.toUpperCase();
        // 	}
        // }// for
      } //for
      if (this.missingData == true) {
        this.missingDataToast = true;
        setTimeout(function () {
          this.missingDataToast = false;
        }, 2000);
      }
      data.labels = labels;
      // if (chartName === "HourlyPeak") {
      //   this.barChartOptions.scales.xAxes[0].time.tooltipFormat = "dddd LT"
      // }
      // else {
      //   this.barChartOptions.scales.xAxes[0].time.tooltipFormat = "MMM D Y h:mm a"
      // }
      var labelArr: Array<any> = [];
      var newChartData: Array<any> = [];
      for (var p = 0; p < data.datasets.length; p++) {
        newChartData.push({});
        newChartData[p].data = data.datasets[p].data;
        newChartData[p].label = data.datasets[p].label;
      }

      for (var s = 0; s < newChartData.length; s++) {
        newChartData[s].spanGaps = true;
      }

      for (var p = 0; p < labels.length; p++) {
        const tempDate = this.parseDateFromString(labels[p].toUpperCase());
        console.log('tempDate : ', tempDate);
        // const formattedTime = this.formatTime(tempDate);
        // labelArr.push(formattedTime);
        // labelArr.push(tempDate.toLocaleString("en-US"));
        labelArr.push(
          tempDate.toLocaleString('en-US', { timeZone: 'America/New_York' }),
        );
      }

      // for (var p = 0; p < labels.length; p++) {
      //   var tempDate = new Date(labels[p].toUpperCase())
      //   console.log("tempDate :", tempDate.toLocaleString);
      //   labelArr.push(tempDate.toLocaleString("en-US"))
      // }
      this.metricsChartLabels = labelArr;
      this.metricsChartData = newChartData;

      var totals = [];
      totals.push(headers);
      totals.push(tots);
      sessionStorage.setItem('totals', JSON.stringify(totals));
      // LineChart.data = data;

      var d: any = [0];
      d[0] = ['Date/Time'];
      d.push(labels);
      for (c = 0; c < data.datasets.length; c++) {
        d[0].push(data.datasets[c].label);
        d.push(data.datasets[c].data);
      }
      sessionStorage.setItem('csvDatasets', JSON.stringify(d));
      this.metricsChartReady = true;

      //updateChart(data.datasets,LineChart,Names);
      //localStorage.setItem("installations",JSON.stringify(installs));
      //	document.getElementById("loadSpinner").classList.add("uk-hidden");
      return datasets;
    } else {
      this.metricsChartData = jqxr;
    }
  };

  findCol = (headers, colname) => {
    var col = headers.findIndex(function (header) {
      return colname === header;
    });
    return col;
  };

  GetColorArray = () => {
    var colorArr: any = [17];
    colorArr[0] = '#269AD0';
    colorArr[1] = '#282521';
    colorArr[2] = '#25408F';
    colorArr[3] = '#F7A209';
    colorArr[5] = '#6D6A67';
    colorArr[6] = '#8C4600';
    colorArr[7] = '#269AD0';
    colorArr[8] = '#282521';
    colorArr[9] = '#25408F';
    colorArr[10] = '#F7A209';
    colorArr[11] = '#6D6A67';
    colorArr[12] = '#8C4600';
    colorArr[13] = '#269AD0';
    colorArr[14] = '#282521';
    colorArr[15] = '#25408F';
    colorArr[16] = '#F7A209';
    colorArr[17] = '#6D6A67';
    return colorArr;
  };

  toTitleCase = (phrase) => {
    return phrase
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  downloadcsv = () => {
    var data = JSON.parse(sessionStorage.getItem('csvDatasets'));
    var csv = data[0].toString() + '%0D%0A';
    for (var c = 0; c < data[1].length; c++) {
      for (var i = 1; i < data.length; i++) {
        if (i == 1) {
          var dataObj = new Date(data[i][c]);
          data[i][c] = dataObj.toLocaleString();
          data[i][c] = data[i][c].replace(',', '%20');
        }
        csv += data[i][c] + ',';
      }
      csv += '%0D%0A';
    }

    var link = document.createElement('a');
    link.href =
      'data:application/octet-stream,field1%2Cfield2%0Afoo%2Cbar%0Agoo%2Cgai%0A';
    link.href = 'data:application/octet-stream,' + csv;
    link.download = 'myChart.csv';
    link.click();
  };
  download = () => {
    var srcCanvas = <HTMLCanvasElement>document.getElementById('metricsChart');
    var destinationCanvas = document.createElement('canvas');
    destinationCanvas.width = srcCanvas.width;
    destinationCanvas.height = srcCanvas.height;
    var destCtx = destinationCanvas.getContext('2d');
    //create a rectangle with the desired color
    destCtx.fillStyle = '#FFFFFF';
    destCtx.fillRect(0, 0, srcCanvas.width, srcCanvas.height);
    //draw the original canvas onto the destination canvas
    destCtx.drawImage(srcCanvas, 0, 0);
    //finally use the destinationCanvas.toDataURL() method to get the desired output;
    var link = document.createElement('a');
    link.href = destinationCanvas.toDataURL();
    link.download = 'MetricsChart.jpeg';
    link.click();
  };
  downloadpdf = () => {
    var srcCanvas = <HTMLCanvasElement>document.getElementById('metricsChart');
    var pdfNameDate: any = new Date();
    pdfNameDate = pdfNameDate.toDateString();
    var destinationCanvas = document.createElement('canvas');
    destinationCanvas.width = srcCanvas.width;
    destinationCanvas.height = srcCanvas.height;
    var destCtx = destinationCanvas.getContext('2d');
    //create a rectangle with the desired color
    destCtx.fillStyle = '#FFFFFF';
    destCtx.fillRect(0, 0, srcCanvas.width, srcCanvas.height);
    //draw the original canvas onto the destination canvas
    destCtx.drawImage(srcCanvas, 0, 0);
    var doc: any = new jsPDF('landscape');
    doc.setFontSize(20);
    doc.text(15, 15, 'Super Cool Chart');
    doc.addImage(destinationCanvas, 'JPEG', 10, 10, 280, 100);
    doc.save('MetricsChart ' + pdfNameDate + '.pdf');
  };
}
