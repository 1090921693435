<div class="content-card">
  <div class="report-top">
    <h2>
      Reports <span id="dashDate">{{ todayDate | date: "fullDate" }}</span>
    </h2>
    <div class="form-group row">
      <!-- <div class="col-sm-6">
    <label for="installDrop">Select Install</label>
  <select *ngIf="oneInstall !== true" class="form-control" id="type" (change)="rptUpdate()" [(ngModel)]="reportSelectedInstall" name="installDrop">
    <option *ngFor="let install of reportInstallSelect; let i = index" [ngValue]="install">
      {{install.name}}
    </option>
  </select>
  </div>
  <div class="col-sm-6">
    <label for="selectChartType">Report Type</label>
    <select class="form-control" id="selectChartType"
    #typeSelect
    (change)="typeChanged($event.currentTarget.selectedOptions[0].title, typeSelect.value)" [(ngModel)]="selectedChartType">
      <option title="Meeting Activity" value = "MeetingActivityRpt" >Meeting Activity</option>
      <option title="Virtual Classroom Activity" value = "VirtualClsActivityRpt" >Virtual Classroom Activity</option>
      <option title="Content Launch Activity" value = "ContentLaunchActivityRpt" >Content Launch Activity</option>
      <option title="Training Launch Activity" value = "TrainingLaunchActivityRpt" >Training Launch Activity</option>
      <option title="On-Demand Activity"  value = "OnDemandActivityRpt" >On-Demand Activity</option>
    </select>
    </div> -->
    </div>
  </div>
</div>

<!-- <div class="content-card">
<h2>{{chartTitle}} <span id="install-block">{{reportSelectedInstall.name}}</span></h2>
<div class="form-group row">
  <div class="col-sm-6">
  <label for="selectTime">Range</label>
  <select   #rangeSelect   (change)="rptUpdate()"
   class="form-control"  [(ngModel)]="timeRange" id="selectTime">
    <option value="WEEK">Last Week</option>
    <option value="MONTH">Last Month</option>
    <option value="2MONTHS">Last 2 Months</option>
    <option value="3MONTHS">Last 3 Months</option>
    <option value="YEAR">Last Year</option>
  </select>
  </div>
  <div class="col-sm-6">
  <label for="selectUser">User Type</label>
  <select   #userSelect   (change)="rptUpdate()"
   class="form-control"  [(ngModel)]="selectedUserType" id="selectUser">
   <option value="ALL">All Users</option>
   <option value="USER">Logged In</option>
   <option value="GUEST">Guest Users</option>
  </select>
  </div>
</div>
<div>
<div *ngIf="reportChartReady == true" style="display: block; height:50vh;">
  <canvas baseChart id="reportsChart"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType"
    [colors]="barChartColors">
  </canvas>
</div>
<div *ngIf="reportChartReady == false" style="display: block; height:50vh;">
  <canvas baseChart id="reportsChart"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [plugins]="barChartPlugins"
    [legend]="barChartLegend"
    [chartType]="barChartType"
    [colors]="barChartColors">
  </canvas>
</div>
<hr />
<div class="row">
  <div class="col-4">
    <button class="btn btn-outline-secondary" (click)="download()"><span style="position:relative; top:5px;"><mat-icon> local_printshop</mat-icon></span> Print Chart</button>
  </div>
  <div class="col-4">
    <button class="btn btn-outline-secondary" (click)="downloadpdf()"><span style="position:relative; top:5px;"><mat-icon>picture_as_pdf</mat-icon></span> Print PDF Chart</button>
  </div>
  <div class="col-4">
    <button class="btn btn-outline-secondary" (click)="downloadcsv()"><span style="position:relative; top:5px;"><mat-icon>arrow_downward</mat-icon></span> Download CSV</button>
  </div>
</div>
</div>
</div> -->

<!-- <div style="padding-top:30px; clear:both" class="row">
  <div class="col-md-12 col-lg-6 col-xl-4">
  <div id="totals" class="col-12 bottom-cards">
    <div class="row">
      <div class="col-6">
        <img style="max-height:80px" src="../../../assets/images/icon_meetings.svg">
      </div>
      <div class="col-6">
      <h2>Totals</h2>
      </div>
      <div *ngIf="selectedChartType == 'MeetingActivityRpt'" class="col-12">
      <p>{{totalMeetingsSlice}}</p>
      <p>{{totalAttendeesSlice}}</p>
      <p>{{totalTimeSlice}}</p>
      </div>
      <div *ngIf="selectedChartType !== 'MeetingActivityRpt'" class="col-12">
        <p>{{totalLaunchesSlice}}</p>
        </div>
      </div>
  </div>
</div>
<div class="col-md-12 col-lg-6 col-xl-4">
  <div id="average" class="col-12 bottom-cards">
    <div class="row">
    <div class="col-6">
      <img style="max-height:80px" src="../../../assets/images/icon_powerpoint_presentations.svg">
    </div>
    <div class="col-6">
    <h2>Averages</h2>
    </div>
    <div *ngIf="selectedChartType == 'MeetingActivityRpt'" class="col-12">
    <p>{{averageMeetingSlice}}</p>
    <p>{{averageAttendeesSlice}}</p>
    <p>{{averageTimeSlice}}</p>
    </div>
    </div>
    <div *ngIf="selectedChartType !== 'MeetingActivityRpt'" class="col-12">
      <p>{{averageLaunchesSlice}}</p>
      </div>
      </div>
  </div>
<div class="col-md-12 col-lg-6 col-xl-4">
  <div id="peaks" class="col-12 bottom-cards">
    <div class="row">
      <div class="col-6">
        <img style="max-height:80px" src="../../../assets/images/icon_max_attendees.svg">
      </div>
      <div class="col-6">
      <h2>Peaks</h2>
      </div>
      <div *ngIf="selectedChartType == 'MeetingActivityRpt'" class="col-12">
      <p>{{peakMeetingSlice}}</p>
      <p>{{peakAttendeesSlice}}</p>
      <p>{{peakTimeSlice}}</p>
      </div>
      <div *ngIf="selectedChartType !== 'MeetingActivityRpt'" class="col-12">
        <p>{{peakLaunchesSlice}}</p>
        </div>
      </div>
  </div>
  </div>
</div> -->
