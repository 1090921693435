<div class="content-card">
  <div class="adminContainer">
    <h1>Admin Panel</h1>
    <div class="newUserBtns">
      <button
        *ngIf="newUser == false"
        class="btn btn-primary"
        (click)="renderNewUser(); newUser = true; editInstalls = false"
      >
        New User
      </button>
      <button
        *ngIf="newUser == true"
        class="btn btn-dark"
        (click)="newUser = false"
      >
        Close
      </button>
      <button class="btn btn-primary mx-1" [routerLink]="['/admin/install']">
        Install
      </button>
    </div>
    <div *ngIf="newUser == true">
      <form #newUser class="form-group">
        <div class="row">
          <div class="col-md-6 col-12">
            <h3>Basic Info</h3>
            <input
              [(ngModel)]="firstName"
              name="First Name"
              class="form-control"
              placeholder="First Name"
            />
            <input
              [(ngModel)]="lastName"
              name="Last Name"
              class="form-control"
              placeholder="Last Name"
            />
            <input
              [(ngModel)]="email"
              name="email"
              class="form-control"
              placeholder="Email"
            />
            <label for="adminNew">Administrator</label>
            <input
              name="installsBox"
              type="checkbox"
              [(ngModel)]="isAdminNew"
              [ngModelOptions]="{ standalone: true }"
              id="adminNew"
            />
            <span class="pipe"> | </span>
            <label for="supportOnlyNew">Support Only</label>
            <input
              type="checkbox"
              name="supportOnlyNew"
              [(ngModel)]="supportOnlyNew"
              [ngModelOptions]="{ standalone: true }"
              id="supportOnlyNew"
            />
            <span class="pipe"> | </span>
            <label for="isSslLogs">SSO Logs</label>
            <input
              type="checkbox"
              name="isSslLogs"
              [(ngModel)]="isSslLogs"
              [ngModelOptions]="{ standalone: true }"
              id="isSslLogs"
            />
          </div>
          <div class="col-md-6 col-12">
            <h3>Add Installations</h3>
            <!-- <select multiple class="form-control multiple-select " size="15" (change)="srcValueChange($event)">
                    <option *ngFor="let installations of installationFullList" value="{{installations.installationKey}}">{{installations.accountName}}</option>
                </select> -->
            <!-- <select multiple class="form-control multiple-select " size="15" (change)="srcValueChange($event)">
                    <option *ngFor="let installations of getInstallationData" value="{{installations.id}}">{{installations.name}}</option>
                </select> -->
            <!-- (change)="srcValueChange($event)" -->
            <select multiple class="form-control multiple-select" size="15">
              <option
                *ngFor="let installations of installationFullList"
                (click)="srcValueChange(installations)"
                [value]="installations.id"
                [selected]="installations.selected"
              >
                {{ installations.name }}
              </option>
            </select>
            <!-- <select multiple class="form-control multiple-select " size="15"
                            (change)="srcValueChange($event)">
                            <option *ngFor="let installations of installationFullList" value="{{installations.id}}">
                                {{installations.name}}</option>
                        </select> -->
          </div>
        </div>
      </form>
      <button (click)="createUser()" class="btn btn-primary createBtn">
        Create User
      </button>
    </div>

    <!----Admin-panal-End-->
    <div class="table-responsive">
      <p-table
        *ngIf="adminTableList.length > 0"
        [value]="adminTableList"
        #dt
        dataKey="fullName"
        [rows]="10"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [paginator]="true"
        [rowHover]="true"
        [globalFilterFields]="[
          'firstname',
          'email',
          'lastLogin',
          'successfulLogins'
        ]"
      >
        <ng-template pTemplate="caption">
          User List
          <span class="p-input-icon-left float-right">
            <i style="position: relative; left: -15px" class="pi pi-search"></i>
            <input
              pInputText
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="User Search"
            />
          </span>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th id="" style="width: 3rem"></th>
            <th id="" pSortableColumn="fullName">
              Name <p-sortIcon field="fullName"></p-sortIcon>
            </th>
            <th id="" pSortableColumn="email">
              Email <p-sortIcon field="email"></p-sortIcon>
            </th>
            <th id="" pSortableColumn="lastLogin" style="width: 10rem">
              Last Login <p-sortIcon field="lastLogin"></p-sortIcon>
            </th>
            <th
              id=""
              pSortableColumn="successfulLogins"
              style="text-align: center"
            >
              Successful Login
              <p-sortIcon field="successfulLogins"></p-sortIcon>
            </th>
            <th id="" pSortableColumn="Action">Action</th>
          </tr>
        </ng-template>
        <!-----GET-User-Details --->
        <ng-template pTemplate="body" let-admin let-expanded="expanded">
          <tr>
            <td>
              <button
                id="userdetailsbutton"
                style="
                  background-color: transparent;
                  border: none;
                  color: #0078e1;
                  outline: none;
                "
                type="button"
                pButton
                pRipple
                [pRowToggler]="admin"
                class="p-button-text p-button-rounded p-button-plain"
                [ngClass]="
                  selectedUserId === admin.id
                    ? 'pi pi-chevron-down'
                    : 'pi pi-chevron-right'
                "
                (click)="
                  getsingleuser(admin.id); newUser = false; editInstalls = false
                "
              ></button>
            </td>
            <td>{{ admin.firstname + " " + admin.lastname }}</td>
            <td>{{ admin.email }}</td>
            <td>{{ admin.lastlogin }}</td>
            <td style="text-align: center">{{ admin.successfulllogin }}</td>
            <td class="d-md-flex flex-row">
              <a
                class="btn btn-outline-dar kmx-1"
                [routerLink]="['/admin/edit-user', admin.id]"
                title="Edit User"
                data-toggle="tooltip"
                ><mat-icon>edit</mat-icon></a
              >
              <a
                class="btn btn-outline-dar kmx-1"
                (click)="deleteUser(admin)"
                title="Delete User"
                data-toggle="tooltip"
                ><mat-icon>delete</mat-icon></a
              >
            </td>
            <!-- Rest of the user data -->
          </tr>
          <tr *ngIf="selectedUserId === admin.id">
            <td [attr.colspan]="6">
              <!-- User details content -->
              <div class="p-p-3">
                <div class="row">
                  <div class="col-md-6 col-12">
                    <h2>User Details</h2>
                    <p><b>Email:</b> {{ admin.email }}</p>
                    <p><b>Is Admin:</b> {{ admin.privs }}</p>
                    <p><b>First Name:</b> {{ admin.firstname }}</p>
                    <p><b>Last Name:</b> {{ admin.lastname }}</p>
                    <p>
                      <b>Successful Logins:</b> {{ admin.successfulllogin }}
                    </p>
                    <p><b>Last Login:</b> {{ admin.lastlogin }}</p>
                    <!-- <p><b>Last Login:</b> {{admin.lastLogin.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}}</p> -->
                    <p class="uuid">
                      <b>UUID:</b> <span class="uuid">{{ admin.id }}</span>
                    </p>
                    <p>
                      <b>Installation Count:</b>
                      {{ admin.installations.length }}
                    </p>
                  </div>

                  <!-----------Edit-Installations--------------------------->

                  <div class="col-md-6 col-12">
                    <h3>Installations</h3>
                    <div *ngFor="let installation of admin.installations">
                      <p>{{ installation.name + " : " + installation.key }}</p>
                    </div>
                    <div *ngFor="let inst of admin.installationKeys">
                      <p>
                        <b>{{ instObj[inst] }}:</b> {{ inst }}
                      </p>
                    </div>
                    <button
                      *ngIf="editInstalls == false"
                      (click)="editInstalls = true; loadData()"
                      class="btn btn-primary"
                    >
                      Edit Installations
                    </button>
                    <button
                      *ngIf="editInstalls == true"
                      (click)="editInstalls = false"
                      class="btn btn-dark"
                    >
                      Close
                    </button>
                    <div
                      style="margin-top: 20px"
                      [hidden]="editInstalls == false"
                      class="updateInstallsBox"
                    >
                      <select
                        multiple
                        class="form-control multiple-select"
                        size="15"
                      >
                        <option
                          *ngFor="let installations of getInstallationData"
                          [selected]="installations.selected"
                          value="{{ installations.id }}"
                          (click)="editinstallationsChange(installations)"
                        >
                          {{ installations.name }}
                        </option>
                      </select>

                      <button
                        style="display: block; clear: both"
                        (click)="
                          editInstallations(admin.id, admin.installations)
                        "
                        class="btn btn-primary"
                      >
                        Submit Changes
                      </button>
                    </div>
                  </div>

                  <!-----------User-Settings--------------------------->

                  <div class="col-12">
                    <hr style="clear: both" />
                    <h2>User Settings</h2>
                    <button
                      class="btn btn-danger"
                      *ngIf="admin.disabled == false"
                      (click)="disableUser(admin.id, admin.email, 'false')"
                    >
                      Disable User
                    </button>
                    <button
                      class="btn btn-success mr-4"
                      *ngIf="admin.disabled == true"
                      (click)="disableUser(admin.id, admin.email, 'true')"
                    >
                      Enable User
                    </button>
                    <!-- <button class="btn btn-primary" (click)="sendPasswordResetEmail($event)">Reset
                                        Password</button> -->
                    <button
                      class="btn btn-primary"
                      (click)="forgotPass(admin.email)"
                    >
                      Reset Password
                    </button>
                    <!-- <input (change)="entitlementToggle($event)" name="killmeeting" class="killMeetingCheck" type="checkbox" [checked]="admin.entitlements['kill-meeting'] == true"/>
                            <label for="killmeeting">Kill Meeting Permission</label> -->
                    <input
                      (change)="adminToggle($event)"
                      name="isAdmin"
                      class="isAdminCheck"
                      type="checkbox"
                      [checked]="admin.privs == 'admin'"
                    />
                    <label for="isAdmin">Insights Admin</label>
                    <input
                      (change)="SSLToggle($event)"
                      name="isShowSSLLog"
                      type="checkbox"
                      [checked]="admin.is_show_ssl_log"
                    />
                    <label for="isSslLogs">SSO Logs</label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </ng-template>

        <!---User-List------>
      </p-table>
    </div>
  </div>
</div>
<ngb-toast class="alert alert-success" id="passToast" *ngIf="passwordToast">
  <p>Password Reset Email Sent!</p>
</ngb-toast>
<ngb-toast class="alert alert-success" id="passToast" *ngIf="installToast">
  <p>This user's installations have been updated</p>
</ngb-toast>
<ngb-toast class="alert alert-success" id="passToast" *ngIf="regToast">
  <p>This user has been updated</p>
</ngb-toast>
<ngb-toast class="alert alert-danger" id="passToast" *ngIf="noInstallToast">
  <p>You cannot have a user with no installs</p>
</ngb-toast>
