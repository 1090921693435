import { Component } from '@angular/core';
import { Router } from '@angular/router';
import '@angular/compiler';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  $: any;
  title = 'control-center-client';
  constructor(public router: Router) {}
}
