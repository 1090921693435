import { Component, OnInit } from '@angular/core';
import { ApiCallsService } from '../shared/services/api-calls.service';
import { Router } from '@angular/router';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'app-cc-extras',
  templateUrl: './cc-extras.component.html',
  styleUrls: ['./cc-extras.component.scss'],
})
export class CcExtrasComponent implements OnInit {
  constructor(
    private router: Router,
    private apiCallsService: ApiCallsService,
  ) {}

  ngOnInit(): void {}

  downloadGlobal = async () => {
    var data = {
      domain: 'data-lake',
      api: 'get-monthly-data-center-stats',
      properties: {},
    };
    var response = await this.apiCallsService.sendApi(data);
    this.downloadRedirect(response.properties['download-key']);
  };

  downloadRedirect = (param) => {
    var baseURL = this.apiCallsService.urlUtil();
    var downloadURL = baseURL + '/download/' + param;
    window.open(downloadURL);
  };
}
